<template>
  <easy-select
    v-model="selectValue"
    :options="options"
    :clearable="true"
  />
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'
export default {
  name: 'OrgSelect',
  components: {},
  // model: {
  //   prop: 'modelValue',
  //   event: 'modelValueChange',
  // },
  props: ['modelValue'],
  data() {
    return {
      options: [],
      // modelSwitch: true,
      selectValue: '',
    }
  },
  watch: {
    modelValue() {
      // if (this.cutModelSwitch()) {
      //   return
      // }
      this.selectValue = this.modelValue
    },
    selectValue() {
      // if (this.cutModelSwitch()) {
      //   return
      // }
      $emit(this, 'update:modelValue', this.selectValue)
    },
  },
  created() {
    this.getOptionsData()
    this.selectValue = this.modelValue
  },
  methods: {
    getOptionsData() {
      request({
        url: '/v4/admin/user/company/list?pageNo=1&pageSize=100',
      }).then((data) => {
        let company = []
        if (this.$store.getters.companyCode == 0) {
          company = [{ id: 0, name: '0-总部' }]
        }
        this.options = [
          ...company,
          ...data.data.data.map((item) => {
            item.name = item.code + '-' + item.name
            item.id = item.code
            return item
          }),
        ]
      })
    },
    // cutModelSwitch() {
    //   this.modelSwitch = !this.modelSwitch
    //   return this.modelSwitch
    // }
  },
  emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
#org-select {
}
</style>
