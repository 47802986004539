<template>
  <el-icon
    :size="size"
    :color="color"
  >
    <AddLocationIcon v-if="name == 'add-location'" />
    <AimIcon v-else-if="name == 'aim'" />
    <AlarmClockIcon v-else-if="name == 'alarm-clock'" />
    <AppleIcon v-else-if="name == 'apple'" />
    <ArrowDownIcon v-else-if="name == 'arrow-down'" />
    <ArrowDownBoldIcon v-else-if="name == 'arrow-down-bold'" />
    <ArrowLeftIcon v-else-if="name == 'arrow-left'" />
    <ArrowLeftBoldIcon v-else-if="name == 'arrow-left-bold'" />
    <ArrowRightIcon v-else-if="name == 'arrow-right'" />
    <ArrowRightBoldIcon v-else-if="name == 'arrow-right-bold'" />
    <ArrowUpIcon v-else-if="name == 'arrow-up'" />
    <ArrowUpBoldIcon v-else-if="name == 'arrow-up-bold'" />
    <AvatarIcon v-else-if="name == 'avatar'" />
    <BackIcon v-else-if="name == 'back'" />
    <BaseballIcon v-else-if="name == 'baseball'" />
    <BasketballIcon v-else-if="name == 'basketball'" />
    <BellIcon v-else-if="name == 'bell'" />
    <BellFilledIcon v-else-if="name == 'bell-filled'" />
    <BicycleIcon v-else-if="name == 'bicycle'" />
    <BottomIcon v-else-if="name == 'bottom'" />
    <BottomLeftIcon v-else-if="name == 'bottom-left'" />
    <BottomRightIcon v-else-if="name == 'bottom-right'" />
    <BowlIcon v-else-if="name == 'bowl'" />
    <BoxIcon v-else-if="name == 'box'" />
    <BriefcaseIcon v-else-if="name == 'briefcase'" />
    <BrushIcon v-else-if="name == 'brush'" />
    <BrushFilledIcon v-else-if="name == 'brush-filled'" />
    <BurgerIcon v-else-if="name == 'burger'" />
    <CalendarIcon v-else-if="name == 'calendar'" />
    <CameraIcon v-else-if="name == 'camera'" />
    <CameraFilledIcon v-else-if="name == 'camera-filled'" />
    <CaretBottomIcon v-else-if="name == 'caret-bottom'" />
    <CaretLeftIcon v-else-if="name == 'caret-left'" />
    <CaretRightIcon v-else-if="name == 'caret-right'" />
    <CaretTopIcon v-else-if="name == 'caret-top'" />
    <CellphoneIcon v-else-if="name == 'cellphone'" />
    <ChatDotRoundIcon v-else-if="name == 'chat-dot-round'" />
    <ChatDotSquareIcon v-else-if="name == 'chat-dot-square'" />
    <ChatLineRoundIcon v-else-if="name == 'chat-line-round'" />
    <ChatLineSquareIcon v-else-if="name == 'chat-line-square'" />
    <ChatRoundIcon v-else-if="name == 'chat-round'" />
    <ChatSquareIcon v-else-if="name == 'chat-square'" />
    <CheckIcon v-else-if="name == 'check'" />
    <CheckedIcon v-else-if="name == 'checked'" />
    <CherryIcon v-else-if="name == 'cherry'" />
    <ChickenIcon v-else-if="name == 'chicken'" />
    <CircleCheckIcon v-else-if="name == 'circle-check'" />
    <CircleCheckFilledIcon v-else-if="name == 'circle-check-filled'" />
    <CircleCloseIcon v-else-if="name == 'circle-close'" />
    <CircleCloseFilledIcon v-else-if="name == 'circle-close-filled'" />
    <CirclePlusIcon v-else-if="name == 'circle-plus'" />
    <CirclePlusFilledIcon v-else-if="name == 'circle-plus-filled'" />
    <ClockIcon v-else-if="name == 'clock'" />
    <CloseIcon v-else-if="name == 'close'" />
    <CloseBoldIcon v-else-if="name == 'close-bold'" />
    <CloudyIcon v-else-if="name == 'cloudy'" />
    <CoffeeIcon v-else-if="name == 'coffee'" />
    <CoffeeCupIcon v-else-if="name == 'coffee-cup'" />
    <CoinIcon v-else-if="name == 'coin'" />
    <ColdDrinkIcon v-else-if="name == 'cold-drink'" />
    <CollectionIcon v-else-if="name == 'collection'" />
    <CollectionTagIcon v-else-if="name == 'collection-tag'" />
    <CommentIcon v-else-if="name == 'comment'" />
    <CompassIcon v-else-if="name == 'compass'" />
    <ConnectionIcon v-else-if="name == 'connection'" />
    <CoordinateIcon v-else-if="name == 'coordinate'" />
    <CopyDocumentIcon v-else-if="name == 'copy-document'" />
    <CpuIcon v-else-if="name == 'cpu'" />
    <CreditCardIcon v-else-if="name == 'credit-card'" />
    <CropIcon v-else-if="name == 'crop'" />
    <DArrowLeftIcon v-else-if="name == 'd-arrow-left'" />
    <DArrowRightIcon v-else-if="name == 'd-arrow-right'" />
    <DCaretIcon v-else-if="name == 'd-caret'" />
    <DataAnalysisIcon v-else-if="name == 'data-analysis'" />
    <DataBoardIcon v-else-if="name == 'data-board'" />
    <DataLineIcon v-else-if="name == 'data-line'" />
    <DeleteIcon v-else-if="name == 'delete'" />
    <DeleteFilledIcon v-else-if="name == 'delete-filled'" />
    <DeleteLocationIcon v-else-if="name == 'delete-location'" />
    <DessertIcon v-else-if="name == 'dessert'" />
    <DiscountIcon v-else-if="name == 'discount'" />
    <DishIcon v-else-if="name == 'dish'" />
    <DishDotIcon v-else-if="name == 'dish-dot'" />
    <DocumentIcon v-else-if="name == 'document'" />
    <DocumentAddIcon v-else-if="name == 'document-add'" />
    <DocumentCheckedIcon v-else-if="name == 'document-checked'" />
    <DocumentCopyIcon v-else-if="name == 'document-copy'" />
    <DocumentDeleteIcon v-else-if="name == 'document-delete'" />
    <DocumentRemoveIcon v-else-if="name == 'document-remove'" />
    <DownloadIcon v-else-if="name == 'download'" />
    <DrizzlingIcon v-else-if="name == 'drizzling'" />
    <EditIcon v-else-if="name == 'edit'" />
    <EditPenIcon v-else-if="name == 'edit-pen'" />
    <ElemeIcon v-else-if="name == 'eleme'" />
    <ElemeFilledIcon v-else-if="name == 'eleme-filled'" />
    <ElementPlusIcon v-else-if="name == 'element-plus'" />
    <ExpandIcon v-else-if="name == 'expand'" />
    <FailedIcon v-else-if="name == 'failed'" />
    <FemaleIcon v-else-if="name == 'female'" />
    <FilesIcon v-else-if="name == 'files'" />
    <FilmIcon v-else-if="name == 'film'" />
    <FilterIcon v-else-if="name == 'filter'" />
    <FinishedIcon v-else-if="name == 'finished'" />
    <FirstAidKitIcon v-else-if="name == 'first-aid-kit'" />
    <FlagIcon v-else-if="name == 'flag'" />
    <FoldIcon v-else-if="name == 'fold'" />
    <FolderIcon v-else-if="name == 'folder'" />
    <FolderAddIcon v-else-if="name == 'folder-add'" />
    <FolderCheckedIcon v-else-if="name == 'folder-checked'" />
    <FolderDeleteIcon v-else-if="name == 'folder-delete'" />
    <FolderOpenedIcon v-else-if="name == 'folder-opened'" />
    <FolderRemoveIcon v-else-if="name == 'folder-remove'" />
    <FoodIcon v-else-if="name == 'food'" />
    <FootballIcon v-else-if="name == 'football'" />
    <ForkSpoonIcon v-else-if="name == 'fork-spoon'" />
    <FriesIcon v-else-if="name == 'fries'" />
    <FullScreenIcon v-else-if="name == 'full-screen'" />
    <GobletIcon v-else-if="name == 'goblet'" />
    <GobletFullIcon v-else-if="name == 'goblet-full'" />
    <GobletSquareIcon v-else-if="name == 'goblet-square'" />
    <GobletSquareFullIcon v-else-if="name == 'goblet-square-full'" />
    <GoodsIcon v-else-if="name == 'goods'" />
    <GoodsFilledIcon v-else-if="name == 'goods-filled'" />
    <GrapeIcon v-else-if="name == 'grape'" />
    <GridIcon v-else-if="name == 'grid'" />
    <GuideIcon v-else-if="name == 'guide'" />
    <HeadsetIcon v-else-if="name == 'headset'" />
    <HelpIcon v-else-if="name == 'help'" />
    <HelpFilledIcon v-else-if="name == 'help-filled'" />
    <HideIcon v-else-if="name == 'hide'" />
    <HistogramIcon v-else-if="name == 'histogram'" />
    <HomeFilledIcon v-else-if="name == 'home-filled'" />
    <HotWaterIcon v-else-if="name == 'hot-water'" />
    <HouseIcon v-else-if="name == 'house'" />
    <IceCreamIcon v-else-if="name == 'ice-cream'" />
    <IceCreamRoundIcon v-else-if="name == 'ice-cream-round'" />
    <IceCreamSquareIcon v-else-if="name == 'ice-cream-square'" />
    <IceDrinkIcon v-else-if="name == 'ice-drink'" />
    <IceTeaIcon v-else-if="name == 'ice-tea'" />
    <InfoFilledIcon v-else-if="name == 'info-filled'" />
    <IphoneIcon v-else-if="name == 'iphone'" />
    <KeyIcon v-else-if="name == 'key'" />
    <KnifeForkIcon v-else-if="name == 'knife-fork'" />
    <LightningIcon v-else-if="name == 'lightning'" />
    <LinkIcon v-else-if="name == 'link'" />
    <ListIcon v-else-if="name == 'list'" />
    <LoadingIcon v-else-if="name == 'loading'" />
    <LocationIcon v-else-if="name == 'location'" />
    <LocationFilledIcon v-else-if="name == 'location-filled'" />
    <LocationInformationIcon v-else-if="name == 'location-information'" />
    <LockIcon v-else-if="name == 'lock'" />
    <LollipopIcon v-else-if="name == 'lollipop'" />
    <MagicStickIcon v-else-if="name == 'magic-stick'" />
    <MagnetIcon v-else-if="name == 'magnet'" />
    <MaleIcon v-else-if="name == 'male'" />
    <ManagementIcon v-else-if="name == 'management'" />
    <MapLocationIcon v-else-if="name == 'map-location'" />
    <MedalIcon v-else-if="name == 'medal'" />
    <MenuIcon v-else-if="name == 'menu'" />
    <MessageIcon v-else-if="name == 'message'" />
    <MessageBoxIcon v-else-if="name == 'message-box'" />
    <MicIcon v-else-if="name == 'mic'" />
    <MicrophoneIcon v-else-if="name == 'microphone'" />
    <MilkTeaIcon v-else-if="name == 'milk-tea'" />
    <MinusIcon v-else-if="name == 'minus'" />
    <MoneyIcon v-else-if="name == 'money'" />
    <MonitorIcon v-else-if="name == 'monitor'" />
    <MoonIcon v-else-if="name == 'moon'" />
    <MoonNightIcon v-else-if="name == 'moon-night'" />
    <MoreIcon v-else-if="name == 'more'" />
    <MoreFilledIcon v-else-if="name == 'more-filled'" />
    <MostlyCloudyIcon v-else-if="name == 'mostly-cloudy'" />
    <MouseIcon v-else-if="name == 'mouse'" />
    <MugIcon v-else-if="name == 'mug'" />
    <MuteIcon v-else-if="name == 'mute'" />
    <MuteNotificationIcon v-else-if="name == 'mute-notification'" />
    <NoSmokingIcon v-else-if="name == 'no-smoking'" />
    <NotebookIcon v-else-if="name == 'notebook'" />
    <NotificationIcon v-else-if="name == 'notification'" />
    <OdometerIcon v-else-if="name == 'odometer'" />
    <OfficeBuildingIcon v-else-if="name == 'office-building'" />
    <OpenIcon v-else-if="name == 'open'" />
    <OperationIcon v-else-if="name == 'operation'" />
    <OpportunityIcon v-else-if="name == 'opportunity'" />
    <OrangeIcon v-else-if="name == 'orange'" />
    <PaperclipIcon v-else-if="name == 'paperclip'" />
    <PartlyCloudyIcon v-else-if="name == 'partly-cloudy'" />
    <PearIcon v-else-if="name == 'pear'" />
    <PhoneIcon v-else-if="name == 'phone'" />
    <PhoneFilledIcon v-else-if="name == 'phone-filled'" />
    <PictureIcon v-else-if="name == 'picture'" />
    <PictureFilledIcon v-else-if="name == 'picture-filled'" />
    <PictureRoundedIcon v-else-if="name == 'picture-rounded'" />
    <PieChartIcon v-else-if="name == 'pie-chart'" />
    <PlaceIcon v-else-if="name == 'place'" />
    <PlatformIcon v-else-if="name == 'platform'" />
    <PlusIcon v-else-if="name == 'plus'" />
    <PointerIcon v-else-if="name == 'pointer'" />
    <PositionIcon v-else-if="name == 'position'" />
    <PostcardIcon v-else-if="name == 'postcard'" />
    <PouringIcon v-else-if="name == 'pouring'" />
    <PresentIcon v-else-if="name == 'present'" />
    <PriceTagIcon v-else-if="name == 'price-tag'" />
    <PrinterIcon v-else-if="name == 'printer'" />
    <PromotionIcon v-else-if="name == 'promotion'" />
    <QuestionFilledIcon v-else-if="name == 'question-filled'" />
    <RankIcon v-else-if="name == 'rank'" />
    <ReadingIcon v-else-if="name == 'reading'" />
    <ReadingLampIcon v-else-if="name == 'reading-lamp'" />
    <RefreshIcon v-else-if="name == 'refresh'" />
    <RefreshLeftIcon v-else-if="name == 'refresh-left'" />
    <RefreshRightIcon v-else-if="name == 'refresh-right'" />
    <RefrigeratorIcon v-else-if="name == 'refrigerator'" />
    <RemoveIcon v-else-if="name == 'remove'" />
    <RemoveFilledIcon v-else-if="name == 'remove-filled'" />
    <RightIcon v-else-if="name == 'right'" />
    <ScaleToOriginalIcon v-else-if="name == 'scale-to-original'" />
    <SchoolIcon v-else-if="name == 'school'" />
    <ScissorIcon v-else-if="name == 'scissor'" />
    <SearchIcon v-else-if="name == 'search'" />
    <SelectIcon v-else-if="name == 'select'" />
    <SellIcon v-else-if="name == 'sell'" />
    <SemiSelectIcon v-else-if="name == 'semi-select'" />
    <ServiceIcon v-else-if="name == 'service'" />
    <SetUpIcon v-else-if="name == 'set-up'" />
    <SettingIcon v-else-if="name == 'setting'" />
    <ShareIcon v-else-if="name == 'share'" />
    <ShipIcon v-else-if="name == 'ship'" />
    <ShopIcon v-else-if="name == 'shop'" />
    <ShoppingBagIcon v-else-if="name == 'shopping-bag'" />
    <ShoppingCartIcon v-else-if="name == 'shopping-cart'" />
    <ShoppingCartFullIcon v-else-if="name == 'shopping-cart-full'" />
    <SmokingIcon v-else-if="name == 'smoking'" />
    <SoccerIcon v-else-if="name == 'soccer'" />
    <SoldOutIcon v-else-if="name == 'sold-out'" />
    <SortIcon v-else-if="name == 'sort'" />
    <SortDownIcon v-else-if="name == 'sort-down'" />
    <SortUpIcon v-else-if="name == 'sort-up'" />
    <StampIcon v-else-if="name == 'stamp'" />
    <StarIcon v-else-if="name == 'star'" />
    <StarFilledIcon v-else-if="name == 'star-filled'" />
    <StopwatchIcon v-else-if="name == 'stopwatch'" />
    <SuccessFilledIcon v-else-if="name == 'success-filled'" />
    <SugarIcon v-else-if="name == 'sugar'" />
    <SuitcaseIcon v-else-if="name == 'suitcase'" />
    <SunnyIcon v-else-if="name == 'sunny'" />
    <SunriseIcon v-else-if="name == 'sunrise'" />
    <SunsetIcon v-else-if="name == 'sunset'" />
    <SwitchIcon v-else-if="name == 'switch'" />
    <SwitchButtonIcon v-else-if="name == 'switch-button'" />
    <TakeawayBoxIcon v-else-if="name == 'takeaway-box'" />
    <TicketIcon v-else-if="name == 'ticket'" />
    <TicketsIcon v-else-if="name == 'tickets'" />
    <TimerIcon v-else-if="name == 'timer'" />
    <ToiletPaperIcon v-else-if="name == 'toilet-paper'" />
    <ToolsIcon v-else-if="name == 'tools'" />
    <TopIcon v-else-if="name == 'top'" />
    <TopLeftIcon v-else-if="name == 'top-left'" />
    <TopRightIcon v-else-if="name == 'top-right'" />
    <TrendChartsIcon v-else-if="name == 'trend-charts'" />
    <TrophyIcon v-else-if="name == 'trophy'" />
    <TurnOffIcon v-else-if="name == 'turn-off'" />
    <UmbrellaIcon v-else-if="name == 'umbrella'" />
    <UnlockIcon v-else-if="name == 'unlock'" />
    <UploadIcon v-else-if="name == 'upload'" />
    <UploadFilledIcon v-else-if="name == 'upload-filled'" />
    <UserIcon v-else-if="name == 'user'" />
    <UserFilledIcon v-else-if="name == 'user-filled'" />
    <VanIcon v-else-if="name == 'van'" />
    <VideoCameraIcon v-else-if="name == 'video-camera'" />
    <VideoCameraFilledIcon v-else-if="name == 'video-camera-filled'" />
    <VideoPauseIcon v-else-if="name == 'video-pause'" />
    <VideoPlayIcon v-else-if="name == 'video-play'" />
    <ViewIcon v-else-if="name == 'view'" />
    <WalletIcon v-else-if="name == 'wallet'" />
    <WalletFilledIcon v-else-if="name == 'wallet-filled'" />
    <WarningIcon v-else-if="name == 'warning'" />
    <WarningFilledIcon v-else-if="name == 'warning-filled'" />
    <WatchIcon v-else-if="name == 'watch'" />
    <WatermelonIcon v-else-if="name == 'watermelon'" />
    <WindPowerIcon v-else-if="name == 'wind-power'" />
    <ZoomInIcon v-else-if="name == 'zoom-in'" />
    <ZoomOutIcon v-else-if="name == 'zoom-out'" />
    <ViewIcon v-else />
  </el-icon>
</template>
<script lang="ts">
import {
  AddLocation as AddLocationIcon,
  Aim as AimIcon,
  AlarmClock as AlarmClockIcon,
  Apple as AppleIcon,
  ArrowDown as ArrowDownIcon,
  ArrowDownBold as ArrowDownBoldIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowLeftBold as ArrowLeftBoldIcon,
  ArrowRight as ArrowRightIcon,
  ArrowRightBold as ArrowRightBoldIcon,
  ArrowUp as ArrowUpIcon,
  ArrowUpBold as ArrowUpBoldIcon,
  Avatar as AvatarIcon,
  Back as BackIcon,
  Baseball as BaseballIcon,
  Basketball as BasketballIcon,
  Bell as BellIcon,
  BellFilled as BellFilledIcon,
  Bicycle as BicycleIcon,
  Bottom as BottomIcon,
  BottomLeft as BottomLeftIcon,
  BottomRight as BottomRightIcon,
  Bowl as BowlIcon,
  Box as BoxIcon,
  Briefcase as BriefcaseIcon,
  Brush as BrushIcon,
  BrushFilled as BrushFilledIcon,
  Burger as BurgerIcon,
  Calendar as CalendarIcon,
  Camera as CameraIcon,
  CameraFilled as CameraFilledIcon,
  CaretBottom as CaretBottomIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  CaretTop as CaretTopIcon,
  Cellphone as CellphoneIcon,
  ChatDotRound as ChatDotRoundIcon,
  ChatDotSquare as ChatDotSquareIcon,
  ChatLineRound as ChatLineRoundIcon,
  ChatLineSquare as ChatLineSquareIcon,
  ChatRound as ChatRoundIcon,
  ChatSquare as ChatSquareIcon,
  Check as CheckIcon,
  Checked as CheckedIcon,
  Cherry as CherryIcon,
  Chicken as ChickenIcon,
  CircleCheck as CircleCheckIcon,
  CircleCheckFilled as CircleCheckFilledIcon,
  CircleClose as CircleCloseIcon,
  CircleCloseFilled as CircleCloseFilledIcon,
  CirclePlus as CirclePlusIcon,
  CirclePlusFilled as CirclePlusFilledIcon,
  Clock as ClockIcon,
  Close as CloseIcon,
  CloseBold as CloseBoldIcon,
  Cloudy as CloudyIcon,
  Coffee as CoffeeIcon,
  CoffeeCup as CoffeeCupIcon,
  Coin as CoinIcon,
  ColdDrink as ColdDrinkIcon,
  Collection as CollectionIcon,
  CollectionTag as CollectionTagIcon,
  Comment as CommentIcon,
  Compass as CompassIcon,
  Connection as ConnectionIcon,
  Coordinate as CoordinateIcon,
  CopyDocument as CopyDocumentIcon,
  Cpu as CpuIcon,
  CreditCard as CreditCardIcon,
  Crop as CropIcon,
  DArrowLeft as DArrowLeftIcon,
  DArrowRight as DArrowRightIcon,
  DataAnalysis as DataAnalysisIcon,
  DataBoard as DataBoardIcon,
  DataLine as DataLineIcon,
  DCaret as DCaretIcon,
  Delete as DeleteIcon,
  DeleteFilled as DeleteFilledIcon,
  DeleteLocation as DeleteLocationIcon,
  Dessert as DessertIcon,
  Discount as DiscountIcon,
  Dish as DishIcon,
  DishDot as DishDotIcon,
  Document as DocumentIcon,
  DocumentAdd as DocumentAddIcon,
  DocumentChecked as DocumentCheckedIcon,
  DocumentCopy as DocumentCopyIcon,
  DocumentDelete as DocumentDeleteIcon,
  DocumentRemove as DocumentRemoveIcon,
  Download as DownloadIcon,
  Drizzling as DrizzlingIcon,
  Edit as EditIcon,
  EditPen as EditPenIcon,
  Eleme as ElemeIcon,
  ElemeFilled as ElemeFilledIcon,
  ElementPlus as ElementPlusIcon,
  Expand as ExpandIcon,
  Failed as FailedIcon,
  Female as FemaleIcon,
  Files as FilesIcon,
  Film as FilmIcon,
  Filter as FilterIcon,
  Finished as FinishedIcon,
  FirstAidKit as FirstAidKitIcon,
  Flag as FlagIcon,
  Fold as FoldIcon,
  Folder as FolderIcon,
  FolderAdd as FolderAddIcon,
  FolderChecked as FolderCheckedIcon,
  FolderDelete as FolderDeleteIcon,
  FolderOpened as FolderOpenedIcon,
  FolderRemove as FolderRemoveIcon,
  Food as FoodIcon,
  Football as FootballIcon,
  ForkSpoon as ForkSpoonIcon,
  Fries as FriesIcon,
  FullScreen as FullScreenIcon,
  Goblet as GobletIcon,
  GobletFull as GobletFullIcon,
  GobletSquare as GobletSquareIcon,
  GobletSquareFull as GobletSquareFullIcon,
  Goods as GoodsIcon,
  GoodsFilled as GoodsFilledIcon,
  Grape as GrapeIcon,
  Grid as GridIcon,
  Guide as GuideIcon,
  Headset as HeadsetIcon,
  Help as HelpIcon,
  HelpFilled as HelpFilledIcon,
  Hide as HideIcon,
  Histogram as HistogramIcon,
  HomeFilled as HomeFilledIcon,
  HotWater as HotWaterIcon,
  House as HouseIcon,
  IceCream as IceCreamIcon,
  IceCreamRound as IceCreamRoundIcon,
  IceCreamSquare as IceCreamSquareIcon,
  IceDrink as IceDrinkIcon,
  IceTea as IceTeaIcon,
  InfoFilled as InfoFilledIcon,
  Iphone as IphoneIcon,
  Key as KeyIcon,
  KnifeFork as KnifeForkIcon,
  Lightning as LightningIcon,
  Link as LinkIcon,
  List as ListIcon,
  Loading as LoadingIcon,
  Location as LocationIcon,
  LocationFilled as LocationFilledIcon,
  LocationInformation as LocationInformationIcon,
  Lock as LockIcon,
  Lollipop as LollipopIcon,
  MagicStick as MagicStickIcon,
  Magnet as MagnetIcon,
  Male as MaleIcon,
  Management as ManagementIcon,
  MapLocation as MapLocationIcon,
  Medal as MedalIcon,
  Menu as MenuIcon,
  Message as MessageIcon,
  MessageBox as MessageBoxIcon,
  Mic as MicIcon,
  Microphone as MicrophoneIcon,
  MilkTea as MilkTeaIcon,
  Minus as MinusIcon,
  Money as MoneyIcon,
  Monitor as MonitorIcon,
  Moon as MoonIcon,
  MoonNight as MoonNightIcon,
  More as MoreIcon,
  MoreFilled as MoreFilledIcon,
  MostlyCloudy as MostlyCloudyIcon,
  Mouse as MouseIcon,
  Mug as MugIcon,
  Mute as MuteIcon,
  MuteNotification as MuteNotificationIcon,
  NoSmoking as NoSmokingIcon,
  Notebook as NotebookIcon,
  Notification as NotificationIcon,
  Odometer as OdometerIcon,
  OfficeBuilding as OfficeBuildingIcon,
  Open as OpenIcon,
  Operation as OperationIcon,
  Opportunity as OpportunityIcon,
  Orange as OrangeIcon,
  Paperclip as PaperclipIcon,
  PartlyCloudy as PartlyCloudyIcon,
  Pear as PearIcon,
  Phone as PhoneIcon,
  PhoneFilled as PhoneFilledIcon,
  Picture as PictureIcon,
  PictureFilled as PictureFilledIcon,
  PictureRounded as PictureRoundedIcon,
  PieChart as PieChartIcon,
  Place as PlaceIcon,
  Platform as PlatformIcon,
  Plus as PlusIcon,
  Pointer as PointerIcon,
  Position as PositionIcon,
  Postcard as PostcardIcon,
  Pouring as PouringIcon,
  Present as PresentIcon,
  PriceTag as PriceTagIcon,
  Printer as PrinterIcon,
  Promotion as PromotionIcon,
  QuestionFilled as QuestionFilledIcon,
  Rank as RankIcon,
  Reading as ReadingIcon,
  ReadingLamp as ReadingLampIcon,
  Refresh as RefreshIcon,
  RefreshLeft as RefreshLeftIcon,
  RefreshRight as RefreshRightIcon,
  Refrigerator as RefrigeratorIcon,
  Remove as RemoveIcon,
  RemoveFilled as RemoveFilledIcon,
  Right as RightIcon,
  ScaleToOriginal as ScaleToOriginalIcon,
  School as SchoolIcon,
  Scissor as ScissorIcon,
  Search as SearchIcon,
  Select as SelectIcon,
  Sell as SellIcon,
  SemiSelect as SemiSelectIcon,
  Service as ServiceIcon,
  Setting as SettingIcon,
  SetUp as SetUpIcon,
  Share as ShareIcon,
  Ship as ShipIcon,
  Shop as ShopIcon,
  ShoppingBag as ShoppingBagIcon,
  ShoppingCart as ShoppingCartIcon,
  ShoppingCartFull as ShoppingCartFullIcon,
  Smoking as SmokingIcon,
  Soccer as SoccerIcon,
  SoldOut as SoldOutIcon,
  Sort as SortIcon,
  SortDown as SortDownIcon,
  SortUp as SortUpIcon,
  Stamp as StampIcon,
  Star as StarIcon,
  StarFilled as StarFilledIcon,
  Stopwatch as StopwatchIcon,
  SuccessFilled as SuccessFilledIcon,
  Sugar as SugarIcon,
  Suitcase as SuitcaseIcon,
  Sunny as SunnyIcon,
  Sunrise as SunriseIcon,
  Sunset as SunsetIcon,
  Switch as SwitchIcon,
  SwitchButton as SwitchButtonIcon,
  TakeawayBox as TakeawayBoxIcon,
  Ticket as TicketIcon,
  Tickets as TicketsIcon,
  Timer as TimerIcon,
  ToiletPaper as ToiletPaperIcon,
  Tools as ToolsIcon,
  Top as TopIcon,
  TopLeft as TopLeftIcon,
  TopRight as TopRightIcon,
  TrendCharts as TrendChartsIcon,
  Trophy as TrophyIcon,
  TurnOff as TurnOffIcon,
  Umbrella as UmbrellaIcon,
  Unlock as UnlockIcon,
  Upload as UploadIcon,
  UploadFilled as UploadFilledIcon,
  User as UserIcon,
  UserFilled as UserFilledIcon,
  Van as VanIcon,
  VideoCamera as VideoCameraIcon,
  VideoCameraFilled as VideoCameraFilledIcon,
  VideoPause as VideoPauseIcon,
  VideoPlay as VideoPlayIcon,
  View as ViewIcon,
  Wallet as WalletIcon,
  WalletFilled as WalletFilledIcon,
  Warning as WarningIcon,
  WarningFilled as WarningFilledIcon,
  Watch as WatchIcon,
  Watermelon as WatermelonIcon,
  WindPower as WindPowerIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon
} from '@element-plus/icons-vue';

export default {
  name: 'IconSelect',
  components: {
    AddLocationIcon,
    AimIcon,
    AlarmClockIcon,
    AppleIcon,
    ArrowDownIcon,
    ArrowDownBoldIcon,
    ArrowLeftIcon,
    ArrowLeftBoldIcon,
    ArrowRightIcon,
    ArrowRightBoldIcon,
    ArrowUpIcon,
    ArrowUpBoldIcon,
    AvatarIcon,
    BackIcon,
    BaseballIcon,
    BasketballIcon,
    BellIcon,
    BellFilledIcon,
    BicycleIcon,
    BottomIcon,
    BottomLeftIcon,
    BottomRightIcon,
    BowlIcon,
    BoxIcon,
    BriefcaseIcon,
    BrushIcon,
    BrushFilledIcon,
    BurgerIcon,
    CalendarIcon,
    CameraIcon,
    CameraFilledIcon,
    CaretBottomIcon,
    CaretLeftIcon,
    CaretRightIcon,
    CaretTopIcon,
    CellphoneIcon,
    ChatDotRoundIcon,
    ChatDotSquareIcon,
    ChatLineRoundIcon,
    ChatLineSquareIcon,
    ChatRoundIcon,
    ChatSquareIcon,
    CheckIcon,
    CheckedIcon,
    CherryIcon,
    ChickenIcon,
    CircleCheckIcon,
    CircleCheckFilledIcon,
    CircleCloseIcon,
    CircleCloseFilledIcon,
    CirclePlusIcon,
    CirclePlusFilledIcon,
    ClockIcon,
    CloseIcon,
    CloseBoldIcon,
    CloudyIcon,
    CoffeeIcon,
    CoffeeCupIcon,
    CoinIcon,
    ColdDrinkIcon,
    CollectionIcon,
    CollectionTagIcon,
    CommentIcon,
    CompassIcon,
    ConnectionIcon,
    CoordinateIcon,
    CopyDocumentIcon,
    CpuIcon,
    CreditCardIcon,
    CropIcon,
    DArrowLeftIcon,
    DArrowRightIcon,
    DCaretIcon,
    DataAnalysisIcon,
    DataBoardIcon,
    DataLineIcon,
    DeleteIcon,
    DeleteFilledIcon,
    DeleteLocationIcon,
    DessertIcon,
    DiscountIcon,
    DishIcon,
    DishDotIcon,
    DocumentIcon,
    DocumentAddIcon,
    DocumentCheckedIcon,
    DocumentCopyIcon,
    DocumentDeleteIcon,
    DocumentRemoveIcon,
    DownloadIcon,
    DrizzlingIcon,
    EditIcon,
    EditPenIcon,
    ElemeIcon,
    ElemeFilledIcon,
    ElementPlusIcon,
    ExpandIcon,
    FailedIcon,
    FemaleIcon,
    FilesIcon,
    FilmIcon,
    FilterIcon,
    FinishedIcon,
    FirstAidKitIcon,
    FlagIcon,
    FoldIcon,
    FolderIcon,
    FolderAddIcon,
    FolderCheckedIcon,
    FolderDeleteIcon,
    FolderOpenedIcon,
    FolderRemoveIcon,
    FoodIcon,
    FootballIcon,
    ForkSpoonIcon,
    FriesIcon,
    FullScreenIcon,
    GobletIcon,
    GobletFullIcon,
    GobletSquareIcon,
    GobletSquareFullIcon,
    GoodsIcon,
    GoodsFilledIcon,
    GrapeIcon,
    GridIcon,
    GuideIcon,
    HeadsetIcon,
    HelpIcon,
    HelpFilledIcon,
    HideIcon,
    HistogramIcon,
    HomeFilledIcon,
    HotWaterIcon,
    HouseIcon,
    IceCreamIcon,
    IceCreamRoundIcon,
    IceCreamSquareIcon,
    IceDrinkIcon,
    IceTeaIcon,
    InfoFilledIcon,
    IphoneIcon,
    KeyIcon,
    KnifeForkIcon,
    LightningIcon,
    LinkIcon,
    ListIcon,
    LoadingIcon,
    LocationIcon,
    LocationFilledIcon,
    LocationInformationIcon,
    LockIcon,
    LollipopIcon,
    MagicStickIcon,
    MagnetIcon,
    MaleIcon,
    ManagementIcon,
    MapLocationIcon,
    MedalIcon,
    MenuIcon,
    MessageIcon,
    MessageBoxIcon,
    MicIcon,
    MicrophoneIcon,
    MilkTeaIcon,
    MinusIcon,
    MoneyIcon,
    MonitorIcon,
    MoonIcon,
    MoonNightIcon,
    MoreIcon,
    MoreFilledIcon,
    MostlyCloudyIcon,
    MouseIcon,
    MugIcon,
    MuteIcon,
    MuteNotificationIcon,
    NoSmokingIcon,
    NotebookIcon,
    NotificationIcon,
    OdometerIcon,
    OfficeBuildingIcon,
    OpenIcon,
    OperationIcon,
    OpportunityIcon,
    OrangeIcon,
    PaperclipIcon,
    PartlyCloudyIcon,
    PearIcon,
    PhoneIcon,
    PhoneFilledIcon,
    PictureIcon,
    PictureFilledIcon,
    PictureRoundedIcon,
    PieChartIcon,
    PlaceIcon,
    PlatformIcon,
    PlusIcon,
    PointerIcon,
    PositionIcon,
    PostcardIcon,
    PouringIcon,
    PresentIcon,
    PriceTagIcon,
    PrinterIcon,
    PromotionIcon,
    QuestionFilledIcon,
    RankIcon,
    ReadingIcon,
    ReadingLampIcon,
    RefreshIcon,
    RefreshLeftIcon,
    RefreshRightIcon,
    RefrigeratorIcon,
    RemoveIcon,
    RemoveFilledIcon,
    RightIcon,
    ScaleToOriginalIcon,
    SchoolIcon,
    ScissorIcon,
    SearchIcon,
    SelectIcon,
    SellIcon,
    SemiSelectIcon,
    ServiceIcon,
    SetUpIcon,
    SettingIcon,
    ShareIcon,
    ShipIcon,
    ShopIcon,
    ShoppingBagIcon,
    ShoppingCartIcon,
    ShoppingCartFullIcon,
    SmokingIcon,
    SoccerIcon,
    SoldOutIcon,
    SortIcon,
    SortDownIcon,
    SortUpIcon,
    StampIcon,
    StarIcon,
    StarFilledIcon,
    StopwatchIcon,
    SuccessFilledIcon,
    SugarIcon,
    SuitcaseIcon,
    SunnyIcon,
    SunriseIcon,
    SunsetIcon,
    SwitchIcon,
    SwitchButtonIcon,
    TakeawayBoxIcon,
    TicketIcon,
    TicketsIcon,
    TimerIcon,
    ToiletPaperIcon,
    ToolsIcon,
    TopIcon,
    TopLeftIcon,
    TopRightIcon,
    TrendChartsIcon,
    TrophyIcon,
    TurnOffIcon,
    UmbrellaIcon,
    UnlockIcon,
    UploadIcon,
    UploadFilledIcon,
    UserIcon,
    UserFilledIcon,
    VanIcon,
    VideoCameraIcon,
    VideoCameraFilledIcon,
    VideoPauseIcon,
    VideoPlayIcon,
    ViewIcon,
    WalletIcon,
    WalletFilledIcon,
    WarningIcon,
    WarningFilledIcon,
    WatchIcon,
    WatermelonIcon,
    WindPowerIcon,
    ZoomInIcon,
    ZoomOutIcon
  },
  props: {
    name: {
      type: String,
      default: 'View',
    },
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'inherit'
    }
  }
}

</script>
<style lang="">

</style>
