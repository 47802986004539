<template>
  <el-date-picker
    style="width: 100%;"
    v-model="value"
    value-format="YYYY-MM-DD HH:mm:ss"
    :type="dateTimeType"
    :range-separator="separator"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :disabled="disabled"
    :default-time="defaultTime"
  />
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  name: 'EasyDatetimePicker',
  props: {
    startValue: {
      type: String,
      default: undefined,
      require: true,
    },
    endValue: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: String,
      default: '至',
    },
    defaultTime: {
      type: String,
      default: ()=>new Date(2000,1,1,0,0,0),
    },
  },
  data() {
    return {
      value: '',
      dateTimeType: '',
    }
  },
  watch: {
    value() {
      if (!this.value) {
        $emit(this, 'update:startValue', '')
        $emit(this, 'update:endValue', '')
        return
      }
      if (this.endValue === undefined) {
        $emit(this, 'update:startValue', this.value)
      } else if (this.value[0]){
        $emit(this, 'update:startValue', this.value[0])
        $emit(this, 'update:endValue', this.value[1])
      }
    },
    startValue() {
      if (!this.startValue) {
        return
      }
      if (this.endValue === undefined) {
        this.value = this.startValue
      } else {
        if (!this.value) {
          this.value = []
        }
        this.value[0] = this.startValue
      }
    },
    endValue() {
      if (!this.endValue) {
        return
      }
      this.value[1] = this.endValue
      this.value = [...this.value]
    },
  },
  created() {
    if (this.endValue === undefined) {
      this.dateTimeType = 'datetime'
      this.value = this.startValue
    } else {
      this.dateTimeType = 'datetimerange'
      this.value = [this.startValue, this.endValue]
    }
  },
  emits: ['update:startValue', 'update:endValue'],
}
</script>
