<template>
    <div>
        <el-upload class="upload-demo" action="" :on-change="onChange" :on-preview="handlePreview" :on-remove="handleRemove"
            :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList"
            :auto-upload="false">
            <el-button size="small" type="primary">点击上传</el-button>
            <template #tip>
                <div class="el-upload__tip" v-if="fileList != ''">
                    <el-progress :percentage="progress"></el-progress>
                </div>
            </template>
            <!-- <div slot="tip" class="el-upload__tip" v-if="fileList != ''"><el-progress
                    :percentage="progress"></el-progress></div> -->
        </el-upload>
    </div>
</template>

<script>
//import x from ''
import request from "@/api/request";
import * as qiniu from "qiniu-js";
export default {
    //import引入的组件需要注入到对象中才能使用
    name: "QiniuUrl",
    components: {},
    //  props: {
    //     qnUrl: {
    //         type: String,
    //         default: "",
    //     },
    // },
    data() {
        return {
            fileList: [],
            progress: 0
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    created() {
        console.log(import.meta.env.VITE_VUE_APP_QINIU_URL)
    },
    mounted() {

    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fileList = [];
                this.$emit("qinUrl", "")
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getQiniuToken() {
            return request({
                url: "/v4/admin/token/get", //  对应后台token 请求接口
                method: "get"
            }).then(res => res.data).catch(e => {
                console.error("获取七牛云token失败", e);
            });
        },
        async uploadQinuImage(file) {
            const token = await this.getQiniuToken();
            var _this = this
            console.log(token)
            const key = new Date().getTime() + file.name;
            const config = {
                useCdnDomain: true,
                region: qiniu.region.z0,
                uptoken: token,
                chunkSize: 10,
                forceDirect: false,
                domain: import.meta.env.VITE_VUE_APP_QINIU_URL,     //配置好的七牛云域名  如   https://cdn.qniyun.com/
                // unique_names: true,
            };
            const putExtra = {
                fname: file.name,
                params: {},
                // mimeType: [] || null,
                ...config
            };
            const observable = qiniu.upload(file, key, token, putExtra, config);
            return new Promise((resolve, reject) => {
                const subscription = observable.subscribe({
                    next(res) {
                        _this.progress = parseInt(res.total.percent)
                    },
                    error(data) {
                        reject(data);
                    },
                    complete(data) {
                        var qnUrl = data.path = `${import.meta.env.VITE_VUE_APP_QINIU_URL}${data.key}`  //拼上七牛云域名  如   https://cdn.qniyun.com/ ${data.key}
                        resolve(qnUrl);
                    }
                }); // 上传开始
            });

        },
        onChange(file, fileList) {
            this.fileList = fileList
            this.uploadQinuImage(file.raw).then(res => {
                console.log(res)
                this.$emit("qinUrl", res)
                // this.form.url = res
            })
        },

    },
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, ////如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped lang="">

</style>
