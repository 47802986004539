<template>
  <div class="easy-picker">
    <el-date-picker :shortcuts="pickerOptions && pickerOptions.shortcuts"
      :disabled-date="pickerOptions && pickerOptions.disabledDate"
      :cell-class-name="pickerOptions && pickerOptions.cellClassName" v-model="deviceFormData.time" type="daterange"
      class="lll" start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" value-format="YYYY-MM-DD"
      :size="size" @change="changeTime">
    </el-date-picker>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    timeEcho: {
      type: Array,
      default: () => {
        ;[]
      },
    },
    days: {
      // 需要限制选择天数
      type: String,
      default: '',
    },
    defaultDays: {
      // //默认选择区间为当前时间倒推-1
      type: String,
      default: '',
    },
    size: {
      // 时间框大小
      type: String,
      default: '',
    },
    defaultTime: {
      type: String,
      default: '',
    },
  },
  data() {
    // 这里存放数据
    return {
      defTime: this.defaultTime,
      defDays: this.defaultDays,
      deviceFormData: {
        time: [],
      },
      data1Map: new Map(),
      data2Map: new Map(),
      pickerMinDate: null,
      pickerMaxDate: null,
      day: this.days * 24 * 3600 * 1000,
      // 日期使用
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime()
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day ||
              time.getTime() < this.pickerMinDate - this.day
            )
          }
          return false
        },
      },
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    defaultDays(newVal, oldVal) {
      this.defDays = newVal
      this.getTimeFnClick()

    },
    defaultTime(newVal, oldVal) {
      this.defTime = newVal
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

    this.timeData()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    $emit(this, 'update:startTime', this.deviceFormData.time[0])
    $emit(this, 'update:endTime', this.deviceFormData.time[1])
    $emit(this, 'deviceFormData', this.deviceFormData)
  },
  // 生命周期 - 更新之后
  updated() {

  },
  // 生命周期 - 销毁完成
  unmounted() { },
  activated() {
    setTimeout(() => {
      if (this.timeEcho === undefined) {
      } else {
        this.deviceFormData.time = this.timeEcho
      }
      // this.timeData()
    })
  },
  // 方法集合
  // 如果页面有keep-alive缓存功能，这个函数会触发
  methods: {
    changeTime() {
      $emit(this, 'deviceFormData', this.deviceFormData)
    },
    getTimeFnClick() {
      this.deviceFormData.time = []
      var day1 = new Date()
      var days = this.defTime * 24
      day1.setTime(day1.getTime() - days * 60 * 60 * 1000)
      const that = this
      const end = day1
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * this.defDays)
      that.deviceFormData.time[0] = that.formatDate(start)
      that.deviceFormData.time[1] = that.formatDate(end)
      $emit(this, 'deviceFormData', this.deviceFormData)
    },
    getTimeFn() {
      var day1 = new Date()
      var days = this.defTime * 24
      day1.setTime(day1.getTime() - days * 60 * 60 * 1000)
      const that = this
      const end = day1
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * this.defDays)
      that.deviceFormData.time[0] = that.formatDate(start)
      that.deviceFormData.time[1] = that.formatDate(end)
    },
    /**
     * 格式化时间
     */
    formatDate(date) {
      var myyear = date.getFullYear()
      var mymonth = date.getMonth() + 1
      var myweekday = date.getDate()

      if (mymonth < 10) {
        mymonth = '0' + mymonth
      }
      if (myweekday < 10) {
        myweekday = '0' + myweekday
      }
      return myyear + '-' + mymonth + '-' + myweekday
    },
    timeData() {
      if (this.timeEcho === undefined) {
        this.getTimeFn()
      } else {
        this.deviceFormData.time = this.timeEcho
      }
    },
  },
  emits: ['deviceFormData'],
}
</script>

<style lang="scss" scoped>
.el-range-editor.el-input__inner {
  width: 100%;
}

:deep(.lll) {
  width: 240px !important;
}
</style>
