import * as Vue from 'vue'
import Layout from '@/layout'

const personOpRouter = {
  path: '/person',
  component: Layout,
  name: 'person_op',
  meta: { title: '个人中心', icon: 'Avatar' },
  children: [
    // {
    //   path: '/account',
    //   component:(() => import('@/views/person/account/index')), // 父级 router-view
    //   name: 'person_op_user',
    //   meta: { title: '账号操作', icon: 'user' },
    //   redirect: '/resetPassword',
    //   children: [
        {
          path: '/person/resetPassword',
          component:(() => import('@/views/person/account/reset_password/reset')),
          name: 'PersonResetPassword',
          meta: { title: '修改密码', icon: 'EditPen', keepAlive: false },
        },
    //   ],
    // },
  ],
}
export default personOpRouter
