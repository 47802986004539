import * as Vue from 'vue'
import Layout from '@/layout'

const order = {
  path: '/order',
  component: Layout,
  redirect: '/order/collect',
  name: 'order',
  meta: {
    title: '订单管理',
    icon: 'Document',
  },
  children: [
    {
      path: '/order/collect',
      component: (() => import('@/views/order/orderListCollect/index')),
      name: 'finereport_order',
      meta: { title: '订单汇总', icon: 'Histogram' },
    },
    {
      path: '/order/list',
      component:(() => import('@/views/order/orderList/index')),
      name: 'item_order',
      meta: { title: '订单明细', icon: 'Comment' },
    },
    {
      path: '/order/cancel',
      component: (() => import('@/views/order/cancel/list')

      ),
      name: 'OrderCancel',
      meta: { title: '撤单审核', icon: 'WalletFilled' },
    },
    {
      path: '/order/cancel/detail',
      component: (() => import('@/views/order/cancel/detail')

      ),
      name: 'OrderCancelDetail',
      meta: { title: '撤单明细', icon: 'Operation' },
      hidden: true,
    },
    {
      path: '/order/import',
      component:(() => import('@/views/order/orderImport/index')),
      name: 'order_import',
      meta: { title: '订单导入', icon: 'UploadFilled' },
    },
    {
      path: '/order/batchImport',
      component:(() => import('@/views/order/batchImportOrder/batch-import-order')),
      name: 'BatchImportOrder',
      meta: { title: '批量下单', icon: 'Finished' },
    },
    {
      path: '/order/requirementsList',
      component:(() => import('@/views/order/requirementsList/index')),
      name: 'RequirementsList',
      meta: { title: '需求单列表', icon: 'CreditCard' },
    },
    {
      path: '/order/requirementsList/view',
      component:(() => import('@/views/order/requirementsList/view')),
      name: 'RequirementsView',
      meta: { title: '需求单查看', icon: 'el-icon-s-data' },
      hidden: true,
    },
    {
      path: '/order/dispatchOrder',
      component: (() => import('@/views/order/dispatch-order')

      ),
      name: 'DispatchOrder',
      meta: { title: '调度单列表', icon: 'Calendar' },
    },
    {
      path: '/order/zbTpOrg',
      component: (() => import('@/views/order/zb-tp-org')

      ),
      name: 'ZbTpOrg',
      // hidden: true,
      meta: { title: '总部直投组织', icon: 'Coin' },
    },
    {
      path: '/order/zbTpOrgEdit',
      component: (() => import('@/views/order/zb-tp-org/edit')),
      name: 'ZbTpOrgEdit',
      hidden: true,
      meta: { title: '总部直投组织详情', icon: 'el-icon-s-order' },
    },
    {
      path: '/order/zbTpOrgStore',
      component:(() => import('@/views/order/zb-tp-org/store-list')),
      name: 'ZbTpOrgStore',
      hidden: true,
      meta: { title: '总部直投组织终端', icon: 'el-icon-s-order' },
    },
    // {
    //   path: '/order/editDispatchOrder/:billNoDemand',
    //   component: () => import("@/views/order/dispatch-order/edit"),
    //   name: "EditDispatchOrder",
    //   props: true,
    //   hidden: true,
    //   meta: { title: '调度单编辑', icon: 'el-icon-s-order' },
    // },
    {
      path: '/order/newEditDispatchOrder',
      component:(() => import('@/views/order/dispatch-order/edit')),
      name: 'EditDispatchOrder',
      hidden: true,
      meta: { title: '调度单编辑', icon: 'el-icon-s-order' },
    },
    {
      path: "/airdrop/airdrop-logs",
      component: () => import("@/views/airdrop/airdrop-logs"),
      name: "AirdropLogs",
      meta: { title: "空投日志", icon: "ScaleToOriginal" },
    },  
    {
      path: '/order/zgUnsent',
      component: (() => import('@/views/order/zg-unsent')

      ),
      name: 'ZgUnsent',
      meta: { title: '终端订单列表(联盟专供)', icon: 'PriceTag' },
    },
    {
      path: '/order/zgUnsentView',
      component:(() => import('@/views/order/zg-unsent/view')),
      name: 'ZgUnsentView',
      hidden: true,
      meta: { title: '专供未发订单查看', icon: 'el-icon-s-order' },
    },
    {
      path: '/order/zgStockout/DCStockout',
      component:(() => import('@/views/order/zgStockout/DCStockout')),
      name: 'ZgDCStockout',
      meta: { title: '配送中心缺货(联盟专供)', icon: 'Suitcase' },
    },
    {
      path: '/order/zgStockout/branchStockout',
      component:(() => import('@/views/order/zgStockout/branchStockout')),
      name: 'ZgBranchStockout',
      meta: { title: '分公司缺货(联盟专供)', icon: 'ShoppingTrolley' },
    },
    {
      path: '/order/zgStockout/shortageDetail',
      component:(() => import('@/views/order/zgStockout/shortageDetail')),
      name: 'ZgShortageDetail',
      hidden: true,
      meta: { title: '专供缺货详情', icon: 'el-icon-s-order' },
    },
    {
      path: '/order/zgStockout/replenOrdersDetail',
      component:(() => import('@/views/order/zgStockout/replenOrdersDetail')),
      name: 'ZgReplenOrdersDetail',
      hidden: true,
      meta: { title: '补货单详情', icon: 'el-icon-s-order' },
    },
    {
      path: '/order/allReplenishOrders',
      component:(() => import('@/views/order/allReplenishOrders')),
      name: 'AllReplenishOrders',
      meta: { title: '补货单(联盟专供)', icon: 'TakeawayBox' },
    },
    {
      path: '/order/allReplenishOrders/add',
      component:(() => import('@/views/order/allReplenishOrders/add')),
      name: 'AddReplenishOrders',
      hidden: true,
      meta: { title: '新增补货单', icon: 'el-icon-s-order' },
    },
    {
      path: '/order/afterSalesApproval',
      component:(() => import('@/views/order/afterSalesApproval')),
      name: 'AfterSalesApproval',
      meta: { title: '售后审批', icon: 'TakeawayBox' },
    },
      {
      path: '/order/afterSalesApproval/edit',
      component:(() => import('@/views/order/afterSalesApproval/edit.vue')),
      name: 'DetailsOfAfterSalesApproval',
      hidden: true,
      meta: { title: '详情', icon: 'TakeawayBox' },
    },

  ],
}

export default order
