import * as Vue from 'vue'
import Layout from '@/layout'

const activityRouter = {
  path: '/activity',
  component: Layout,
  name: 'activity',
  redirect: '/activity/activityList',
  meta: { title: '活动管理', icon: 'Coin' },
  children: [
    {
      path: '/activity/activityList',
      component: (() => import('@/views/activity/activity/activityList/list')),
      name: 'UserActivity',
      meta: { title: '活动列表', icon: 'Odometer' },
    },
    {
      path: '/activity/activityList-target',
      component:(() => import('@/views/activity/activity-target')),
      name: 'ActivityTarget',
      meta: { title: '活动目标管理', icon: 'HelpFilled' },
    },
    {
      path: '/activity/activityList-target/dataSupplement',
      component: (() => import('@/views/activity/activity-target/data-supplement')),
      name: 'DataSupplement',
      hidden: true,
      meta: { title: '补录数据', icon: 'clipboard' },
    },
    {
      path: '/activity/activityList-target/noticeRecord',
      component:(() => import('@/views/activity/activity-target/notice-record')),
      name: 'noticeRecord',
      hidden: true,
      meta: { title: '通知记录', icon: 'clipboard' },
    },
    {
      path: '/activity/activityTargetBaseSetting/:id',
      component:(() => import('@/views/activity/activity-target/base-setting')),
      name: 'ActivityTargetBaseSetting',
      meta: { title: '活动目标基本设置', icon: 'clipboard' },
      hidden: true,
      props: true,
    },
    {
      path: '/activity/activityTargetSetting',
      component:(() => import('@/views/activity/activity-target/target-setting')),
      name: 'ActivityTargetSetting',
      hidden: true,
      meta: { title: '活动目标设置', icon: 'clipboard' },
    },
    {
      path: '/activity/createActivity',
      component:(() => import('@/views/activity/activity/activityList/create')),
      name: 'ActivityCreate',
      meta: { title: '新建活动' },
      hidden: true,
    },
    {
      path: '/activity/editActivity',
      component:(() => import('@/views/activity/activity/activityList/edit')),
      name: 'activity_edit',
      meta: { title: '编辑活动' },
      hidden: true,
    },
    {
      path: '/activity/goodsDetails',
      component:(() => import('@/views/activity/activity/activityList/import')),
      name: 'goodsDetails',
      meta: { title: '编辑产品池' },
      hidden: true,
    },
    {
      path: '/activity/send',
      component:(() => import('@/views/activity/activity/activityList/send')),
      name: 'SendActivity',
      meta: { title: '发送优惠券', noCache: true },
      hidden: true,
    },
    {
      path: '/activity/editActivityGoods',
      component: (() => import('@/views/activity/activity/activityList/editGoods')),
      name: 'editActivityGoods',
      meta: { title: '编辑活动商品' },
      hidden: true,
    },
    {
      path: '/activity/crowdlist',
      component: (() => import('@/views/crowd-funding/crowd-list/list')),
      name: 'CrowdFundIngList',
      meta: { title: '众筹活动列表', icon: 'Promotion', keepAlive: false },
    },
    {
      path: '/productPoolList',
      component: (() => import('@/views/crowd-funding/product-pool/list')),
      name: 'crowdProductPoolList',
      meta: {
        title: '众筹活动产品池列表',
        icon: 'el-icon-copy-document',
        keepAlive: false,
      },
      hidden: true,
    },
    {
      path: '/importGoods',
      component:(() =>import('@/views/crowd-funding/crowd-list/crowd-list-detail/import')),
      name: 'goods_import',
      meta: { title: '导入商品', keepAlive: true },
      hidden: true,
    },
    {
      path: '/createCrowd',
      component:(() => import('@/views/crowd-funding/crowd-list/create')),
      name: 'createCrowdFunding',
      meta: { title: '新建众筹活动', keepAlive: false },
      hidden: true,
    },
    {
      path: '/editCrowd',
      component:(() => import('@/views/crowd-funding/crowd-list/edit')),
      name: 'editCrowdFunding',
      meta: { title: '编辑众筹活动', keepAlive: false },
      hidden: true,
    },
    {
      path: '/activity/groupRelay',
      component: (() => import('@/views/activity/group-relay')
      ),
      name: 'GroupRelay',
      meta: { title: '群接龙管理', keepAlive: true },
      hidden: true,
    },
    {
      path: '/activity/headquarters-crowdfunding/activity',
      component: (() => import('@/views/activity/headquarters-crowdfunding/activity/index.vue')),
      name: 'HeadquartersCrowdfundingActivity',
      meta: { title: '总部众筹活动管理', keepAlive: true, icon: 'Document' },
    },
    {
      path: '/activity/headquarters-crowdfunding/commodity',
      component: (() => import('@/views/activity/headquarters-crowdfunding/commodity/index.vue')),
      name: 'HeadquartersCrowdfundingCommodity',
      meta: { title: '总部众筹商品', keepAlive: true, icon: 'Notebook' },
      hidden: true,
    },
    {
      path: '/activity/headquarters-crowdfunding/order',
      component: (() => import('@/views/activity/headquarters-crowdfunding/order/index.vue')),
      name: 'HeadquartersCrowdfundingOrder',
      meta: { title: '总部众筹订单', keepAlive: true, icon: 'Notebook' },
    },
  ],
}

export default activityRouter
