import Layout from '@/layout'

const score = {
  path: '/luck',
  component: Layout,
  redirect: '/luck/luckDraw',
  name: 'LuckModule',
  meta: {
    title: '抽奖活动',
    icon: 'Money'
  },
  children: [
    {
      path: '/luck/luckDraw',
      component: () => import('@/views/luck-draw/index'),
      name: 'LuckDraw',
      meta: { title: '抽奖活动', icon: 'Box' }
    },
    {
      path: '/luck/luckDrawRecord',
      component: () => import('@/views/luck-draw/record'),
      name: 'LuckDrawRecord',
      meta: { title: '中奖记录', icon: 'Box' }
    },
    {
      path: "/luck/luckDraw/edit",
      component: () => import("@/views/luck-draw/edit"),
      name: "EditLuckDraw",
      props: true,
      meta: { title: "编辑抽奖活动", icon: "el-icon-picture", noCache: true },
      hidden: true
    },
    {
      path: "/luck/luckDraw/view",
      component: () => import("@/views/luck-draw/view"),
      name: "ViewLuckDraw",
      props: true,
      meta: { title: "查看抽奖活动", icon: "el-icon-picture", noCache: true },
      hidden: true
    },
  ]
}

export default score
