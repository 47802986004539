import Layout from '@/layout'

const task = {
  path: '/task',
  component: Layout,
  redirect: '/task/manage',
  name: 'TaskModule',
  meta: {
    title: '任务管理',
    icon: 'Tickets'
  },
  children: [
    {
      path: '/task/manage',
      component: () => import('@/views/task/task-manage/index'),
      name: 'TaskManage',
      meta: { title: '任务列表', icon: 'Document' }
    },
        {
      path: '/task-report',
      component: () => import('@/views/task/task-report/index'),
      name: 'TaskReport',
      meta: { title: '238上柜任务报表', icon: 'Document' }
    },
    {
      path: '/task-listing',
      component: () => import('@/views/task/task-listing/index'),
      name: 'TaskListing',
      meta: { title: '小学汛上柜任务报表', icon: 'Document' }
    },
    {
      path: '/work-report',
      component: () => import('@/views/task/work-report/index'),
      name: 'WorkReport',
      meta: { title: '办公上柜任务报表', icon: 'Document' }
    },
    
    {
      path: '/task/item',
      component: () => import('@/views/task/item-manage/index'),
      name: 'ItemManage',
      hidden:true,
      meta: { title: '商品管理', icon: 'Memo' }
    },
  ]
}

export default task
