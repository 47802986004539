import * as Vue from 'vue'
import Layout from '@/layout'

const statistics = {
  path: '/statistics',
  component: Layout,
  redirect: '/statistics/finereport_list',
  name: 'statistics',
  meta: {
    title: '数据报表',
    icon: 'FolderOpened',
  },
  children: [
    {
      path: '/statistics/finereport_list',
      component:(() => import('@/views/statistics/finereport_list/index')),
      name: 'FinereportList',
      meta: { title: '报表中心', icon: 'VideoCameraFilled' },
    },
    {
      path: '/statistics/finereport_auth',
      component:(() => import('@/views/statistics/finereport_auth/index')),
      name: 'FinereportAuth',
      meta: { title: '报表设置', icon: 'Tools' },
    },
    {
      path: '/statistics_box',
      component:(() => import('@/views/statistics/statistics/index')),
      meta: { title: '报表页', icon: 'el-icon-s-data', noCache: true },
      name: 'statistics_box',
      hidden: true,
    },
    {
      path: '/statistics/import_export',
      component:(() => import('@/views/statistics/import_export_centre/index')),
      meta: { title: '导入导出列表', icon: 'UploadFilled' },
      name: 'ImportExport',
    },
  ],
}

export default statistics
