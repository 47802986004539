class staticData
{
  static DEFAULT_CONTENT = [
    {
      type: 'ITEM',
      name: '商品详细',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'ITEMLIST',
      name: '商品列表',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'POST',
      name: '文章详细',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'POSTLIST',
      name: '文章列表',
      linkUrlShow: false,
      activityShow: true,
    },
    { type: 'LIVE', name: '直播', linkUrlShow: false, activityShow: true },
    {
      type: 'COUPON',
      name: '优惠券',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'NEW_PURCHASING',
      name: '新订货会',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'INTENT',
      name: '订货会',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'ARRIVE',
      name: '我的到货提醒',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'MESSAGE',
      name: '消息',
      linkUrlShow: false,
      activityShow: false,
    },
    { type: 'VR', name: 'VR', linkUrlShow: false, activityShow: true },
    {
      type: 'LIVE_OR_VIDEO',
      name: '直播与商品视频列表',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'VIDEO',
      name: '视频',
      linkUrlShow: false,
      activityShow: false,
    },
    { type: 'EVENT', name: '活动', linkUrlShow: true, activityShow: false },
    {
      type: 'REGULARLY_PURCHASE_LIST',
      name: '常购清单',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'ALLITEMS',
      name: '全部商品',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'NEWITEMS',
      name: '新品订货',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'BUSSNESS',
      name: '生意经',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'CROWD',
      name: '众筹',
      linkUrlShow: false,
      activityShow: false,
    },
    // {
    //   type: 'SMART',
    //   name: '智能推荐',
    //   linkUrlShow: false,
    //   activityShow: true,
    // },
    {
      type: 'SALES',
      name: '限时抢购聚合页',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'SALE',
      name: '限时抢购列表',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'LANDING_PAGE',
      name: '落地页',
      url: '/v4/admin/landingPage/list',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'NEW_LANDING_PAGE',
      name: '新落地页',
      url: '/v4/admin/landingPage/new/list',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'LOCAL_HOT_SALE',
      name: '本地热卖',
      linkUrlShow: false,
      activityShow: false,
    },
     {
      type: "TOPIC",
      name: "话题广场",
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: "ANNUAL_REPORT",
      name: "H5",
      linkUrlShow: true,
      activityShow: false,
    },
        {
      type: "ANNUAL_REPORT_TITLE",
      name: "H5（带标题栏）",
      linkUrlShow: true,
      activityShow: false,
    },
      {
      type: "NEW_BILLBOARD",
      name: "榜单",
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: "ACTIVITY_PAGE",
      name: "本地活动",
      linkUrlShow: false,
      activityShow: false,
    },
    /*
    { type: 'LOOK_VIDEO_TAB', name: '逛逛', url: "" },
    { type: 'LOOK_VIDEO_PAGE', name: '视频播放页', url: "/v4/admin/productVideo/detail" },
    { type: 'SALE_PAGE', name: '限时抢购商详', url: "/v4/admin/item/sku/listByName" },
     */
    {
      type: 'LOOK_VIDEO_TAB',
      name: '视频选货-标签',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'LOOK_VIDEO_PAGE',
      name: '视频播放页',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'SALE_PAGE',
      name: '限时抢购商详',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'PROMOTION_TASK',
      name: '推广任务',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'NEW_TOPIC',
      name: '话题详情页',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'SELECT_GOODS_TAB',
      name: '视频选货-栏目',
      linkUrlShow: false,
      activityShow: true,
    },
    {
      type: 'NEW_RAISE_LIST',
      name: '新众筹聚合页',
      linkUrlShow: false,
      activityShow: false,
    },
    {
      type: 'NEW_RAISE_DETAIL',
      name: '新众筹详情',
      linkUrlShow: false,
      activityShow: true,
    },

  ]
  static homeType = {
    targetUrlShow: {
      ITEM: 0,
      ITEMLIST: 0,
      POST: 0,
      POSTLIST: 0,
      LIVE: 0,
      COUPON: 0,
      INTENT: 0,
      ARRIVE: 0,
      MESSAGE: 0,
      VR: 0,
      LIVE_OR_VIDEO: 0,
      VIDEO: 0,
      EVENT: 1,
      REGULARLY_PURCHASE_LIST: 0,
      ALLITEMS: 0,
      NEWITEMS: 0,
      BUSSNESS: 0,
    },
    jumpType: [
      'ITEM',
      'ITEMLIST',
      'POST',
      'POSTLIST',
      'LIVE',
      'COUPON',
      'INTENT',
      'ARRIVE',
      'MESSAGE',
      'VR',
      'LIVE_OR_VIDEO',
      'VIDEO',
      'EVENT',
      'REGULARLY_PURCHASE_LIST',
      'ALLITEMS',
      'NEWITEMS',
      'BUSSNESS',
    ],
    jumpStr: {
      ITEM: '商品详细',
      ITEMLIST: '商品列表',
      POST: '文章详细',
      POSTLIST: '文章列表',
      LIVE: '直播',
      COUPON: '优惠券',
      NEWPURCHASING: '新订货会',
      INTENT: '订货会',
      ARRIVE: '我的到货提醒',
      MESSAGE: '消息',
      VR: 'VR',
      LIVE_OR_VIDEO: '直播与商品视频列表',
      VIDEO: '视频',
      EVENT: '活动',
      REGULARLY_PURCHASE_LIST: '常购清单',
      ALLITEMS: '全部商品',
      NEWITEMS: '新品订货',
      BUSSNESS: '生意经',
    },
    jumpUrl: {
      // 商品详细    需要配置 商品 ID
      ITEM: '/v4/admin/item/listByName',
      // 商品列表   需要配置 商品列表 ID
      ITEMLIST: '/v4/admin/goodsType/listByName',
      // 文章详细  	需要配置 文章 ID
      POST: '/v4/admin/post/listByName',
      // 	文章列表  需要配置 文章列表 ID 文章标题
      POSTLIST: '/v4/admin/channelNew/listByName',
      // 直播  需要 Url
      LIVE: '/v4/admin/mgLive/live',
      // 优惠券
      COUPON: '',
      // 订货会
      INTENT: '',
      // 到货提醒
      ARRIVE: '',
      // 消息
      MESSAGE: '',
      // 	VR
      VR: '/v4/admin/vr/listByName',
      // 直播与商品视频列表
      LIVE_OR_VIDEO: '',
      // 视频
      VIDEO: '',
      // 活动    需要H5 Url
      EVENT: '',
      // 常购清单  不需要h5 不需要url 不需要targetId
      REGULARLY_PURCHASE_LIST: '',
      // 全部商品
      ALLITEMS: '',
      // 新品订货
      NEWITEMS: '',
      // 	生意经
      BUSSNESS: '',
    },
  }
  static crowdactivity = {
    CROWDLEVELLIST: [ '商品级' ],
    CROWDMODELIST: [ '阶梯价格', '阶梯赠品', '阶梯价格和赠品' ],
    CROWDSTATUS: [ '上线和下线', '上线', '下线', '作废' ],
    CROWDTYPE: [ '众筹' ],
    CROWDSHOW: [ '显示', '不显示' ],
  }
}
export default staticData
