<template>
  <el-select
    v-model="selectValue"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
  >
    <el-option
      v-for="(item, i) in data"
      :key="i"
      :label="label ? item[label] : item.name"
      :value="value ? item[value] : item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'

export default {
  name: 'EasyDataSelect',
  // model: {
  //   prop: 'modelValue',
  //   event: 'modelValueChange',
  // },
  props: [
    'modelValue',
    'label',
    'value',
    'clearable',
    'disabled',
    'url',
    'method',
    'params',
    'placeholder',
    'dataFormat'
  ],
  data() {
    return {
      selectValue: '',
      data: [],
    }
  },
  watch: {
    modelValue() {
      // console.log(this.modelSwitch)
      // if (this.cutModelSwitch()) {
      //   return
      // }
      this.selectValue = this.modelValue
      // this.$emit("change")
    },
    selectValue() {
      // console.log(this.selectValue)
      // console.log(this.modelSwitch)
      // if (this.cutModelSwitch()) {
      //   return
      // }
      this.$emit("update:modelValue", this.selectValue)
      // $emit(this, 'modelValueChange', this.selectValue)
      for (var datum of this.data) {
        const value = datum[this.value ? this.value : 'id']
        if (value == this.selectValue) {
          this.$emit("change", datum)
          return;
        }
      }
      $emit(this, 'change')
    },
    url() {
      console.log("hello")
      this.getData()
    },
    disabled() {
      this.getData()
    },
    params() {
      this.getData()
    }
  },
  created() {
    this.selectValue = this.modelValue
    this.getData()
  },
  methods: {
    getData() {
      if (!this.url || this.disabled) {
        return
      }
      const config = {}
      config.url = this.url
      config.method = this.method ? this.method : 'GET'
      const payload = {
        ...this.params,
      }
      if (config.method === 'GET') {
        config.params = payload
      } else {
        config.data = payload
      }
      request(config).then((data) => {
        const temp = data.data
        if (temp.data && temp.data.length > 0) {
          this.data = this.dataFormat ? this.dataFormat(temp.data) : temp.data
        } else if (temp.list && temp.list.length > 0) {
          this.data = this.dataFormat ? this.dataFormat(temp.list) : temp.list
        } else if (temp.items && temp.items.length > 0) {
          this.data = this.dataFormat ? this.dataFormat(temp.items) : temp.items
        } else {
          this.data = []
        }
        this.$emit("dataLoaded")
      })
    },
    getValue(value, row) {
      return value instanceof Function ? value(row) : value
    },
  },
  emits: ['modelValueChange', 'change'],
}
</script>

<style lang="scss" scoped>
#easy-select {}
</style>
