import Layout from "@/layout";

const airdropRouter = {
  path: "/airdrop",
  component: Layout,
  name: "AirdropKanban",
  meta: { title: "总部空投看板", icon: "DataAnalysis" },
  children: [
    // {
    //   path: "/airdrop/airdrop-logs",
    //   component: () => import("@/views/airdrop/airdrop-logs"),
    //   name: "AirdropLogs",
    //   meta: { title: "空投日志", icon: "ScaleToOriginal" },
    //   // hidden: true
    // },  
    {
        path: "/airdrop",
        component: () => import("@/views/airdrop/Kanban-board"),
        name: "KanbanBoard",
        meta: { title: "总部空投看板", icon: "clipboard" },
        hidden: true
      },  

  ]
};

export default airdropRouter;
