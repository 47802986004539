import * as Vue from 'vue'
import Layout from '@/layout'

const test = {
  path: '/test',
  component: Layout,
  redirect: '/test/index',
  name: 'test',
  meta: {
    title: '组件测试',
    icon: 'el-icon-s-data',
  },
  hidden: true,
  children: [
    {
      path: '/test/index',
      component: (() => import('@/views/test/index')),
      name: 'test',
      meta: { title: '组件测试', icon: 'el-icon-setting' },
      hidden: true,
    },
  ],
}

export default test
