<template>
  <div id="easy-excel-import-pro">
    <el-button :icon="hiddenBtnIcon ? '' : 'Upload'" plain :type="btnType ? btnType : 'primary'"
      :size="btnSize ? btnSize : 'medium'" @click="dialogVisible = true">{{ btnText ? btnText : '导入' }}</el-button>
    <el-dialog :title="title ? title : '文件导入'" v-model="dialogVisible" width="600px" align-center :modal="modal">
      <el-form ref="form" :model="form" label-width="100px" :inline="false">
        <el-form-item v-if="dialogVisible" label="文件：">
          <easy-file-select v-model:file="form.file" accept=".xls,.xlsx" :template-url="templateUrl" :tip="tip"
            :template-text="templateText"></easy-file-select>
        </el-form-item>
        <el-form-item v-if="showImportType" label="导入方式：">
          <div>
            <el-radio-group v-model="form.importType">
              <el-radio :disabled="disabledIncrement" :label="1">增量</el-radio>
              <el-radio :disabled="disabledOverride" :label="0">覆盖</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label=" ">
          <div>
            <div style="margin-bottom: 10px;">
              <slot name="comment"></slot>
            </div>
            <div>
              <el-button type="success" :loading="submitLoading" icon="Upload" @click="submit()">{{ async ? '提 交' : '导 入'
                }}</el-button>
              <el-button @click="dialogVisible = false">取 消</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Upload as ElIconUpload } from '@element-plus/icons-vue'
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      showImportType: true,
      form: {
        file: null,
        importType: 1,
      },
      ElIconUpload,
    }
  },
  name: 'EasyExcelImportPro',
  components: {},
  props: [
    'url',
    'title',
    'method',
    'params',
    'templateUrl',
    'templateText',
    'message',
    'modal',
    'tip',
    'id',
    'disabledIncrement',
    'disabledOverride',
    'btnSize',
    'btnText',
    'btnType',
    'hiddenBtnIcon',
    'async',
  ],
  created() {
    if (this.disabledOverride && this.disabledIncrement) {
      this.showImportType = false
    } else {
      if (this.disabledIncrement) {
        this.form.importType = 0
      }
      if (this.disabledOverride) {
        this.form.importType = 1
      }
    }
  },
  methods: {
    getValue(value) {
      return value instanceof Function ? value() : value
    },
    submit() {
      if (!this.form.file) {
        this.$message.error('文件不能为空！')
        return
      }
      if (!this.url) {
        return
      }
      this.submitLoading = true
      const data = {
        file: this.form.file,
        ...this.getValue(this.params),
      }
      if (this.showImportType) {
        data.importType = this.form.importType
      }
      if (this.id) {
        data.id = this.id
      }
      request({
        url: this.url,
        method: this.method ? this.method : 'POST',
        data: this.$toFormData(data),
      })
        .then((data) => {
          if (data.code == 30004) {
            this.$message.error(data.data ? data.message : '导入失败')
            window.open(data.data ? data.data.url : data.message)
            // window.location.href = data.data ? data.data.url : data.message;
          } else if (data.code == 200003) {
            this.$message.error("导入失败")
            window.open(data.message)
          } else {
            if (this.async) {
              this.$message({
                type: 'success',
                dangerouslyUseHTMLString: true,
                message: `提交成功, <a href="#/statistics/import_export">点击前往<span style="color: #409eff;">导入导出列表</span>查看导入结果</a>`,
              })
            } else {
              this.$message.success(this.message ? this.message : '导入成功!')
            }
            $emit(this, 'success', data)
          }
        })
        .finally(() => {
          this.form.file = null
          this.submitLoading = false
          this.dialogVisible = false
        })
    },
  },
  emits: ['success'],
}
</script>

<style lang="scss" scoped>
#easy-excel-import-pro {
  display: inline-block;
}
</style>
