import Layout from '@/layout'

const store = {
  path: '/store',
  component: Layout,
  redirect: '/store/tag',
  name: 'StoreModule',
  meta: {
    title: '标签管理',  // 终端互动
    icon: 'SetUp'
  },
  children: [
    {
      path: '/store/tag',
      component: () => import('@/views/store/tag/index.vue'),
      name: 'StoreTagManage',
      meta: { title: '终端标签管理', icon: 'Money' }
    },
    {
      path: '/store/tag-detail',
      component: () => import('@/views/store/tag-detail/index.vue'),
      name: 'StoreTagDetail',
      meta: { title: '终端标签详情', icon: 'Money' }
    },
    {
      path: '/store/store-tag-task',
      component: () => import('@/views/store/store-tag-task/index.vue'),
      name: 'StoreTagTask',
      meta: { title: '终端标签任务', icon: 'Money' }
    },
    {
      path: '/store/product-label-list',
      component: () => import('@/views/store/product-label-list/index.vue'),
      name: 'StoreProductLabelList',
      meta: { title: '商品标签列表', icon: 'Money' }
    },
    {
      path: '/store/product-label-detail',
      component: () => import('@/views/store/product-label-detail/index.vue'),
      name: 'StoreProductLabelDetail',
      meta: { title: '商品标签详情', icon: 'Money' }
    },
    {
      path: '/store/product-tag-task',
      component: () => import('@/views/store/product-tag-task/index.vue'),
      name: 'ProductTagTask',
      meta: { title: '商品标签任务', icon: 'Money' }
    },


  ]
}

export default store
