<template>
  <div id="di-table" style="display: inline-block">
    <el-tag
      v-for="(el, i) in checkedRowData"
      :key="i"
      type="success"
      effect="dark"
      class="di-tag"
      :disable-transitions="true"
    >
      {{ el[tagLabel] }}
      <el-icon style="top: 3px;" @click="deleteCheckedData(el)"><Close /></el-icon>
    </el-tag>

    <el-button
      v-if="multiple || checkedRowData.length == 0"
      icon="Plus"
      type="primary"
      style="margin-left: 5px"
      :disabled="disabled"
      @click="openDiTable"
    >
      {{ btnText }}
    </el-button>

    <el-dialog
      :title="btnText"
      width="55%"
      v-model="tableIsShow"
      style="text-align: left"
    >
      <el-row v-if="searchMap != null" :gutter="10">
        <el-col v-for="(value, key, i) in searchMap" :key="i" :span="4" :sm="6">
          <el-input v-model="keywords[key]" :placeholder="value" />
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="Search" @click="search()">
            搜索
          </el-button>
        </el-col>
      </el-row>

      <el-table
        v-loading="tableLoading"
        border
        :data="tableData"
        style="margin-top: 10px"
      >
        <el-table-column
          v-for="(value, key, index) in fieldMap"
          :key="index"
          :prop="key"
          :label="value"
          align="center"
        />
        <el-table-column label="操作" align="center" width="130">
          <template v-slot="{ row }">
            <div v-if="multiple">
              <el-button
                v-if="hasRow(row)"
                type="danger"
                
                icon="Delete"
                @click="deleteCheckedData(row)"
                >移除</el-button
              >
              <el-button
                v-else
                type="primary"
                
                icon="Plus"
                @click="checkedRow(row)"
                >选择</el-button
              >
            </div>
            <div v-else>
              <el-button
                v-if="hasRow(row)"
                disabled
                
                icon="Check"
                >已选择</el-button
              >
              <el-button
                v-else
                type="primary"
                
                icon="Plus"
                @click="checkedRow(row)"
                >选择</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 10px"
        background
        :current-page="page.pageNo"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
      />
    </el-dialog>
  </div>
</template>

<script>

import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'

export default {
  data() {
    return {
      tableIsShow: false,
      tableLoading: false,
      keywords: {
        ...(this.searchMap
          ? Object.keys(this.searchMap).map((el) => ({}[el] = ''))
          : {}),
      },
      tableData: [],
      checkedRowData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

    }
  },
  components: {

  },
  name: 'Ditable',
  props: {
    btnText: {
      type: String,
      default: '添加',
    },
    url: {
      type: String,
      require: true,
    },
    params: Object,
    fieldMap: Object,
    multiple: {
      type: Boolean,
      default: false,
    },
    searchMap: {
      type: Object,
    },
    tagLabel: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tagData: {},
  },
  watch: {
    tagData() {
      if (this.tagData) {
        if (this.tagData instanceof Array) {
          this.checkedRowData = [...this.tagData]
        } else {
          this.checkedRowData =
            JSON.stringify(this.tagData) == '{}' ? [] : [this.tagData]
        }
      }
    },
  },
  created() {
    if (this.tagData) {
      if (this.tagData instanceof Array) {
        this.checkedRowData = [...this.tagData]
      } else {
        this.checkedRowData =
          JSON.stringify(this.tagData) == '{}' ? [] : [this.tagData]
      }
    }
  },
  methods: {
    getData() {
      this.tableLoading = true
      this.tableData = []
      const params = {
        ...this.params,
        ...this.page,
      }
      if (this.searchMap) {
        Object.keys(this.searchMap).forEach((key) => {
          if (this.keywords[key]) {
            params[key] = this.keywords[key]
          }
        })
      }
      request({
        url: this.url,
        params: params,
      }).then((data) => {
        this.tableData = data.data.data
        this.page.total = data.data.total
        this.tableLoading = false
      })
    },
    openDiTable() {
      this.tableIsShow = true
      this.page.pageNo = 1
      for (const key in this.keywords) {
        this.keywords[key] = ''
      }
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getData()
    },
    pageCurrentChange(pageNo) {
      this.page.pageNo = pageNo
      this.getData()
    },
    search() {
      this.page.pageNo = 1
      this.getData()
    },
    checkedRow(row) {
      if (this.multiple) {
        this.checkedRowData.push(row)
        $emit(this, 'change', this.checkedRowData)
      } else {
        this.checkedRowData = [row]
        $emit(this, 'change', row)
      }
    },
    hasRow(row) {
      for (let i = 0; i < this.checkedRowData.length; i++) {
        const item = this.checkedRowData[i]
        if (item == row) {
          return true
        }
      }
    },
    deleteCheckedData(item) {
      if (this.multiple) {
        this.checkedRowData = this.checkedRowData.filter((el) => {
          return !(item == el)
        })
      } else {
        this.checkedRowData = []
      }
      $emit(this, 'change', this.checkedRowData)
    },
  },
  emits: ['change'],
}
</script>

<style lang="scss" scoped>
.di-tag {
  font-size: 14px;
  height: 36px;
  line-height: 34px;
  margin-left: 5px;
  i {
    margin-top: 1px;
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.di-tag:hover {
  i {
    background: #fff;
    color: #1890ff;
  }
}
</style>
