import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  // roles是异步返回的角色，route是本地的路由
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role))
  } else {
    return true
  }
}

function hasAsyncPermission(roles, route) {
  // roles是后端异步返回的路由，route是本地的单个路由
  if (route.name) {
    return roles.includes(route.name)
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

export function formatAsyncRoutes(routes, roles) {
  // routes是本地异步的路由，roles是后端异步路由

  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasAsyncPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = formatAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

function getAsyncRouteNames(routes, roles, allName) {
  routes.forEach((route) => {
    const tmp = { ...route }
    allName.push(tmp.name)
    if (hasAsyncPermission(roles, tmp)) {
      if (tmp.children) {
        getAsyncRouteNames(tmp.children, roles, allName)
      }
    }
  })
}

const state = {
  routes: [],
  addRoutes: [],
  routeNames: [],
  sidebarRoutes:[]
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    state.sidebarRoutes = constantRoutes.concat(routes)
  },
  SET_ASYNC_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    state.sidebarRoutes = constantRoutes.concat(routes)
  },
  SET_ALL_ROUTE_NAMES: (state, names) => {
    state.routeNames = names
  },
  SET_SIDEBAR_ROUTES: (state, sidebarRoutes) => {
    state.sidebarRoutes = sidebarRoutes
  },
}

const actions = {
  // 前端用角色控制路由
  generateRoutes({ commit }, roles) {
    return new Promise((resolve) => {
      let accessedRoutes
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  generateAsyncRoutes({ commit }, routes) {
    return new Promise((resolve) => {
      let accessedRoutes = []
      accessedRoutes = formatAsyncRoutes(asyncRoutes, routes)
      commit('SET_ASYNC_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  generateAsyncRouteName({ commit }, routes) {
    return new Promise((resolve) => {
      const allRouteName = []
      const webRoutes = []
      webRoutes.push(...asyncRoutes)
      webRoutes.push(...constantRoutes)
      getAsyncRouteNames(webRoutes, routes, allRouteName)
      commit('SET_ALL_ROUTE_NAMES', allRouteName)
      resolve(allRouteName)
    })
  },
  removeRouteName({ commit }) {
    return new Promise((resolve) => {
      commit('SET_ALL_ROUTE_NAMES', [])
      resolve()
    })
  },
  changeSidebarRoutes({ commit }, sidebarRoutes) {
    commit('SET_SIDEBAR_ROUTES', sidebarRoutes)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
