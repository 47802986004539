import axios from "axios";
import { ElMessageBox as MessageBox, ElMessage as Message } from "element-plus";
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 180000, // request timeout
});

const hint = (msg, state) => {
  Message({
    message: msg,
    type: state || "error",
    duration: 5 * 1000,
  });
};
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 400:
      hint(other);
      break;
    case 401:
      hint(other);
      store.dispatch("user/resetToken");
      router.push({
        path: "/login",
      });
      break;
    case 403:
      hint("没有操作权限");
      break;
    case 404:
      hint("找不到资源");
      break;
    case 502:
      hint("网关错误");
      break;
    default:
      hint("服务器错误");
  }
};

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.formDate) {
      const formData = new FormData();
      config.headers["content-type"] = "application/x-www-form-urlencoded";
      if (config.params) {
        const params = config.params;
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        config.params = formData;
      } else if (config.data) {
        const datas = config.data;
        Object.keys(datas).forEach((key) => {
          formData.append(key, datas[key]);
        });
        config.data = formData;
      }
    }
    if (store.getters.token) {
      config.headers["authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 需要放出给页面自己处理的code
    const codeList = [0, 30004, 200, 5001, 200003];
    if (codeList.includes(res.code)) {
      return res;
    } else {
      if (res.code == 102001) {
        hint(res.message, "success");
        return;
      }
      // 需要全局处理的code
      hint(res.message);
      // 自定义code全局拦截示例
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401) {
        MessageBox.confirm("登录状态失效，请重新登录", "重新登录", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("user/resetToken");
        });
      }
      return Promise.reject(new Error(res.message || "Error"));
    }
  },
  (error) => {
    const { response } = error;
    if (response) {
      errorHandle(response.status, response.data.message);
      return Promise.reject(error.response);
    }
    console.log("err" + error); // for debug

    return Promise.reject(error);
  }
);

export default service;
