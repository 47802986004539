import * as Vue from 'vue'
import Layout from '@/layout'

const settingRouter = {
  path: '/sys',
  component: Layout,
  redirect: '/sys/setting/settingList',
  name: 'sys',
  meta: {
    title: '系统设置',
    icon: 'Tools',
  },
  children: [
    {
      path: '/sys/sys_goods_stock_import',
      component:(() => import('@/views/sys/setting/settingList/stockset')),
      name: 'sys_goods_stock_import',
      meta: { title: '库存干预', icon: 'EditPen' },
    },
    {
      path: '/sys/positions_list',
      component:(() => import('@/views/sys/setting/settingList/warehouselist')),
      name: 'positions',
      meta: { title: '仓位设置', icon: 'Discount' },
    },
       {
      path: '/sys/switch-settings',
      component: () => import('@/views/sys/setting/switch-settings'),
      name: 'SwitchSettings',
      meta: { title: '开关设置', icon: 'Tools' }
    },
    {
      path: '/sys/switch-settings/deliverList',
      component: () => import('@/views/sys/setting/switch-settings/deliverList'),
      meta: { title: '配送中心列表', icon: 'Tools' },
      name: 'SwitchSettingsDeliverList',
      hidden: true,
    },
     {
      path: '/sys/switch-settings/edit-company',
      component: () => import('@/views/sys/setting/switch-settings/edit-company'),
      meta: { title: '编辑分公司', icon: 'Tools' },
      name: 'SwitchSettingsEditCompany',
      hidden: true,
    },
        {
      path: '/sys/switch-settings/edit-deliver',
      component: () => import('@/views/sys/setting/switch-settings/edit-deliver'),
      meta: { title: '编辑配送中心', icon: 'Tools' },
      name: 'SwitchSettingsEditDeliver',
      hidden: true,
    },
    {
      path: '/sys/operationalTool/lableList',
      component:(() => import('@/views/operationalTools/lable/list')),
      name: 'lable_list',
      // redirect: '/operationalTool/lableList/manage',
      meta: {
        title: '标签管理',
        icon: 'ChatSquare',
        noCache: true,
      },
      children: [
        {
          path: '/operationalTool/lableList/manage',
          component: (() => import('@/views/operationalTools/lable/list')),
          props: true,
          meta: {
            title: '标签管理',
            icon: 'ChatSquare',
            keepAlive: false,
          },
          hidden: true,
        },
        {
          path: '/operationalTool/tag/save/:id',
          component: (() => import('@/views/operationalTools/tag/save')),
          props: true,
          meta: {
            title: '编辑标签',
            icon: 'el-icon-tickets',
            keepAlive: false,
          },
          hidden: true,
        },
        {
          path: '/operationalTool/tag/label-user',
          component: (() => import('@/views/operationalTools/lable/label-user')),
          props: true,
          meta: {
            title: '用户标签',
            icon: 'el-icon-tickets',
            keepAlive: false,
          },
          hidden: true,
        },
        {
          path: '/operationalTool/tag/import-user',
          component: (() => import('@/views/operationalTools/lable/import-user')),
          props: true,
          meta: {
            title: '导入用户',
            icon: 'el-icon-tickets',
            keepAlive: false,
          },
          hidden: true,
        },
      ],
    },
    {
      path: '/sys/inventory-management/deliverList',
      component: () => import('@/views/sys/setting/inventory-management/deliverList.vue'),
      name: 'InventoryManagementDeliverList',
      meta: { title: '库存管理(配送中心)', icon: 'Tools' }
    },
  ],
}

export default settingRouter
