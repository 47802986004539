<script>
import request from "@/api/request";

import EasySelectSearch from "@/components/easy-components/easy-select-search";

export default {
  name: 'NewOrgSelect',
  components: { EasySelectSearch },
  props: {
    companyCode: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    deliverCode: {
      type: String,
      default: "",
    },
    deliverName: {
      type: String,
      default: "",
    },
    storeId: {
      type: [String, Number],
      default: "",
    },
    showDeliver: {
      type: Boolean,
      default: true,
    },
    showCompany: {
      type: Boolean,
      default: true,
    },
    showStore: {
      type: Boolean,
      default: false,
    },
    authCheck: {
      type: Boolean,
      default: false,
    },
    hiddenCenter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showDeliverOne:{
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      companyData: [],
      deliverData: [],
      companyCodeValue: "",
      deliverCodeValue: "",
      storeIdValue: "",
      clearDeliverCodeValue: true,
      companyIsDisabled: false,
      deliverIsDisabled: false,
    }
  },
  watch: {
    companyCode() {
      if (this.companyCodeValue != this.companyCode) {
        this.clearDeliverCodeValue = false
        this.companyCodeValue = this.companyCode;
      }
    },
    deliverCode() {
        this.deliverCodeValue = this.deliverCode
    },
    storeId() {
      this.storeIdValue = this.storeId
    },
    companyCodeValue() {
      if (this.authCheck && this.$store.getters.companyCode != 0 && this.$store.getters.deliverCode != '') {
        this.companyCodeValue = this.$store.getters.companyCode
        this.deliverCodeValue = this.$store.getters.deliverCode

        this.companyIsDisabled = true
        if (this.deliverCodeValue != 0) {
          this.deliverIsDisabled = true
        }
      }

      if (!this.companyCodeValue) {
        this.deliverData = []
        this.deliverCodeValue = ""
        this.storeIdValue = ""
        this.$emit("update:companyName", "")
        this.$emit("update:deliverName", "")
        this.$emit("update:companyCode", this.companyCodeValue)
        return
      }
      if (this.clearDeliverCodeValue) {
        this.deliverCodeValue = ""
      }
      for (const item of this.companyData) {
        if (item.orgCode === this.companyCodeValue) {
          this.deliverData = item.childList
          break
        }
      }
      this.clearDeliverCodeValue = true
      this.$emit("update:companyCode", this.companyCodeValue)
      for (const item of this.companyData) {
        if (item.orgCode === this.companyCodeValue) {
          this.$emit("update:companyName", item.orgName);
          break
        }
      }
      if (this.authCheck && this.$store.getters.companyCode != 0 && this.$store.getters.deliverCode == '') {
        this.companyCodeValue = this.$store.getters.companyCode;
        // this.deliverCodeValue = this.deliverData[0].orgCode;

        if (this.deliverCode) {
          this.deliverCodeValue = this.deliverCode;
        } else if(this.showDeliverOne) {
          this.deliverCodeValue = this.deliverData[0].orgCode
        }else{
          this.deliverCodeValue = ''
        }

        // this.companyIsDisabled = true
        // if (this.deliverCodeValue != 0) {
        //   this.deliverIsDisabled = true
        // }
      } else {
        // this.companyCodeValue = this.$store.getters.companyCode;
        if (this.$store.getters.companyCode != 0) {
          this.companyCodeValue = this.$store.getters.companyCode;
        }
      }
    },
    deliverCodeValue() {
      this.$emit("update:deliverCode", this.deliverCodeValue)
      this.storeIdValue = ""
      for (const item of this.deliverData) {
        if (item.orgCode === this.deliverCodeValue) {
          this.$emit("update:deliverName", item.orgName);
        }
      }
    },
    storeIdValue() {
      this.$emit("update:storeId", this.storeIdValue)
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      request.get("/v4/admin/org/unLimit").then(data => {
        data.data.orgList.forEach(item => {
          item.$label = `${item.orgCode}-${item.orgName}`
          item.childList.forEach(item => {
            item.$label = `${item.orgCode}-${item.orgName}`
          })
        })
        if (this.$store.getters.companyCode == "0" && !this.hiddenCenter) {
          this.companyData = [{ childList: [], orgCode: "0", orgName: "总部", $label: "0-总部" }, ...data.data.orgList]
        } else {
          this.companyData = data.data.orgList
        }
        console.log(this.companyData)
        if (this.authCheck && this.$store.getters.companyCode != 0) {
          this.companyCodeValue = this.$store.getters.companyCode
          this.deliverCodeValue = this.$store.getters.deliverCode

          this.companyIsDisabled = true
          if (this.deliverCodeValue != 0) {
            this.deliverIsDisabled = true
          }
        } else {
          this.companyCodeValue = this.companyCode;
          this.deliverCodeValue = this.deliverCode;
        }
        this.clearDeliverCodeValue = false
      })
    }
  }
}
</script>

<template>
  <div>
    <el-select
      v-if="showCompany"
      v-model="companyCodeValue"
      placeholder="请选择"
      filterable
      :clearable="true"
      :disabled="companyIsDisabled || disabled"
      style="width: 140px;"
    >
      <el-option
        v-for="(item,i) in companyData"
        :key="i"
        :value="item.orgCode"
        :label="item.$label"
      />
    </el-select>
    <el-select
      v-if="showDeliver"
      v-model="deliverCodeValue"
      filterable
      style="margin-left: 10px; width: 200px;"
      placeholder="配送中心"
      :clearable="true"
      :disabled="deliverIsDisabled || deliverData.length == 0 || disabled"
    >
      <el-option
        v-for="(item,i) in deliverData"
        :key="i"
        :value="item.orgCode"
        :label="item.$label"
      />
    </el-select>
    <easy-select-search
      v-if="showStore"
      v-model="storeIdValue"
      :disabled="!companyCode || !deliverCode || disabled"
      style="margin-left: 10px; width: 230px;"
      placeholder="店铺"
      url="/dreport/v4/admin/store/mini/list"
      :params="{
        companyCode: companyCodeValue,
        deliverCode: deliverCodeValue,
      }"
      search-key="keyword"
      label="storeName"
      value="storeId"
      :format-item="(item)=>{item.label = item.$item.shopCode+'-'+item.$item.storeName}"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
