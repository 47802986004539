<template>
  <div style="margin-top: 20px">
    <div style="overflow: auto">
      <slot name="search" :params="searchParams" />
      <mg-label
        width="500px"
        style="
          float: right;
          text-align: right;
          padding-right: 10px;
          overflow: hidden;
        "
      >
        <el-button type="primary" icon="Search" @click="search">
          查询
        </el-button>
        <slot name="button" />
      </mg-label>
    </div>
    <el-table :data="data" style="margin-top: 20px" border>
      <slot :getData="getData" />
    </el-table>
    <el-pagination
      background
      style="margin-top: 10px; text-align: center"
      :current-page="page.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="pageSizeChange"
      @current-change="pageNoChange"
    />
  </div>
</template>

<script>
import { Search as ElIconSearch } from '@element-plus/icons-vue'
import request from '@/api/request'

export default {
  data() {
    return {
      data: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      searchParams: {},
      total: 0,
      ElIconSearch,
    }
  },
  name: 'MgTable',
  props: ['url', 'params', 'method', 'afterFormatData', 'formatData'],
  created() {
    this.searchParams = { ...this.params }
    this.getData()
  },
  methods: {
    search() {
      this.page.pageNo = 1
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.page.pageNo = 1
      this.getData()
    },
    pageNoChange(pageNo) {
      this.page.pageNo = pageNo
      this.getData()
    },
    getData() {
      const method = this.method ? this.method : 'get'
      const config = {
        url: this.url,
        method: method,
      }

      let data = {
        ...this.page,
        ...(this.searchParams ? this.searchParams : {}),
      }
      if (this.formatData) {
        data = this.formatData(data)
      }
      if (method == 'get') {
        config.params = data
      } else if (method == 'post') {
        config.data = this.$toFormData(data)
      }

      request(config).then((data) => {
        this.data = this.afterFormatData
          ? this.afterFormatData(data.data.data)
          : data.data.data
        this.total = data.data.total
        if (this.data.length == 0 && this.page.pageNo != 1) {
          this.page.pageNo = 1
          this.getData()
        }
      })
    },
  },
}
</script>
