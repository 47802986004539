import Layout from "@/layout";

const goodsRouter = {
  path: "/goods",
  component: Layout,
  name: "item",
  redirect: "/goods/item_sub",
  meta: { title: "商品管理", icon: "Grid" },
  children: [
    // {
    //   path: '/goods/item_sub',
    //   component: () => import('@/views/goods/goods/index'),
    //   name: 'item_sub',
    //   redirect: '/goods/item_sub/goodsList',
    //   meta: { title: '商品列表', icon: 'clipboard' },
    //   children: [
    //     {
    //       path: '/goods/item_sub/goodsList',
    //       component: () => import('@/views/goods/goods/goodsList/list'),
    //       name: 'item_sub',
    //       meta: { title: '活动列表', icon: 'clipboard', keepAlive: false }
    //     },
    //     {
    //       path: '/goods/item_sub/item_create',
    //       component: () => import('@/views/goods/goods/goodsList/create'),
    //       name: 'item_create',
    //       meta: { title: '新建活动', keepAlive: false },
    //       hidden: true
    //     },
    //   ]
    // },
    // 价格管理

    {
      path: "/goods/new-goods-manage",
      component: () => import("@/views/goods/goods-list/index.vue"),
      name: "NewGoodsManage",
      meta: { title: "商品列表", icon: "Notebook" },
    },
    {
      path: "/goods/zb-item-strategy",
      component: () => import("@/views/goods/zb-item-strategy"),
      name: "ZbItemStrategy",
      meta: { title: "商品策略(总部)", icon: "Notebook" },
    },
    {
      path: "/goods/zb-item-strategy-stream",
      component: () =>
        import("@/views/goods/zb-item-strategy/zb-item-strategy-stream"),
      name: "ZbItemStrategyStream",
      meta: { title: "商品策略流水(总部)", icon: "SetUp" },
    },
    {
      path: "/goods/product-strategy-fulfillment",
      component: () =>
        import("@/views/goods/product-strategy/product-strategy-fulfillment"),
      name: "ProductStrategyFulfillment",
      meta: { title: "商品策略(配送中心)", icon: "Notebook" },
    },
    {
      path: "/goods/merchandise-strategy-flow",
      component: () =>
        import("@/views/goods/product-strategy/merchandise-strategy-flow"),
      name: "MerchandiseStrategyFlow",
      meta: { title: "商品策略流水(配送中心)", icon: "SetUp" },
    },
    {
      path: "/goods/sku-price-manage",
      component: () => import("@/views/goods/sku/sku-price/manage"),
      name: "ItemSkuPriceManage",
      meta: { title: "价格管理", icon: "Watermelon" },
    },
    {
      path: "/goods/sku-price-import",
      component: () => import("@/views/goods/sku/sku-price/import"),
      name: "ItemSkuPriceImport",
      meta: { title: "SKU价格导入", icon: "clipboard" },
      hidden: true,
    },

    // 起订量管理
    {
      path: "/goods/moq-manage",
      component: () => import("@/views/goods/moq/moq/manage"),
      name: "ItemMoqList",
      meta: { title: "起订量管理", icon: "List" },
    },
    {
      path: "/goods/moq-save",
      component: () => import("@/views/goods/moq/moq/save"),
      props: true,
      name: "AddOrEditItemMoq",
      meta: { title: "商品起订量", icon: "clipboard", noCache: true },
      hidden: true,
    },
    {
      path: "/goods/pool-manage",
      component: () => import("@/views/goods/moq/pool/manage"),
      name: "ItemMoqManage",
      props: true,
      hidden: true,
      meta: { title: "产品池管理", icon: "el-icon-share", noCache: true },
    },
    {
      path: "/goods/pool-import",
      component: () => import("@/views/goods/moq/pool/import"),
      name: "ItemMoqImport",
      props: true,
      hidden: true,
      meta: { title: "导入产品", icon: "el-icon-share" },
    },
    // 可订货管理
    {
      path: "/goods/store-tag-good",
      component: () => import("@/views/goods/store-tag-good-pool/good/manage"),
      name: "StoreTagGoodList",
      meta: { title: "可订货管理", icon: "Checked" },
    },
    {
      path: "/goods/store-tag-good-save/:id",
      component: () => import("@/views/goods/store-tag-good-pool/good/save"),
      name: "AddOrEditStoreTagGood",
      props: true,
      hidden: true,
      meta: {
        title: "新建|编辑终端组商品池",
        icon: "el-icon-share",
        noCache: true,
      },
    },
    {
      path: "/goods/store-tag-good-pool-manage/:id/:name/:status",
      component: () => import("@/views/goods/store-tag-good-pool/pool/manage"),
      name: "StoreTagGoodManage",
      props: true,
      hidden: true,
      meta: { title: "产品池管理", icon: "el-icon-share", noCache: true },
    },
    {
      path: "/goods/store-tag-good-pool-import/:id/:name",
      component: () => import("@/views/goods/store-tag-good-pool/pool/import"),
      name: "StoreTagGoodImport",
      props: true,
      hidden: true,
      meta: { title: "导入产品", icon: "el-icon-share" },
    },
    // 商品包
    {
      path: "/goods/group_sku_setting",
      component: () => import("@/views/goods/group/sku/import"),
      name: "group_sku_setting",
      redirect: "/goods/group_sku_import",
      meta: { title: "商品包设置", icon: "FolderOpened" },
      hidden: true,
      children: [
        {
          path: "/goods/group_sku_import",
          component: () => import("@/views/goods/group/sku/import"),
          name: "group_sku_import",
          props: true,
          hidden: true,
          meta: {
            title: "导入商品包",
            icon: "el-icon-share",
            keepAlive: false,
          },
        },
      ],
    },
    {
      path: "/goods/group_sku_list",
      component: () => import("@/views/goods/group/sku/list"),
      name: "group_sku_list",
      // redirect: '/goods/group_sku_add',
      meta: { title: "商品包设置", icon: "DocumentCopy" },
      children: [
        {
          path: "/goods/group_sku_add",
          component: () => import("@/views/goods/group/sku/list"),
          name: "group_sku_add",
          props: true,
          hidden: true,
          meta: {
            title: "商品包设置",
            icon: "el-icon-share",
            keepAlive: false,
          },
        },
      ],
    },

    {
      path: "/goods/upload-product-package",
      component: () =>
        import("@/views/goods/group/sku/upload-product-package.vue"),
      hidden: true,
      meta: { title: "上传商品包图片", keepAlive: false },
    },

    // 搭配商品
    {
      path: "/goods/pairs-goods",
      component: () => import("@/views/goods/pair-goods/index"),
      name: "PairGoods",
      meta: { title: "搭配商品", icon: "EditPen" },
    },

    {
      path: "/goods/pairs-goods-manage",
      component: () => import("@/views/goods/pair-goods/manage"),
      name: "PairGoodsManage",
      hidden: true,
      meta: {
        title: "商品管理",
        icon: "el-icon-share",
        keepAlive: false,
        noCache: true,
      },
    },
    {
      path: "/goods/product-label",
      component: () => import("@/views/goods/product-label"),
      name: "ProductLabel",
      meta: { title: "商品标签", icon: "DocumentCopy" },
    },
       {
      path: '/goods/import-product',
      component: (() => import('@/views/goods/import-product')),
      name: 'ImportProduct',
      meta: { title: '导入商品', icon: 'EditPen' },
    },
      {
      path: '/goods/import-multilingual-images',
      component: (() => import('@/views/goods/import-multilingual-images')),
      name: 'ImportMultilingualImages',
      meta: { title: '导入多语言图片', icon: 'EditPen' },
    },
  ],
};

export default goodsRouter;
