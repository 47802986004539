<template>
  <el-upload ref="upload-file" style="width: 362px;" :drag="drag == undefined ? true : drag" action="" multiple
    :auto-upload="false" :on-change="onChange" :accept="accept" :on-remove="onRemove">
    <template v-if="drag == undefined ? true : drag">
      <el-icon style="font-size:40px">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </template>
    <template v-else>
      <el-button type="primary"> 上传文件 </el-button>
    </template>
    <template v-slot:tip>
      <div class="el-upload__tip">
        <el-link :underline="false" v-if="templateUrl" :href="templateUrl" type="primary" target="_blank" style="margin-top: -4px">{{
          templateText ? templateText : '下载文件导入模板' }}</el-link>
      </div>
      <div class="el-upload__tip  text" v-html="tip">

      </div>
    </template>

  </el-upload>
</template>

<script>

import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  components: {
  },
  name: 'EasyFileSelect',
  props: ['file', 'accept', 'drag', 'tip', 'templateUrl', 'templateText'],
  data() {
    return {}
  },
  created() { },
  methods: {
    onChange(file) {

      this.$refs['upload-file'].uploadFiles = [file]
      $emit(this, 'update:file', file.raw)
    },
    onRemove() {
      $emit(this, 'update:file', null)
    },
  },
  emits: ['update:file'],
}
</script>

<style lang="scss" scoped>
#easy-file-select {}
</style>
