<template>
  <div id="mg-label">
    <div id="mg-label-text" :style="labelWidth ? { width: labelWidth } : {}">
      {{ label }}
    </div>
    <div id="mg-label-form" :style="width ? { width: width } : {}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MgLabel',
  props: ['label', 'width', 'labelWidth'],
}
</script>

<style scoped>
#mg-label {
  display: inline-block;
  border-radius: 3px;
  height: 36px;
  margin-top: 10px;
  margin-right: 20px;
}
#mg-label-text {
  font-size: 14px;
  float: left;
  text-align: right;
  line-height: 36px;
  box-sizing: border-box;
  height: 36px;
  padding: 0 10px;
  color: #606266;
}
#mg-label-form {
  float: left;
  max-width: 500px;
}
</style>
