import Layout from "@/layout";

const commentFeedback = {
  path: "/comment-feedback",
  component: Layout,
  name: "CommentFeedback",
  meta: { title: "评论反馈", icon: "ChatDotRound" },
  children: [
    {
      path: "/comment-feedback/Retroaction",
      component: () => import("@/views/comment-feedback/retroaction/index"),
      name: "Retroaction",
      meta: { title: "意见反馈", icon: "ChatDotRound" },
      // hidden: true
    },
      {
      path: "/comment-feedback/entry-information",
      component: () => import("@/views/comment-feedback/entry-information/index"),
      name: "EntryInformation",
      meta: { title: "入驻资料", icon: "ChatDotRound" },
      // hidden: true
    },

    {
      path: "/comment-feedback/video-comment",
      component: () => import("@/views/home-page/audioVisual-vue/video-comment"),
      name: "VideoComment",
      meta: { title: '评论列表', icon: 'ChatDotSquare' }
    },
  ]
};

export default commentFeedback;
