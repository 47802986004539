import * as Vue from 'vue'
import store from '@/store'
import { isString, isArray } from '@/utils/validate'
import settings from '@/settings'

// you can set in settings.js
// errorLog:'production' | ['production', 'development']
const { errorLog: needErrorLog } = settings

function checkNeed() {
  const env = import.meta.env.VITE_ENV
  if (isString(needErrorLog)) {
    return env === needErrorLog
  }
  if (isArray(needErrorLog)) {
    return needErrorLog.includes(env)
  }
  return false
}

export default function() {
  if (checkNeed()) {
    window.$vueApp.config.errorHandler = function (err, vm, info, a) {
      // Don't ask me why I use Vue.nextTick, it just a hack.
      // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
      Vue.nextTick(() => {
        store.dispatch('errorLog/addErrorLog', {
          err,
          vm,
          info,
          url: window.location.href,
        })
        console.error(err, info)
   
    })
    }
  }
}


// if (checkNeed()) {
//   window.$vueApp.config.errorHandler = function (err, vm, info, a) {
//     // Don't ask me why I use Vue.nextTick, it just a hack.
//     // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
//     Vue.nextTick(() => {
//       store.dispatch('errorLog/addErrorLog', {
//         err,
//         vm,
//         info,
//         url: window.location.href,
//       })
//       console.error(err, info)
//     })
//   }
// }
