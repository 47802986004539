<template>
  <div>
    <div
      id="easy-image-select-pro"
      @click="selectFile()"
      :style="'width:' + thumbWidth + 'px; height:' + thumbHeight + 'px;'"
    >
      <img
        ref="img"
        :src="imgUrl"
        :alt="imgUrl"
        style="display: block"
        :width="thumbWidth"
        :height="thumbHeight"
        v-show="!showSlot"
      />
      <input
        ref="file"
        type="file"
        accept="image/*"
        style="display: none"
        @change="fileChange()"
      />
      <slot v-if="showSlot" />
    </div>
    <div>
      {{ tip }}
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  name: 'EasyImageSelectPro',
  props: ['file', 'imgUrl', 'tip', 'width', 'height', 'maxSize', 'scale'],
  data() {
    return {
      showSlot: true,
      thumbWidth: this.width,
      thumbHeight: this.height,
    }
  },
  watch: {
    imgUrl() {
      if (this.imgUrl) {
        this.showSlot = false
      } else {
        this.showSlot = true
      }
    },
  },
  created() {
    if (this.imgUrl) {
      this.showSlot = false
    } else {
      this.showSlot = true
    }
    this.thumbWidth = this.width * this.scale
    this.thumbHeight = this.height * this.scale
  },
  methods: {
    selectFile() {
      this.$refs['file'].click()
    },
    async fileChange() {
      const file = this.$refs['file'].files[0]
      this.$refs['file'].value = ''
      if (!file) {
        return
      }
      console.log('file size:', file.size)
      console.log('max size: ', this.maxSize)
      if (this.getByte(file.size) > this.getByte(this.maxSize)) {
        // 文件大小超限
        console.log('文件大小超限')
        this.$message({
          type: 'error',
          message: '文件大小超限',
        })
        return
      }

      let success = await this.checkPicSize(file, this.width, this.height)
      console.log('校验图片宽高结果:', success)

      if (!success) {
        // 图片宽高不匹配
        console.log('图片宽高不匹配', this.width, this.height)
        this.$message({
          type: 'error',
          message: '图片宽高不匹配(' + this.width + 'x' + this.height + ')',
        })
        return
      }

      this.$refs['img'].src = window.URL.createObjectURL(file)
      $emit(this, 'update:file', file)
      setTimeout(() => {
        this.showSlot = false
      }, 5)
    },
    /**
     * 获取大小(单位:byte)
     */
    getByte(size) {
      console.log('input:', size)
      let newSize = 5 * 1024 * 1024
      if (!size) {
        // 默认5M
        return newSize
      }
      let str = size
      if (typeof size != 'string') {
        str = size.toString()
      }

      if (str.endsWith('M') || str.endsWith('m')) {
        // M
        newSize = parseInt(str.substr(0, str.length - 1)) * 1024 * 1024
      } else if (str.endsWith('K') || str.endsWith('k')) {
        // K
        newSize = parseInt(str.substr(0, str.length - 1)) * 1024
      } else {
        newSize = parseInt(str)
      }
      console.log('output:', newSize)
      return newSize
    },
    /**
     * 校验图片文件宽高
     * @param {*} img
     * @param {*} width
     * @param {*} height
     */
    async checkPicSize(file, width, height) {
      return new Promise((resolve) => {
        let reader = new FileReader()
        reader.onload = (function (f) {
          return function (e) {
            let img = new Image()
            img.src = e.target.result
            img.onload = function () {
              console.log(img.width)
              console.log(img.height)
              if (img.width === Number(width) && img.height == Number(height)) {
                resolve(true)
              } else {
                resolve(false)
              }
            }
          }
        })(file)
        reader.readAsDataURL(file)
      })
    },
  },
  emits: ['update:file'],
}
</script>

<style lang="scss" scoped>
#easy-image-select-pro {
  border: 2px solid #dcdfe6;
  min-height: 100px;
  overflow: hidden;
  cursor: pointer;
  color: #dcdfe6;
  border-radius: 5px;
  font-size: 4px;
  text-align: center;
  &:hover {
    border-color: #409eff;
    color: #409eff;
  }
}
</style>
