import * as Vue from 'vue'
import Layout from '@/layout'

const conferenceMarketingRouter = {
  path: '/conferenceMarketing',
  component: Layout,
  name: 'Marketing',
  redirect: '/operationalTool/LiveListPage',
  meta: { title: '会议营销', icon: 'VideoCameraFilled' },
  children: [
    {
      path: '/conferenceMarketing/LiveListPage',
      component:(() => import('@/views/operationalTools/live-list/list')),
      name: 'LiveListPage',
      meta: { title: '直播列表', icon: 'VideoCameraFilled' },
    },
    {
      path: '/conferenceMarketing/LiveListPageDetail',
      component:(() => import('@/views/operationalTools/live-list/detail')),
      name: 'LiveListPageDetail',
      meta: { title: '直播间', icon: 'el-icon-s-order', noCache: true },
      hidden: true,
    },
    {
      path: '/conferenceMarketing/AddLivePage',
      component:(() => import('@/views/operationalTools/live-list/add')),
      name: 'AddLivePage',
      meta: {
        title: '内容页',
        icon: 'el-icon-s-order',
        keepAlive: false,
        noCache: true,
      },
      hidden: true,
    },
    {
      path: '/conferenceMarketing/statistical-management',
      component: (() => import('@/views/operationalTools/statistical-management')),
      name: 'StatisticalManagement',
      meta: { title: '订单统计条件管理', icon: 'VideoCameraFilled' },
    },
  ],
}

export default conferenceMarketingRouter
