import * as Vue from 'vue';
import { createVNode, render } from 'vue'
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import Element from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/es/locale/lang/zh-cn'; //中文
import '@/styles/index.scss'; // global css
import VueUeditorWrap from 'vue-ueditor-wrap';
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import App from './App';
import router from './router';
import store from './store';
import 'virtual:svg-icons-register';
// import installIcon from '@/icons'; // icon
import './permission'; // permission control
import installErrorLog from './utils/error-log'; // error log
// import './utils/error-log' // error log
import staticData from '@/tool/staticData';
import MgPlugin from '@/utils/mg-plugin';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import IconSelect from './components/easy-components/icon-select.vue';
import ComponentConfig from './config/ComponentConfig.js';
import PrototypeConfig from './config/PrototypeConfig';
import * as filters from './utils/filters';
import {createPinia} from "pinia"; // global filters
const app = Vue.createApp(App)
window.$vueApp = Vue.createApp(App)
installErrorLog(app)
// console.log(import.meta.env.VITE_ENV)
console.log(import.meta.env)
// process.env
if (import.meta.env.VITE_ENV == 'prod') {
  Sentry.init({
    app,
    dsn: "https://1c2ac41050@newsentry.lifemg.cn/6",
    integrations: [new Integrations.BrowserTracing()],
  })
}

ComponentConfig.createComponent(app)
PrototypeConfig.createPrototype(app)
app.use(MgPlugin)
app.use(VueUeditorWrap)

app.use(Element, {
  size: Cookies.get('size') || 'default', locale// set element-ui default size
})
app.component("IconSelect", IconSelect)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
Object.keys(filters).forEach((key) => {
  (
    app.config.globalProperties.$filters ||
    (app.config.globalProperties.$filters = {})
  )[key] = filters[key]
})
app.config.globalProperties.GLOBAL = staticData
app.directive('css', function (el, binding) {
  // el.style[binding.arg] = Object.keys(binding.modifiers).join(' ')
  const modifiers = Object.keys(binding.modifiers);
  if (!binding.arg){
    return;
  }

  if (modifiers.length != 0){
    if (binding.value == undefined || binding.value == null || binding.value == true){
      el.setAttribute(binding.arg, el.style[binding.arg] || "");
      el.style[binding.arg] = modifiers.join(" ");
    } else {
      el.style[binding.arg] = el.getAttribute(binding.arg) || "";
    }
  } else {
    el.style[binding.arg] = binding.value;
  }
})
app.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
// 屏蔽警告信息
app.config.warnHandler = () => null;
app.use(store)
app.use(createPinia())
app.use(router)
app.mount('#app')

// 全局挂载mg-iframe
window.mgIframe = async function (url, title) {
  const mgIframeVueSource = (await import('@/components/mg-component/mg-iframe.vue')).default
  const div = document.createElement('div')
  Vue.createApp(mgIframeVueSource, { url, title }).mount(div)
  document.body.appendChild(div)
}

// YYYY-MM-DD
Date.prototype.toYYYYMMDD = function () {
    const year = this.getFullYear();
    const month = this.getMonth() + 1;
    const date = this.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
        date < 10 ? "0" + date : date
    }`;
};

// YYYY-MM-DD 00:00:00
Date.prototype.toYYYYMMDD000000 = function () {
    const year = this.getFullYear();
    const month = this.getMonth() + 1;
    const date = this.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
        date < 10 ? "0" + date : date
    } 00:00:00`;
};

// YYYY-MM-DD 23:59:59
Date.prototype.toYYYYMMDD235959 = function () {
    const year = this.getFullYear();
    const month = this.getMonth() + 1;
    const date = this.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
        date < 10 ? "0" + date : date
    } 23:59:59`;
};

// YYYY-MM-DD HH:MM:SS
Date.prototype.toYYYYMMDDHHMMSS = function () {
    const year = this.getFullYear();
    const month = this.getMonth() + 1;
    const date = this.getDate();
    const hour = this.getHours();
    const minute = this.getMinutes();
    const second = this.getSeconds();
    return `${year}-${month < 10 ? "0" + month : month}-${
        date < 10 ? "0" + date : date
    } ${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}:${
        second < 10 ? "0" + second : second
    }`;
};

// HH:MM:SS
Date.prototype.toHHMMSS = function () {
    const hour = this.getHours();
    const minute = this.getMinutes();
    const second = this.getSeconds();
    return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}:${
        second < 10 ? "0" + second : second
    }`;
};

// HH:MM
Date.prototype.toHHMM = function () {
    const hour = this.getHours();
    const minute = this.getMinutes();
    return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute}`;
};


