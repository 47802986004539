import { ElMessageBox } from 'element-plus';
 
let lastScripts = [];
 
/* 获取html中script标签的src属性值 */
async function extractNewScripts(html) {
	const scriptReg = /<script.*src=["'](?<src>[^"']+)/gm;
	const result = [];
	let match;
	while ((match = scriptReg.exec(html))) {
		result.push(match.groups?.src ?? '');
	}
	return result;
}
 
/* 判断浏览器是否需要更新数据 */
async function needUpdate() {
	const newScripts = await extractNewScripts(await fetch(window.location.origin + window.location.pathname).then((resp) => resp.text()));
	if (!lastScripts.length) {
		lastScripts = newScripts;
		return false;
	}
	
	if (newScripts.length !== lastScripts.length) {
		lastScripts = newScripts;
		return true;
	}

	for (let i = 0; i < lastScripts.length; i++) {
		if (lastScripts[i] !== newScripts[i]) {
			lastScripts = newScripts;
			return true;
		}
	}
	return false;
}
 
/* 延时时间20s */
const DURATION = 20 * 1000;
 
/* 自动刷新 */
export const autoRefresh = () => {
	console.log('autoRefresh');
	setTimeout(async () => {
		const willUpdate = await needUpdate();
		if (willUpdate) {
			ElMessageBox.confirm('页面有更新，点击确定刷新页面?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					location.reload();
				})
				.catch(() => {});
		} else {
			autoRefresh(); // 如果不需要更新数据，继续执行下一次判断
		}
	}, DURATION);
};