<!--  -->
<template>
  <div class="">
    <el-select v-model="deliveryCodeValue" :style="`width:${width}`" :disabled="disabled" clearable filterable placeholder="配送中心"
      @change="changDeliveryCode()">
      <el-option v-for="deliver in deliverData" :key="deliver.orgCode" :label="deliver.orgName + '-' + deliver.orgCode"
        :value="deliver.orgCode">{{ deliver.orgName }}-{{ deliver.orgCode }}</el-option>
    </el-select>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    deliverData: {
      type: Array,
      default: function () {
        return []
      }
    },
    width: {
      type: String,
      default: ""
    },
    deliverCode: {
      type: String,
      default: ""
    },
    disabledTemp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 这里存放数据
    return {
      deliveryList: [],
      disabled: false,
      deliveryCodeValue: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    deliverData() {
      if (!this.disabledTemp ) {
        this.disabled = false;
        this.deliveryCodeValue = "";
        this.distribution();
      }
    },
    deliverCode() {
      if (!this.disabledTemp || this.$store.getters.companyCode==0) {
        this.deliveryCodeValue = this.deliverCode
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.disabledTemp && this.$store.getters.deliverCode != '') {
      this.$nextTick().then((res) => {
        this.deliveryCodeValue = this.$store.getters.deliverCode;
        this.disabled = true;
        this.changDeliveryCode()
      });
    }
    this.$nextTick().then((res) => {
      this.distribution();
    })

  },
  // 方法集合
  methods: {
    distribution() {
      if (!this.disabledTemp && this.deliverData == '') {
        this.disabled = true;
        return
      }
    },
    changDeliveryCode() {
      this.$emit("selectDistribution", this.deliveryCodeValue);
    },
  }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='' scoped>
/* @import url(); 引入公共css类 */
</style>
