import * as Vue from 'vue'
import Layout from '@/layout'

const templateRouter = {
  path: '/templateRouter',
  component: Layout,
  name: 'template',
  redirect: '/template/index',
  meta: { title: '消息管理', icon: 'ChatDotRound' },
  children: [
    {
      path: '/addMsgChannel',
      component:(() => import('@/views/msgChannel/create')),
      name: 'AddMsgChannel',
      meta: { title: '新增通道', icon: 'el-icon-circle-plus' },
      hidden: true,
    },
    {
      path: '/detailMsgChannel',
      component:(() => import('@/views/msgChannel/edit')),
      meta: { title: '通道详情', icon: 'el-icon-edit', noCache: true },
      hidden: true,
    },
    {
      path: '/templateRouter/listMsgChannel',
      component:(() => import('@/views/msgChannel/list')),
      name: 'listMsgChannel',
      meta: { title: '通道列表', icon: 'Operation' },
    },
    {
      path: '/templateCreate',
      component:(() => import('@/views/template/createAndEdit/create')),
      name: 'TemplateCreate',
      hidden: true,
      meta: { title: '新增消息', icon: 'el-icon-folder-add', keepAlive: false },
    },
    {
      path: '/templateEdit',
      component:(() => import('@/views/template/createAndEdit/edit')),
      name: 'templateEdit',
      hidden: true,
      meta: {
        title: '编辑消息',
        icon: 'el-icon-edit-outline',
        keepAlive: false,
      },
    },
    {
      path: '/templateRouter/templateList',
      component:(() => import('@/views/template/list/list')),
      name: 'TemplateList',
      meta: { title: '消息列表', icon: 'ChatDotRound', keepAlive: false },
    },
    {
      path: '/addressAndRemind',
      component:(() => import('@/views/template/addressAddRemind')),
      name: 'AddressAndRemind',
      hidden: true,
      meta: { title: '消息列表', icon: 'ChatDotRound', keepAlive: false },
    },
    {
      path: '/templateList/product-pool',
      component:(() => import('@/views/template/product-pool')),
      name: 'TemplateProductPoolList',
      hidden: true,
      meta: { title: '产品池列表', icon: 'el-icon-monitor', keepAlive: false },
    },
    {
      path: '/templateList/product-pool-import',
      component:(() => import('@/views/template/product-pool/import-good')),
      name: 'TemplateGoodImport',
      hidden: true,
      meta: { title: '产品池导入', icon: 'el-icon-monitor', keepAlive: false },
    },
    {
      path: '/templateRouter/infoLog',
      component:(() => import('@/views/template/info-log/info-log')),
      name: 'infoLog',
      meta: { title: '消息日志', icon: 'ChatLineSquare', keepAlive: false },
    },
    {
      path: '/editMsgChannel',
      component:(() => import('@/views/msgChannel/create')),
      name: 'EditMsgChannel',
      meta: { title: '编辑通道', icon: 'el-icon-edit', noCache: true },
      hidden: true,
    },
  ],
}

export default templateRouter
