<template>
  <el-select
    v-model="selectValue"
    style="width: 100%"
    :multiple="multiple ? multiple : false"
    filterable
    remote
    reserve-keyword
    :placeholder="placeholder ? placeholder : '输入关键字以搜索'"
    :remote-method="remoteMethod"
    :loading="loading"
    :clearable="clearable ? clearable : false"
    :disabled="disabled"
    @visible-change="visibleChange"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'

export default {
  name: 'EasySelectSearch',
  // model: {
  //   prop: 'modelValue',
  //   event: 'modelValueChange',
  // },
  props: [
    'url',
    'modelValue',
    'multiple',
    'selectData',
    'params',
    'searchKey',
    'label',
    'value',
    'placeholder',
    'clearable',
    'checkedNull',
    'formatItem',
    'disabled',
  ],
  data() {
    return {
      options: [],
      selectValue: null,
      data: [],
      loading: false,
      modelSwitch: false,
    }
  },
  watch: {
    selectValue() {
      // if (this.multiple && this.cutModelSwitch()) {
      //   return
      // }
      if (this.loading) {
        return
      }
      if (JSON.stringify(this.selectValue) == JSON.stringify(this.modelValue)) {
        return
      }
      this.$emit( 'update:modelValue', this.selectValue)
      $emit(this, 'change', this.selectValue)
    },
    modelValue() {
      // if (this.multiple && this.cutModelSwitch()) {
      //   return
      // }
      if (this.loading) {
        return
      }
      if (JSON.stringify(this.selectValue) == JSON.stringify(this.modelValue)) {
        return
      }
      this.selectValue = this.modelValue
    },
  },
  created() {},
  mounted() {
    if (this.url) {
      this.getData('', () => {
        this.selectValue = this.modelValue
      })
    }
  },
  methods: {
    visibleChange(isDown) {
      if (isDown) {
        this.getData('')
      }
    },
    cutModelSwitch() {
      this.modelSwitch = !this.modelSwitch
      return this.modelSwitch
    },
    remoteMethod(query) {
      this.getData(query)
    },
    getData(query, fn) {
      if (this.checkedNull && !query) {
        return
      }
      this.loading = true
      request({
        url: this.url,
        method: 'get',
        params: {
          pageNo: 1,
          pageSize: 100000,
          ...((searchKey, query) => {
            const temp = {}
            if (searchKey) {
              temp[searchKey] = query || ''
            } else {
              temp['name'] = query || ''
            }
            return temp
          })(this.searchKey, query),
          ...this.params,
        },
      })
        .then((data) => {
          if (data.data.list && data.data.list.length > 0) {
            this.data = data.data.list
          } else if (data.data.data && data.data.data.length > 0) {
            this.data = data.data.data
          } else if (data.data.items && data.data.items.length > 0) {
            this.data = data.data.items
          } else {
            this.data = []
          }
          this.options = this.data.map((item) => {
            if (typeof item === 'object') {
              const data = {
                $item: item,
                value: this.value ? item[this.value] : item.id,
                label: this.label ? item[this.label] : item.name,
              }
              if (this.formatItem) {
                this.formatItem(data)
              }
              return data
            } else {
              return { value: item, label: item }
            }
          })
        })
        .finally(() => {
          if (fn) {
            fn()
          }
          this.loading = false
        })
    },
  },
  emits: ['modelValueChange', 'change'],
}
</script>

<style lang="scss" scoped>
#select-search {
}
.el-select__tags {
  .el-select__input {
    width: 50px !important;
  }
}
</style>
