import * as Vue from 'vue'
import Layout from '@/layout'

const tackoutPlatform = {
  path: '/tackout-platform',
  component: Layout,
  name: 'TackoutPlatform',
  meta: { title: '外卖平台入驻', icon: 'Coin' },
  children: [
    {
      path: '/tackout-platform/merchant',
      component: (() => import('@/views/takeout-platform/merchant/manage.vue')),
      name: 'TakeoutPlatformMerchant',
      meta: { title: '外卖平台入驻商家', icon: 'Odometer' },
    },
    {
      path: '/tackout-platform/item',
      component: (() => import('@/views/takeout-platform/item/manage.vue')),
      name: 'TakeoutPlatformItem',
      meta: { title: '总部商品库', icon: 'Odometer' },
    },
    {
      path: '/tackout-platform/area-item',
      component: (() => import('@/views/takeout-platform/area-item/manage.vue')),
      name: 'TakeoutPlatformAreaItem',
      meta: { title: '区域商品清单', icon: 'Odometer' },
    },
  ],
}

export default tackoutPlatform
