import * as Vue from 'vue'
import Layout from '@/layout'

const homePage = {
  path: '/home',
  component: Layout,
  redirect: '/home-page/video-vue/list',
  name: 'newhome',
  meta: {
    title: '展示管理',
    icon: 'Monitor',
  },
  children: [
    {
      path: '/home/ShowActivity',
      component:(() => import('@/views/home-page/home-vue/list')),
      name: 'HomeActive',
      meta: { title: '首页管理', icon: 'Film' },
    },
    {
      path: '/home/dialog-manage',
      component:(() => import('@/views/home-page/dialog/index')),
      name: 'DialogManage',
      meta: { title: '弹窗管理', icon: 'Discount' },
    },
    {
      path: '/home/EditShowActivity',
      component:(() => import('@/views/home-page/home-vue/edit')),
      name: 'EdithomeActive',
      meta: {
        title: '模块管理新建|编辑',
        noCache: true,
        icon: 'el-icon-milk-tea',
      },
      hidden: true,
    },
    {
      path: '/home/PlateActivity',
      component:(() => import('@/views/home-page/plate-vue/list')),
      name: 'vue_set_module',
      meta: { title: '首页板块配置', icon: 'Platform' },
    },
    {
      path: '/home/VenueActivity',
      component:(() => import('@/views/home-page/venue-vue/list-back')),
      name: 'VenueActivityConfig',
      meta: { title: '落地页管理', icon: 'Picture' },
    },
    {
      path: '/home/NewVenueActivity',
      component:(() => import('@/views/home-page/venue-vue/next-venue.vue')),
      name: 'NewVenueActivityConfig',
      meta: { title: '新落地页管理', icon: 'Picture' },
    },
    {
      path: '/home/VenueActivityEdit/:id',
      component:(() => import('@/views/home-page/venue-vue/edit')),
      name: 'VenueActivityConfigEdit',
      meta: { title: '落地页编辑', icon: 'el-icon-milk-tea' },
      props: true,
      hidden: true,
    },
    // {
    //   path: '/home/NewVenueActivityEdit',
    //   component:(() => import('@/views/home-page/venue-vue/new-edit')),
    //   name: 'NewVenueActivityConfigEdit',
    //   meta: { title: '新落地页编辑', icon: 'el-icon-milk-tea' },
    //   hidden: true,
    // },
    {
      path: '/home/NextVenueActivityEdit',
      component:(() => import('@/views/home-page/venue-vue/next-edit')),
      name: 'NextVenueActivityConfigEdit',
      meta: { title: '新落地页编辑', icon: 'el-icon-milk-tea' },
      hidden: true,
    },
    {
      path: '/home/EditPlate',
      component:(() => import('@/views/home-page/plate-vue/edit')),
      name: 'ChangePlate',
      meta: {
        title: '板块配置内容页',
        noCache: true,
        icon: 'el-icon-milk-tea',
      },
      hidden: true,
    },
    {
      path: '/home/recommend/list',
      component: (() => import('@/views/recommend/list')

      ),
      name: 'RecommendList',
      meta: { title: '推荐板块配置', icon: 'Bell' },
    },
    {
      path: '/home/recommend/edit',
      component: (() => import('@/views/recommend/edit')

      ),
      name: 'RecommendEdit',
      props: true,
      meta: { title: '推荐板块配置设置', icon: 'el-icon-sell' },
      hidden: true,
    },
    {
      path: '/home/scene/list',
      component: (() => import('@/views/scene/list')

      ),
      name: 'SceneList',
      meta: { title: '智能推荐场景列表', icon: 'Histogram' },
    },
    {
      path: '/home/scene/edit/:id',
      component: (() => import('@/views/scene/edit')

      ),
      props: true,
      name: 'SceneEdit',
      meta: { title: '智能推荐场景内容页', icon: 'el-icon-files' },
      hidden: true,
    },
    {
      path: '/home/scene/goodsList/:id',
      component: (() => import('@/views/scene/goodsList')

      ),
      props: true,
      name: 'SceneGoodsList',
      meta: { title: '场景商品列表', icon: 'el-icon-files' },
      hidden: true,
    },
    {
      path: '/home/operationalTool/splashes/list',
      component:(() => import('@/views/operationalTools/splashes/list')),
      name: 'ToolOpen',
      meta: { title: '闪屏列表', icon: 'Camera', noCache: true },
    },
    {
      path: '/home/operationalTool/splashes/add',
      component:(() => import('@/views/operationalTools/splashes/add')),
      name: 'AddToolOpen',
      meta: { title: '添加闪屏', icon: 'el-icon-picture', noCache: true },
      hidden: true,
    },
    {
      path: '/home/operationalTool/splashes/update',
      component:(() => import('@/views/operationalTools/splashes/update')),
      name: 'EditToolOpen',
      props: true,
      meta: { title: '更新闪屏', icon: 'el-icon-picture', noCache: true },
      hidden: true,
    },
    {
      path: '/home/PassWordActivity',
      component:(() => import('@/views/home-page/password-vue/list')),
      name: 'VueCommandModule',
      meta: { title: '口令管理', icon: 'Notification' },
    },
    {
      path: '/home/EditPassword',
      component:(() => import('@/views/home-page/password-vue/edit')),
      name: 'AddOrEditCommandModule',
      meta: { title: '口令内容', noCache: true, icon: 'Notification' },
      hidden: true,
    },
    {
      path: '/home/ClassifyList',
      component:(() => import('@/views/home-page/classify-vue/list')),
      name: 'ClassifyList',
      meta: { title: '分类列表', icon: 'Clock' },
    },

        {
      path: '/home/listProducts',
      component: () => import('@/views/home-page/list-products/list'),
      name: 'ListProducts',
      meta: { title: '分类管理', icon: 'FolderOpened' }
    },
      {
      path: '/home/listProducts/edit',
      component: () => import('@/views/home-page/list-products/edit'),
      meta: { title: '新建/编辑', icon: 'el-icon-menu' },
        hidden: true
    },
      {
      path: '/home/activity-management',
      component: () => import('@/views/home-page/activity-management/list'),
      name: 'ActivityManagement',
      meta: { title: '本地活动页管理', icon: 'FolderRemove' }
    },
         {
      path: '/home/activity-management/edit',
      component: () => import('@/views/home-page/activity-management/edit'),
      meta: { title: '新建/编辑', icon: 'el-icon-menu' },
        hidden: true
    },
    {
      path: '/home/AddClassify',
      component:(() => import('@/views/home-page/classify-vue/add')),
      name: 'AddClassify',
      meta: { title: '新增分类', icon: 'el-icon-menu' },
      hidden: true,
    },
    {
      path: '/home/EditClassify',
      component:(() => import('@/views/home-page/classify-vue/edit')),
      name: 'EditClassify',
      meta: { title: '编辑分类', icon: 'el-icon-menu' },
      hidden: true,
    },
    {
      path: '/home/ThirdClassify',
      component:(() => import('@/views/home-page/classifyThirdVue/list')),
      name: 'ThirdClassifyList',
      meta: { title: '三级分类列表', icon: 'el-icon-s-operation' },
      hidden: true,
    },
    {
      path: '/home/AddThirdClassify',
      component:(() => import('@/views/home-page/classifyThirdVue/add')),
      name: 'AddThirdClassify',
      meta: { title: '三级分类', icon: 'el-icon-s-operation', noCache: true },
      hidden: true,
    },
    {
      path: '/home/resource-location',
      component:(() => import('@/views/home-page/resource-location/index')),
      name: 'ResourceLocationManage',
      meta: { title: '资源位管理', icon: 'el-icon-s-operation', noCache: true },
      hidden: true,
    },
    // {
    //   path: '/home_actives',
    //   component: () => import('@/views/home-page/index'), // 父级 router-view
    //   name: 'home_active',
    //   meta: {
    //     title: '视频列表页面',
    //     icon: 'el-icon-sunny'
    //   },
    //   redirect: '/home-page/vide-vue/videoVueList',
    //   children: [
    // {
    //   path: '/HomeActive',
    //   component: () => import('@/views/home-page/video-vue/list'),
    //   name: 'home_active',
    //   meta: { title: '首页活动位', keepAlive: false }
    // },
    // {
    //   path: '/editHomePageDetail',
    //   component: () => import('@/views/home-page/video-vue/edit'),
    //   name: 'home_active',
    //   meta: { title: '编辑模块信息', keepAlive: false },
    //   hidden: true
    // },
    // {
    //   path: '/LiveActive',
    //   component: () => import('@/views/home-page/live-vue/list'),
    //   name: 'home_active',
    //   meta: { title: '直播页列表', keepAlive: false }
    // },
    // {
    //   path: '/EditLivePage',
    //   component: () => import('@/views/home-page/live-vue/edit'),
    //   name: 'home_active',
    //   meta: { title: '编辑直播信息', keepAlive: false },
    //   hidden: true
    // },
    //   ]
    // },
    // {
    //   path: '/list_actives',
    //   component: () => import('@/views/home-page/index'), // 父级 router-view
    //   name: 'product_list',
    //   meta: {
    //     title: '产品清单',
    //     icon: 'el-icon-heavy-rain'
    //   },
    //   redirect: '/home-page/vide-vue/videoVueList',
    //   children: [
    //   ]
    // }
  ],
}

export default homePage
