<template>
  <div id="new-easy-image-select" ref="new-easy-image-select">
    <div v-if="disabled" style="
        width: 100%;
        min-height: 100px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
      ">
      <img style="display: block; width: 100%; height: 100%; " :src="imgUrl ? imgUrl : ''" alt="" />
    </div>
    <div v-else id="new-easy-image-select-box" ref="new-easy-image-select-box" @click="selectFile()">
      <input ref="file" type="file" accept="image/*" style="display: none" @change="fileChange()" />
      <img v-show="showImg" ref="img" :src="imgUrl ? imgUrl : ''" alt="" style="display: block ; width: 100%; height: 100%;" width="100%" />
      <div v-show="showAddBtn" id="add-btn" ref="add-btn">+</div>
      <div v-if="showRemoveBtn" id="remove-btn" @click.stop="removeImage()">
        <el-icon>
          <Delete />
        </el-icon>
      </div>
    </div>
    <div id="tip-title" style="text-align: center; font-weight: bold; line-height: 20px">
      {{ tipTitle }}
    </div>
    <div id="tip" style="
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
        color: #c0c4cc;
      ">
      {{ tip }}
    </div>
  </div>
</template>

<script>

import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  components: {
  },
  name: 'NewEasyImageSelect',
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    tipTitle: {
      type: String,
      default: '',
    },
    file: {
      type: File,
      default: null,
    },
    maxSizeMb: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 200,
    },
    imgWidth: {
      type: Number,
      default: 0,
    },
    imgHeight: {
      type: Number,
      default: 0,
    },
    dialogMsg: {
      type: String,
      default: '',
    },
    removeBeforeHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showImg: false,
      showAddBtn: true,
      showRemoveBtn: false,

    }
  },
  watch: {
    imgUrl: {
      handler(newUrl, oldUrl) {
        // if (newUrl) {
        //   this.imgUrl = newUrl;
        // }

        if (this.imgUrl) {
          this.showAddBtn = false
          this.showRemoveBtn = true
          this.showImg = true
        } else {
          this.showAddBtn = true
          this.showRemoveBtn = false
          this.showImg = false
        }
      },
      immediate: true,
      deep: true,
    },
    file() {
      if (this.file == null) {
        this.$refs['img'].src = ''
        $emit(this, 'update:imgUrl', '')
        this.showAddBtn = true
        this.showRemoveBtn = false
        this.showImg = false
      } else {
        this.$refs['img'].src = window.URL.createObjectURL(this.file)
      }
    },
  },
  created() {
    if (this.imgUrl) {
      this.showAddBtn = false
      this.showRemoveBtn = true
      this.showImg = true
    } else {
      this.showAddBtn = true
      this.showRemoveBtn = false
      this.showImg = false
    }
  },
  mounted() {
    this.$refs['new-easy-image-select'].style.width = this.width + 'px'
    if (this.file) {
      this.$refs['img'].src = this.file
      this.showImg = true
    }
    if (this.imgWidth > 0 && this.imgHeight > 0) {
      const temp = this.imgWidth / this.width
      this.$refs['new-easy-image-select-box'].style.height =
        this.imgHeight / temp + 'px'
      this.$refs['add-btn'].style.marginTop =
        this.imgHeight / temp / 2 - 20 + 'px'
    }
    if (this.file) {
      this.$refs['img'].src = window.URL.createObjectURL(this.file)
      this.showAddBtn = false
      this.showRemoveBtn = true
    }
  },
  methods: {
    selectFile() {
      this.$refs['file'].click()
    },
    fileChange() {
      const file = this.$refs['file'].files[0]
      this.$refs['file'].value = ''
      if (!file) {
        return
      }

      if (this.maxSizeMB > 0 && this.maxSizeMB < file.size / 1024 / 1024) {
        this.$message.error(`图片大小不能超过${this.maxSizeMb}M`)
        return
      }
      // this.$refs["img"].src = window.URL.createObjectURL(file)
      $emit(this, 'update:file', file)
      this.showAddBtn = false
      this.showImg = true
      setTimeout(() => {
        this.showRemoveBtn = true
      }, 5)
    },
    removeImage() {
      const fn = () => {
        $emit(this, 'update:file', null)
        $emit(this, 'update:imgUrl', '')
        // this.$refs["img"].src = ""
        this.showAddBtn = true
        this.showRemoveBtn = false
        this.showImg = false
      }
      if (this.dialogMsg) {
        this.$confirm(this.dialogMsg, '提示', { type: 'warning' })
          .then(() => {
            if (this.removeBeforeHandler) {
              this.removeBeforeHandler().then(() => {
                fn()
              })
            } else {
              fn()
            }
          })
          .catch(() => { })
        return
      }
      if (this.removeBeforeHandler) {
        this.removeBeforeHandler().then(() => {
          fn()
        })
      } else {
        fn()
      }
    },
  },
  emits: ['update:imgUrl', 'update:file'],
}
</script>

<style lang="scss" scoped>
#new-easy-image-select-box {
  cursor: pointer;
  width: 100%;
  min-height: 100px;
  border: 2px solid #dcdfe6;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  #add-btn {
    font-size: 40px;
    color: #dcdfe6;
    margin: 30px auto 0;
    width: 20px;
    position: relative;
  }

  #remove-btn {
    display: none;
    border-radius: 0 0 4px 4px;
    position: absolute;
    height: 25px;
    text-align: center;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);

    i {
      font-weight: bold;
      font-size: 15px;
      position: relative;
      top: -5px;
      color: #fff;
    }

    &:hover {
      i {
        color: red;
      }
    }
  }

  &:hover {
    border-color: #409eff;

    #add-btn {
      color: #409eff;
    }

    #remove-btn {
      display: block;
    }
  }
}
</style>
