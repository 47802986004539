<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from '@/store'
import { version } from 'element-plus/package.json'
// import {version} from "vue-router/package.json"
// import { vueversion } from '@vue/runtime-core/package.json'
import { autoRefresh } from '@/utils/autoUpdate'
import { orderRemind } from '@/network/NetworkVoice'
import Cookies from 'js-cookie'
export default {
  name: 'App',
  data() {
    return {
      lastOrderDate: null,
      lastOrderCancelDate: null,
      isPlay: false,
    }
  },
  mounted(){
    console.log(import.meta.env.VITE_VUE_APP_QINIU_URL)
    console.log(version)
    this.ignoreLoopLimit()
    this.$nextTick(() => {
        autoRefresh();
    });
  },
  created() { 
    // 第一次延迟两秒后执行，否则无法获取到权限列表
    setTimeout(this.orderSoundPlay, 2000)
    setInterval(this.orderSoundPlay, 60000)
    const storageSetting =
      JSON.parse(localStorage.getItem('layout-setting')) || ''
    if (storageSetting) {
      let styleTag = document.createElement('style')
      styleTag.setAttribute('id', 'chalk-style')
      document.head.appendChild(styleTag)
      const newStyle = localStorage.getItem('newStyle')
      styleTag.innerText = newStyle
    }
  },
  methods: {
    ignoreLoopLimit() {
      const e = window.onerror
      window.onerror = function (err) {
        if (err === 'ResizeObserver loop limit exceeded') {
          return false
        } else{
          return e(...arguments)
        }
      }
    },
    orderSoundPlay() {
      const deliverCode = store.getters.deliverCode
      if (deliverCode == null || deliverCode == '') {
        return
      }
      const routeNames = store.getters.routeNames
      if (
        routeNames !== null &&
        routeNames !== 'undefined' &&
        (routeNames.includes('OrderCancel') ||
          routeNames.includes('item_order'))
      ) {
        orderRemind()
          .then((response) => {
            const orderCreateDate = response.data['orderCreateDate']
            const lastOrderDate = Cookies.get('lastOrderDate')
            if (orderCreateDate != null) {
              if (lastOrderDate == null || orderCreateDate > lastOrderDate) {
                this.voicePlay('https://qiniupic-ssl.lifemg.cn/order.mp3')
                this.messageNotify('您有新的订单，请及时处理！')
                Cookies.set('lastOrderDate', orderCreateDate)
              }
            }
            const _this = this
            const applyCreateDate = response.data['applyCreateDate']
            const lastOrderCancelDate = Cookies.get('lastOrderCancelDate')
            setTimeout(function () {
              if (applyCreateDate != null) {
                if (
                  lastOrderCancelDate == null ||
                  applyCreateDate > lastOrderCancelDate
                ) {
                  _this.voicePlay(
                    'https://qiniupic-ssl.lifemg.cn/orderCancel.mp3'
                  )
                  _this.messageNotify('您有新的撤单申请!')
                  Cookies.set('lastOrderCancelDate', applyCreateDate)
                }
              }
            }, 10000)
          })
          .catch(() => {})
      }
    },

    voicePlay(voiceFile) {
      new Audio(voiceFile)
        .play()
        .catch()
        .finally(() => {})
    },
    messageNotify(message) {
      this.$notify.info({
        title: '消息',
        duration: 3000,
        message: message,
      })
    },
  },
}
</script>

<style lang="scss">
body .el-table th.gutter {
  display: table-cell !important;
}
.el-dialog{
  margin-top: 15vh;
}
html,
body {
  width: 100%;
  height: 100%;
}
.box-orde {
  .grid-content {
    .el-form-item--medium .el-form-item__content {
      width: 70%;
      flex-shrink: 1;
    }
  }
}
// .el-row{
//   width: 100%;
// }
.el-form--label-left .el-form-item__label{
  font-weight: 700;
}
.el-form-item--default .el-form-item__label{
  font-weight: 700;
}
.theme-dark{
  .el-menu .el-menu--inline{
  background-color: #1f2d3d ;
}
}
.el-tag.is-closable{
  margin-left: 10px;
}
.a-link {
  margin: 0 10px;
  color: #409eff !important;
}
.flex-left-rigth {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.flex-middle-x {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-middle-center {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
}
.flex-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.el-scrollbar__thumb{
 background-color: #333 !important;
}
</style>
