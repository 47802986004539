import request from '@/api/request'

// 登录
export function login(data) {
  const fn = new FormData()
  fn.append('email', data.username)
  fn.append('password', data.password)
  return request({
    url: '/v4/admin/login',
    method: 'post',
    data: fn,
  })
}

// user信息
export function getInfo(token) {
  return request({
    url: '/v4/admin/user/me',
    method: 'get',
  })
}

// 登出
export function logout() {
  return request({
    url: '/v4/admin/logout',
    method: 'post',
  })
}

// 懒加载组织结构数
export function getTreeChildren(id) {
  return request({
    url: '/v4/admin/user/findOrgByCode/' + id,
  })
}

// 移出标签用户
export function moveOutUser(data) {
  return request({
    url: '/v4/admin/label/user',
    method: 'delete',
    data: data,
  })
}

// 获取根组织
export function getRootData() {
  return request({
    url: '/v4/admin/user/initOrg',
  })
}

export function filterOrgByName(params) {
  return request({
    url: '/v4/admin/user/findOrgByName',
    params: params,
  })
}

// 用户列表
export function userList(query) {
  return request({
    url: '/v4/admin/user/user',
    method: 'get',
    params: query,
  })
}

/**
 * 查询店铺详情
 * @param {} query
 */
export function findStoreDetail(query) {
  return request({
    url: '/v4/admin/store/store/' + query,
    method: 'get',
    query: null,
  })
}

/**
 * 活动公司列表
 * @param {} query
 */
export function companyList(query) {
  return request({
    url: 'v4/admin/user/company/list',
    method: 'get',
    params: query,
  })
}

/**
 * 通过手机查询用户信息
 * @param {} query
 */
export function findByUserInfo(query) {
  const url = 'v4/admin/user/user'
  return request({
    url: url,
    method: 'get',
    params: query,
  })
}

/**
 * 通过手机查询用户信息
 * @param {} query
 */
export function addUserInfo(query) {
  const id = query.storeId
  const url = `v4/admin/store/user/${id}/store`
  const fn = new FormData()
  fn.append('storeIds', query.id)
  return request({
    url: url,
    method: 'post',
    data: fn,
  })
}

export function deleteUserInfo(query) {
  const storeId = query.id
  const id = query.storeId
  const url = `v4/admin/user/user/${id}/store/${storeId}`
  return request({
    url: url,
    method: 'delete',
  })
}

/**
 * 关联账号/请求方式get
 * @param {} query
 */
export function getRelationAccount(query) {
  const id = query.id
  const url = `v4/admin/store/${id}/user`
  return request({
    url: url,
    method: 'get',
    params: query,
  })
}

/**
 * 终端列表
 * @param {*} query
 */
export function storeList(query) {
  return request({
    url: 'v4/admin/user/deliver/store/list',
    method: 'get',
    params: query,
  })
}

/**
 * 配送中心列表/请求方式get
 * @param {} query
 */
export function deliverList(query) {
  return request({
    url: 'v4/admin/user/deliver/list',
    method: 'get',
    params: query,
  })
}

// 分公司列表
export function getCompany() {
  return request({
    url: '/v4/admin/user/company/list',
    method: 'get',
    params: {
      pageNo: 1,
      pageSize: 100,
    },
  })
}

// 配送中心列表
export function getDeliver(query) {
  return request({
    url: '/v4/admin/user/deliver/list',
    method: 'get',
    params: query,
  })
}

// 获取用户组织
export function getOrgListRequest() {
  return request({
    url: '/v4/admin/org/active',
    method: 'get',
  })
}

// 修改用户状态
export function updateUserStatus(query) {
  const fn = new FormData()
  fn.append('status', query.status === '1' ? '2' : '1')
  return request({
    url: '/v4/admin/user/user/' + query.id + '/status',
    method: 'patch',
    data: fn,
  })
}

// 修改用户是否禁言
export function updateShutUp(query) {
  const fn = new FormData()
  fn.append('type', query.status === '1' ? '0' : '1')
  fn.append('userId', query.id)
  fn.append('liveId', 0)
  return request({
    url: '/v4/admin/mgLive/live/shutUp',
    method: 'post',
    data: fn,
  })
}

// 新增用户
export function createUser(query) {
  const fn = new FormData()
  fn.append('name', query.name)
  fn.append('userType', query.userType)
  fn.append('mobile', query.mobile)
  fn.append('companyCode', query.companyCode)
  fn.append('orgType', query.orgType)
  fn.append('deliverCode', query.deliverCode)
  fn.append('deliverName', query.deliverName)
  return request({
    url: '/v4/admin/user/user',
    method: 'post',
    data: fn,
  })
}

export function newCreateUser(data) {
  return request({
    url: '/v4/admin/user/user',
    method: 'post',
    data: data,
  })
}

export function newEditUser(id, data) {
  return request({
    url: '/v4/admin/user/user/' + id,
    method: 'patch',
    data: data,
  })
}

// 编辑用户
export function editUser(query) {
  const fn = new FormData()
  fn.append('name', query.name)
  fn.append('userType', query.userType)
  fn.append('mobile', query.mobile)
  fn.append('companyCode', query.companyCode)
  fn.append('orgType', query.orgType)
  fn.append('deliverCode', query.deliverCode)
  fn.append('deliverName', query.deliverName)
  return request({
    url: '/v4/admin/user/user/' + query.userId,
    method: 'patch',
    data: fn,
  })
}

// 查看单个用户信息
export function selectUser(id) {
  return request({
    url: '/v4/admin/user/user/' + id,
    method: 'get',
  })
}

// 导出用户信息
export function exportUser(query) {
  return request({
    url: '/v4/admin/user/user/outPut',
    method: 'get',
    params: query,
  })
}

// 批量新建用户
export function importUser(query, isTag) {
  const fn = new FormData()
  fn.append('file', query.file)
  fn.append('importType', query.method)
  let url = ''
  if (isTag) {
    url = '/v4/admin/user/user/importLabel'
  } else {
    if (query.type == 1) {
      url = '/v4/admin/user/user/import'
    } else {
      url = '/v4/admin/userInfo/birthday/import'
    }
  }
  return request({
    url: url,
    method: 'post',
    data: fn,
  })
}

// 微伴信息同步
export function syncWeibanAPI() {
  return request({
    url: `/v4/admin/user/sync/weiban`,
    method: 'post'
  })
}

// 用户绑定的店铺
export function userStoreList(query) {
  const userId = query.id
  return request({
    url: `/v4/admin/user/user/${userId}/store`,
    method: 'get',
    params: query,
  })
}

// 删除用户绑定的店铺
export function delUserStoreRequest(query) {
  const userId = query.id
  const storeId = query.storeId
  return request({
    url: `/v4/admin/user/user/${userId}/store/${storeId}`,
    method: 'delete',
  })
}

// 清空用户绑定的店铺
export function delUserAllStoreRequest(id) {
  return request({
    url: `/v4/admin/user/user/${id}`,
    method: 'delete',
  })
}

// 查询店铺
export function searchUserStoreRequest(query) {
  return request({
    url: '/v4/admin/store/store',
    method: 'POST',
    data: query,
  })
}

export function searchUserStore(query) {
  return request({
    url: '/v4/admin/store/list',
    method: 'get',
    params: query,
  })
}

// 添加用户店铺
export function addUserStore(query) {
  const userId = query.userId
  const fn = new FormData()
  fn.append('storeIds', query.storeIds)
  return request({
    url: `/v4/admin/user/user/${userId}/store`,
    method: 'post',
    data: fn,
  })
}

export function switchList() {
  return request({
    url: '/v4/admin/switch/switch',
  })
}

export function getOptionInfo(deliverCode) {
  return request({
    url: '/v4/admin/user/deliver/list',
    params: {
      pageNo: 1,
      pageSize: 10,
      deliverCode: deliverCode,
    },
  })
}

export function updateOptionInfo(data) {
  return request({
    url: '/v4/admin/switch/deliverSwitch/update',
    method: 'PATCH',
    data: data,
  })
}

// 获取模块列表
export function getModuleList() {
  return request({
    url: '/v4/admin/backend_permission/module',
    method: 'get',
  })
}

// 获取权限列表
export function getPowerList() {
  return request({
    url: '/v4/admin/backend_permission/list?pageNo=1&pageSize=100000',
    method: 'get',
  })
}

// 添加权限
export function addPower(data) {
  return request({
    url: '/v4/admin/backend_permission/add',
    method: 'post',
    data: data,
  })
}

// 删除权限
export function delPower(id) {
  return request({
    url: '/v4/admin/backend_permission/delete/' + id,
    method: 'delete',
  })
}

// 修改权限
export function updatePower(data) {
  return request({
    url: '/v4/admin/backend_permission/update',
    method: 'put',
    data: data,
  })
}

// 根据id获取权限
export function getPowerById(id) {
  return request({
    url: '/v4/admin/backend_permission/get/' + id,
    method: 'get',
  })
}

// ----------------- 权限管理 ---------------------

// 获取菜单模块下拉列表
export function getMenuModuleList() {
  return request({
    url: '/v4/admin/backend_menu/module',
    method: 'get',
  })
}

// 获取菜单列表
export function getMenuList() {
  return request({
    url: '/v4/admin/backend_menu/list?pageNo=1&pageSize=100000',
    method: 'get',
  })
}

// 添加菜单
export function addMenu(data) {
  return request({
    url: '/v4/admin/backend_menu/add',
    method: 'post',
    data: data,
  })
}

// 删除菜单
export function delMenu(id) {
  return request({
    url: '/v4/admin/backend_menu/delete/' + id,
    method: 'delete',
  })
}

// ------------- 角色管理 ------------

// 角色列表
export function getRoleList() {
  return request({
    url: '/v4/admin/backend_permission/role/list',
    method: 'get',
  })
}

// 添加角色
export function addRole(data) {
  return request({
    url: '/v4/admin/backend_permission/role',
    method: 'post',
    data: data,
  })
}

// 删除角色
export function delRole(id) {
  return request({
    url: '/v4/admin/backend_permission/role/' + id,
    method: 'delete',
  })
}

// 获取角色的权限列表
export function getRolePowers(id) {
  return request({
    url: '/v4/admin/backend_permission/role/role_permission_map/' + id,
    method: 'get',
  })
}

// 保存角色权限
export function saveRolePowers(data) {
  return request({
    url: '/v4/admin/backend_permission/role/role_permission_map',
    method: 'post',
    data: data,
  })
}

// 获取角色的菜单列表
export function getRoleMenus(id) {
  return request({
    url: '/v4/admin/backend_permission/role/role_menu_map/' + id,
    method: 'get',
  })
}

// 保存角色菜单
export function saveRoleMenus(data) {
  return request({
    url: '/v4/admin/backend_permission/role/role_menu_map',
    method: 'post',
    data: data,
  })
}

// ----------------- 用户管理 ---------------------

// 获取用户列表
export function getUserList(data) {
  return request({
    url: '/v4/admin/user_action/list',
    method: 'get',
    params: data,
  })
}

// 分公司下拉菜单
export function getCompanyList() {
  return request({
    url: '/v4/admin/user_action/company_name?pageNo=1&pageSize=100000',
    method: 'get',
  })
}

// 获取组织结构
export function getOrgList() {
  return request({
    url: '/v4/admin/org/active',
    method: 'get',
  })
}

// 根据分公司的Code查询配送中心
export function getDeliverByCompanyCode(companyCode) {
  return request({
    url: '/v4/admin/user_action/deliver_name?companyCode=' + companyCode,
    method: 'get',
  })
}

// 查询所有角色
export function getRoles() {
  return request({
    url: '/v4/admin/user_action/role_name',
    method: 'get',
  })
}

// 添加用户
export function addUser(data) {
  return request({
    url: '/v4/admin/user_action/add',
    method: 'post',
    data: data,
  })
}

// 添加用户
export function updateUser(data) {
  return request({
    url: '/v4/admin/user_action/update',
    method: 'PUT',
    data: data,
  })
}

// 根据Id获取用户
export function getUser(id) {
  return request({
    url: '/v4/admin/user_action/get/' + id,
    method: 'get',
  })
}

// 删除用户
export function delUser(id) {
  return request({
    url: '/v4/admin/user_action/delete/' + id,
    method: 'delete',
  })
}
