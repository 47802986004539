import Layout from '@/layout'

const score = {
  path: '/score',
  component: Layout,
  redirect: '/score/task',
  name: 'ScoreModule',
  meta: {
    title: '会员体系',
    icon: 'Money'
  },
  children: [
    {
      path: '/score/task',
      component: () => import('@/views/score/task/index'),
      name: 'ScoreTask',
      meta: { title: '积分任务', icon: 'Money' }
    },
    // {
    //   path: '/score/luckDraw',
    //   component: () => import('@/views/score/luck-draw/index'),
    //   name: 'LuckDraw',
    //   meta: { title: '抽奖活动', icon: 'Box' }
    // },
    // {
    //   path: "/score/luckDraw/edit",
    //   component: () => import("@/views/score/luck-draw/edit"),
    //   name: "EditLuckDraw",
    //   props: true,
    //   meta: { title: "编辑抽奖活动", icon: "el-icon-picture", noCache: true },
    //   hidden: true
    // },
    // {
    //   path: "/score/luckDraw/view",
    //   component: () => import("@/views/score/luck-draw/view"),
    //   name: "ViewLuckDraw",
    //   props: true,
    //   meta: { title: "查看抽奖活动", icon: "el-icon-picture", noCache: true },
    //   hidden: true
    // },
    
    {
      path: '/score/detail',
      component: () => import('@/views/score/detail/index'),
      name: 'ScoreDetail',
      meta: { title: '积分明细', icon: 'Discount' }
    },
    {
      path: '/score/prize',
      component: () => import('@/views/score/prize/index'),
      name: 'PrizeManage',
      meta: { title: '兑换物品管理', icon: 'GoodsFilled' }
    },
    {
      path: '/score/exchange-record',
      component: () => import('@/views/score/exchange-record/index.vue'),
      name: 'ExchangeRecordManage',
      meta: { title: '兑换记录', icon: 'Notebook' }
    },
     {
      path: '/score/growth-value',
      component: () => import('@/views/score/growth-value/index.vue'),
      name: 'GrowthValue',
      meta: { title: '成长值任务', icon: 'Notebook' }
    },
     {
      path: '/score/growth-detail',
      component: () => import('@/views/score/growth-detail/index.vue'),
      name: 'GrowthDetail',
      meta: { title: '成长值明细', icon: 'Notebook' }
    },
  ]
}

export default score
