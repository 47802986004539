import * as Vue from 'vue'
import Layout from '@/layout'

const redPacketRouter = {
  path: '/red',
  component: Layout,
  redirect: '/red/redpacketlog',
  name: 'RedPacketLog',
  meta: {
    title: '红包管理',
    icon: 'Message',
  },
  children: [
    {
      path: '/red/redpacket',
      component:(() => import('@/views/redpacket/manage/list.vue')),
      name: 'RedPacketList',
      meta: { title: '红包列表', icon: 'Ticket' },
    },
    {
      path: '/red/redpacketlog',
      component:(() => import('@/views/redpacket/log/list-bak.vue')),
      name: 'RedPacketStatistics',
      meta: { title: '使用统计', icon: 'Histogram' },
    },
    {
      path: '/red/redpacketlogList',
      component:(() => import('@/views/redpacket/log/list.vue')),
      name: 'RedPacketLog',
      hidden: true,
      meta: { title: '红包日志', icon: 'people' },
    },
    {
      path: '/red/select-report',
      redirect: '/statistics_box?mgPage=luckyMoneySelect&reportFormType=2',
      hidden: false,
      meta: { title: '红包管理查询', icon: 'Ticket' },
    },
    {
      path: '/red/save',
      component:(() => import('@/views/redpacket/save/save')),
      name: 'RedPacketAdd',
      meta: { title: '新增红包', icon: 'people' },
      hidden: true,
    },
    {
      path: '/red/info',
      component:(() => import('@/views/redpacket/save/info')),
      name: 'RedPacketInfo',
      meta: { title: '红包详情', icon: 'people' },
      hidden: true,
    },
  ],
}

export default redPacketRouter
