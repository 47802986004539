const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  userId: (state) => state.user.userId,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  companyCode: (state) => state.user.companyCode,
  deliverCode: (state) => state.user.deliverCode,
  sidebarRoutes: (state) => state.permission.sidebarRoutes,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  isRequireSetPwd: (state) => state.user.isRequireSetPwd,
  routeNames: (state) => state.permission.routeNames,
}
export default getters
