<template>
  <div
    :style="{
      backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.bg,
    }"
  >
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="[settings.sideTheme , showLogo?'scrollbar-logo':'']"  wrap-class="scrollbar-wrapper">
      <el-menu
      :default-active="activeMenu"
       class="el-menu-container"
        :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.bg"
        :text-color="settings.sideTheme === 'theme-dark' ? variables.menuText : 'rgba(0,0,0,.65)'"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRoutes"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.module.scss";

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebar", "sidebarRoutes"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
      // return {
      //   menuText: "#bfcbd9",
      //   menuActiveText: "#409EFF",
      //   subMenuActiveText: "#f4f4f5", // https://github.com/ElemeFE/element/issues/12951
      //   menuBg: "#304156",
      //   menuHover: "#263445",
      //   subMenuBg: "#191a23",
      //   subMenuHover: "#001528",
      //   sideBarWidth: "210px",
      //   bg:'#ffffff'
      // };
      // #304156
    },
    isCollapse() {

      return !this.sidebar.opened;
    },
  },
  created(){

  }
};
</script>
