import * as Vue from 'vue'
import Layout from '@/layout'

const channelVerificationRouter = {
  path: '/channel',
  component: Layout,
  redirect: '/channel/verification',
  name: 'ChannelVerification',
  meta: {
    title: '渠道管理',
    icon: 'Guide',
  },
  children: [
    {
      path: '/channel/verification',
      component: (() => import('@/views/channel/verification.vue')),
      name: 'ChannelVerification',
      meta: { title: '渠道管理', icon: 'Guide' },
    },
    {
      path: '/channel/verification',
      component: (() => import('@/views/channel/verification.vue')),
      name: 'Verification',
      meta: { title: '跳转登录', icon: 'el-icon-document' },
      hidden: true,
    },
  ],
}

export default channelVerificationRouter
