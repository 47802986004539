<template>
  <section id="app-main" class="app-main">
    <!-- <transition name="fade-transform" mode="out-in" max="20">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition> -->
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    },
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  // min-height: calc(100vh - 0px);
  height: 100%;
  width: 100%;
  // position: relative;
  overflow: auto;
  background: #fff;
}
.fixed-header + .app-main {
    margin-top: 50px;
  }
.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }
  .fixed-header + .app-main {
    margin-top: 84px;
  }

}

</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
