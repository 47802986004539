<template>
  <el-date-picker v-model="datetime" style="width: 240px;" type="daterange" start-placeholder="开始时间"
    end-placeholder="结束时间" value-format="YYYY-MM-DD" :shortcuts="shortcuts"></el-date-picker>
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
  name: "easy-datetimerange-select",
  props: {
    startTime: {
      type: String,
      default: null,
    },
    endTime: {
      type: String,
      default: null,
    },
    shortcuts: {
      type: Array,
      default: () => {
        return [
          {
            text: '今',
            value() {
              const end = new Date();
              const start = new Date();
              return [start, end];
            }
          },
          {
            text: '昨',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              return [start, end];
            }
          },
          {
            text: '近7天',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '近30天',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          }
        ]
      }
    }
  },
  setup(props, context) {
    const datetime = ref(null)
    watch(datetime, (val) => {
      if (val) {
        context.emit('update:startTime', val[0])
        context.emit('update:endTime', val[1])
      } else {
        context.emit('update:startTime', null)
        context.emit('update:endTime', null)
      }
    })
    watch(() => props.startTime, (val) => {
      const dateTime = [val, props.endTime]
      if (JSON.stringify(dateTime) === JSON.stringify(datetime.value)) {
        return
      }
      if (val) {
        datetime.value = dateTime
      } else {
        datetime.value = null
      }
    })
    onMounted(() => {
      const dateTime = [props.startTime, props.endTime]
      if (JSON.stringify(dateTime) === JSON.stringify(datetime.value)) {
        return
      }
      if (props.startTime) {
        datetime.value = dateTime
      } else {
        datetime.value = null 
      }
    })
    return {
      datetime,
    }
  }
}
</script>

<style scoped></style>
