<!--  -->
<template>
  <div class="">
    <el-select
    filterable
      v-model="companyCodeValue"
      :style="`width:${width}`"
      clearable
      :disabled="disabled"
      placeholder="分公司"
      @change="changeCompany()"
      @clear="empty()"
    >
      <el-option
        v-for="company in companyList"
        :key="company.orgCode"
        :label="company.orgName + '-' + company.orgCode"
        :value="company.orgCode"
      >{{ company.orgName }}-{{ company.orgCode }}</el-option>
    </el-select>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import { branchOffice } from "../../../network/NetworkOrderZg";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    orgType: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: ""
    },
    companyCode: {
      type: String,
      default: ""
    },
    disabledTemp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 这里存放数据
    return {
      disabled: false,
      companyList: [],
      companyCodeValue: '',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    companyCode() {
      if (this.disabledTemp) {
        this.companyCodeValue = this.companyCode
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    branchOffice().then((res) => {
      this.companyList = res.data.orgList;
      this.$nextTick().then((res) => {
        if (this.disabledTemp && this.$store.getters.companyCode != 0) {
          this.disabled = true;
          this.companyCodeValue = this.$store.getters.companyCode;
          this.changeCompany()
        }
      });
    });
    this.$emit("selectBrabch", this.companyCodeValue);
  },
  // 方法集合
  methods: {
    empty() {
      this.$emit("selectBrabch", '', []);
    },
    changeCompany() {
      for (const item of this.companyList) {
        if (item.orgCode === this.companyCodeValue) {
          this.deliverData = item.childList
          this.$emit("selectBrabch", this.companyCodeValue, this.deliverData);
        }
      }
    },
  }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='' scoped>
/* @import url(); 引入公共css类 */
</style>
