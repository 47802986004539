<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <!-- 面包屑导航 -->
    <!-- <breadcrumb class="breadcrumb-container" v-if="!topNav" /> -->

    <!-- 顶部导航 -->
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->
        <!-- <div class="right-menu-item">
          <el-button v-if="!isRequireSetPwd" type="primary" @click="jump">
            回退老版本 <el-icon><Switch /></el-icon> 
          </el-button>
        </div> -->
        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->
        <div class="right-menu-item">
          <el-button v-if="!isRequireSetPwd" type="primary" @click="goOld">
            点击跳转旧后台
          </el-button>
        </div>

        <!-- <screenfull
                  id="screenfull"
                  class="right-menu-item hover-effect"
                /> -->

        <div class="right-menu-item">{{ name }}</div>
        <!-- <el-tooltip content="Global Size" effect="dark" placement="bottom">
                  <size-select id="size-select" class="right-menu-item hover-effect" />
                </el-tooltip> -->
      </template>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" />
          <el-icon class="home-icon"><CaretBottom /></el-icon>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <!-- <router-link to="/profile/index">
                    <el-dropdown-item>Profile</el-dropdown-item>
                  </router-link> -->
            <router-link to="/">
              <el-dropdown-item>首页</el-dropdown-item>
            </router-link>
            <!-- <a target="_blank" href="https://github.com/PanJiaChen/vue-element-admin/">
                    <el-dropdown-item>Github</el-dropdown-item>
                  </a>
                  <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
                    <el-dropdown-item>Docs</el-dropdown-item>
                  </a> -->
              <el-dropdown-item @click="setting = true">
              <span>布局设置</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click="logout">
              <span style="display: block">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
// import { CaretBottom as ElIconCaretBottom } from '@element-plus/icons-vue'
import { mapGetters } from 'vuex'
import Breadcrumb from './Breadcrumb'
import Hamburger from './Hamburger'
import Screenfull from './Screenfull'
import TopNav from '@/layout/components/TopNav'
export default {
  components: {
    Breadcrumb,
    Hamburger,
    TopNav,
    // SizeSelect,
    // Search
    Screenfull,
    // ElIconCaretBottom,
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device', 'name', 'isRequireSetPwd']),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val,
        })
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      },
      set(val){

      }
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    goOld() {
      window.open('/admin')
    },
    jump(){
      console.log(import.meta.env.VITE_VUE_APP_LINK)
    //  window.location.href='https://new.lifemg.cn/newadmin/#/dashboard'
      window.location.href = import.meta.env.VITE_VUE_APP_LINK
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
.home-icon{
  position: absolute;
  top: 13px;
}
  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
