<template>
  <div id="easy-table">
    <el-table @sort-change="(e) => {
      $emit('sortChange', e);
    }" ref="el-table" v-loading="isLoad" border :data="data" :show-summary="!!count" :summary-method="getSummaries" :scrollbar-always-on="true"
      :height="height" size="small" @selection-change="(rows) => (handleSelectionChange ? handleSelectionChange(rows) : '')
        ">
      <template v-for="(item, i) in computedColumnConfig">
        <el-table-column v-if="item.columnType" :type="item.columnType ? item.columnType : 'default'" :label="item.label"
          :align="item.align ? item.align : 'center'" :width="item.width"
          :show-overflow-tooltip="item.showOverflowTooltip" :fixed="item.fixed" :index="indexMethod">
        </el-table-column>
        <el-table-column :min-width="item.minWidth" v-if="!item.columnType" :label="item.label"
          :align="item.align ? item.align : 'center'" :width="item.width"
          :show-overflow-tooltip="item.showOverflowTooltip" :fixed="item.fixed" :sortable="!!item.sortable"
          :prop="item.prop || (item.template ? item.template.prop : false) || ''">
          <template v-if="!item.columnType" v-slot="{ row, $index }">
            <template v-if="item.prop">
              <div :style="getValue(item.css, row)">
                {{ row[item.prop] }}
              </div>
            </template>
            <template v-else-if="item.template.type === 'text'">
              <div :style="getValue(item.template.css, row)">
                {{ getValue(item.template.value, row) }}
              </div>
            </template>
            <template v-else-if="item.template.type === 'index'">
              <span>
                {{ indexMethod(i) }}
              </span>
            </template>
            <template v-else-if="item.template.type === 'text-list'">
              <div style="
                  width: 100%;
                  max-height: 100px;
                  overflow-x: hidden;
                  overflow-y: auto;
                ">
                <div v-for="(textListItem, textListIndex) in getValue(
                  item.template.value,
                  row
                )" v-bind:key="textListIndex">
                  {{ textListItem }}
                </div>
              </div>
            </template>
            <!-- 晨光联盟 个性化 组件,  展示的 数据的 创建 修改 时间 -->
            <template v-else-if="item.template.type === 'mg-update-info'">
              <div>
                <!-- 组织名字段 -->
                {{
                  item.template.orgNameProp
                  ? row[item.template.orgNameProp]
                  : row.orgName
                }}
              </div>
              <div>
                {{
                  item.template.createUserProp
                  ? row[item.template.createUserProp]
                  : row.createBy
                }}
              </div>
              <div>
                {{
                  item.template.createTimeProp
                  ? row[item.template.createTimeProp]
                  : row.createTime
                }}
              </div>
              <div>
                {{
                  item.template.updateUserProp
                  ? row[item.template.updateUserProp]
                  : row.updateBy
                }}
              </div>
              <div>
                {{
                  item.template.updateTimeProp
                  ? row[item.template.updateTimeProp]
                  : row.updateTime
                }}
              </div>
            </template>

            <template v-else-if="item.template.type === 'image'">
              <el-image :src="row[item.template.prop]" :style="{
                width: item.template.width || '100px',
              }" :zoom-rate="1.2" :preview-src-list="[row[item.template.prop]]" :initial-index="4" fit="cover"
                :preview-teleported="true" />
            </template>
            <!--
                      按钮组
                      表格列  按钮 类型
                    -->
            <template v-else-if="item.template.type === 'btns'">
              <template v-for="(btn, btnIndex) in item.template.list" v-bind:key="btnIndex">
                <div :style="{
                  display: item.template.display ? item.template.display : 'block',
                  padding: '0px 5px',
                }" v-if="btn.show !== undefined ? getValue(btn.show, row) : true">
                  <el-button :style="{ ...getValue(btn.css, row) }" :icon="btn.icon" link type="primary" :size="btn.size ? btn.size : btn.type === 'text' ? '' : 'mini'
                    " :disabled="getValue(btn.disabled, row)" @click="
    btn.meta
      ? handleMeta(btn, row)
      : btn.click
        ? btn.click(row, flushfn)
        : null
    ">
                    {{ getValue(btn.value, row) }}
                    <el-tooltip v-if="btn.tooltip" :effect="btn.tooltip.effect || 'dark'" :content="typeof btn.tooltip.content === 'string'
                        ? btn.tooltip.content
                        : ''
                      " :placement="btn.tooltip.placement">
                      <template v-if="typeof btn.tooltip.content === 'object'" #content>
                        <div>
                          <div v-for="(i, contentItem) in btn.tooltip.content" v-bind:key="i">
                            {{ contentItem }}
                          </div>
                        </div>
                      </template>
                      <div style="display: inline-block">
                        <i :class="btn.tooltip.iconClass || 'el-icon-question'"></i>
                      </div>
                    </el-tooltip>
                  </el-button>
                </div>
              </template>
              <!-- <div v-for="(btn, btnIndex) in item.template.list"
                v-show="btn.show !== undefined ? getValue(btn.show, row) : true" v-bind:key="btnIndex" :style="{
                  display: item.template.display
                    ? item.template.display
                    : 'block',
                  padding: '0px 5px',
                }">
                <el-button :style="{ ...getValue(btn.css, row) }" :icon="btn.icon"
                   link
                  type="primary" :size="
                    btn.size ? btn.size : btn.type === 'text' ? '' : 'mini'
                  " :disabled="getValue(btn.disabled, row)" @click="
                    btn.meta
                      ? handleMeta(btn, row)
                      : btn.click
                        ? btn.click(row, flushfn)
                        : null
                  ">
                  {{ getValue(btn.value, row) }}
                  <el-tooltip v-if="btn.tooltip" :effect="btn.tooltip.effect || 'dark'" :content="
                    typeof btn.tooltip.content === 'string'
                      ? btn.tooltip.content
                      : ''
                  " :placement="btn.tooltip.placement">
                    <template v-if="typeof btn.tooltip.content === 'object'" #content>
                      <div>
                        <div v-for="(i, contentItem) in btn.tooltip.content" v-bind:key="i">
                          {{ contentItem }}
                        </div>
                      </div>
                    </template>
                    <div style="display: inline-block">
                      <i :class="btn.tooltip.iconClass || 'el-icon-question'"></i>
                    </div>
                  </el-tooltip>
                </el-button>
              </div> -->
            </template>
            <template v-else-if="item.template.type === 'slot'">
              <slot :row="row"></slot>
            </template>
            <!-- 具名插槽 -->
            <template v-else-if="item.template.type === 'new-slot'">
              <slot :name="item.template.name" :row="row" :index="$index"></slot>
            </template>
            <template v-else-if="item.template.type === 'enums'">
              {{ new Enum(...item.template.enums).getEnumDataByValue(row[item.template.prop]).label }}
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <slot name="footer" :resp-data="respData"></slot>
    <el-pagination v-if="showPage" background style="padding: 20px; text-align: right" :current-page="page.pageNo"
      :page-sizes="pageSize || [10, 50, 100, 200]" :page-size="page.pageSize"
      :layout="this.pageLayout || 'total, sizes, prev, pager, next, jumper'" :total="page.total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange"></el-pagination>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'
import {ElMessageBox} from 'element-plus'
import TemplateCreate from '@/views/template/createAndEdit/create'
import {Enum} from "../../utils/enum";

export default {
  name: 'EasyTable',
  props: [
    'url',
    'method',
    'params',
    'columnConfig',
    'reload',
    'flush',
    'flushData',
    'height',
    'lazy',
    'dataList',
    'dataFormat',
    'countFormat',
    'disableSummary',
    'handleSelectionChange',
    'pageSize',
    'defaultPageSize',
    'summaryPromise',
    'pageLayout',
  ],
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: this.defaultPageSize || 10,
        total: 0,
      },
      data: [],
      count: null,
      isLoad: false,
      showPage: false,
      respData: {},
    }
  },
  computed: {
    Enum() {
      return Enum
    },
    computedColumnConfig() {
      return this.columnConfig.filter((item) => {
        return !item.hidden
      })
    },
  },
  watch: {
    reload() {
      if (this.reload) {
        this.page.pageNo = 1
        this.getData()
      }
    },
    flush() {
      if (this.flush) {
        this.data = []
        this.getData()
      }
    },
    flushData() {
      if (this.flushData) {
        this.data = [...this.data]
        $emit(this, 'update:flushData', false)
      }
    },
    dataList(){
      this.data = this.dataFormat
        ? this.dataFormat(this.dataList)
        : this.dataList
      $emit(this, 'dataLoaded', this.data)
      $emit(this, 'update:reload', false)
      $emit(this, 'update:flush', false)
    }
  },
  created() {
    this.console = window.console
    if (!this.lazy) {
      this.getData()
    } else {
      $emit(this, 'update:reload', false)
      $emit(this, 'update:flush', false)
    }
  },
  methods: {
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1
    },
    // 表尾合计行
    getSummaries(param) {
      // console.log(param)
      // const { columns, data } = param;
      const sums = ['合计']

      for (let i = 0; i < this.columnConfig.length; i++) {
        const column = this.columnConfig[i]
        if (
          column.prop &&
          this.count[column.prop] != null &&
          this.count[column.prop] != undefined
        ) {
          sums[i] = this.count[column.prop]
        }
      }
      return sums
    },
    flushfn() {
      this.getData()
    },
    handleMeta(btn, row) {
      // 跳转 页面
      if (btn.meta === 'router') {
        this.$router.push(this.getValue(btn.url, row))

        // 点击时删除 废弃,可以使用 request
      } else if (btn.meta === 'delete') {
        if (!btn.url) {
          return
        }
        const config = {}
        config.url = this.getValue(btn.url, row)
        config.method = btn.method ? btn.method : 'GET'
        const payload = btn.params ? btn.params : {}
        if (this.method === 'GET') {
          config.params = payload
        } else {
          config.data = payload
        }
        this.$confirm('确定要删除吗？', '提示', { type: 'error' })
          .then(() => {
            request(config).then(() => {
              this.$message.success('删除成功！')
              this.flushfn()
            })
          })
          .catch(() => { })

        // 点击是发送请求
      } else if (btn.meta === 'request') {
        if (!btn.url) {
          return
        }
        const config = {}
        config.url = this.getValue(btn.url, row)
        config.method = btn.method ? btn.method : 'GET'
        const payload = btn.params ? this.getValue(btn.params, row) : {}
        if (config.method === 'GET') {
          config.params = payload
        } else {
          config.data = payload
        }
        // 点击时 对话框信息, 如果 没有给  dialogMsg 没有的话,没有弹窗
        if (btn.dialogMsg) {
          this.$confirm(this.getValue(btn.dialogMsg, row), '提示', {
            type: btn.dialogType,
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              request(config).then((res) => {
                if (btn.popup && res.code == 200003) {
                  ElMessageBox.alert(res.message, '提示', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                  })
                } else {
                  this.$message.success('操作成功！')
                  btn.success ? btn.success(row,res) : ''
                  this.flushfn()
                }
              })
            })
            .catch(() => { })
        } else {
          request(config).then((res) => {
            this.$message.success('操作成功！')
            btn.success ? btn.success(row,res) : ''
            this.flushfn()
          })
        }
      } else if (btn.meta === 'excel-export') {
        if (!btn.url) {
          return
        }
        const config = {}
        config.url = this.getValue(btn.url, row)
        config.method = btn.method ? btn.method : "GET"
        const payload = btn.params ? this.getValue(btn.params, row) : {}
        if (config.method === "GET") {
          config.params = payload
        } else {
          config.data = payload
        }
        request(config).then(data => {
          if (btn.async) {
            this.$message.success("导出成功,请前往导入导出中心查看!")
          } else {
            this.$message.success(btn.successMsg || "导出成功!");
            window.location.href = data.message
          }
        }).finally(() => {
          this.exportLoading = false
        })
      }
    },
    AddKey(params) {
      const data = []
      for (let i = 0; i < params.length; i++) {
        data.push({
          easyKey: i + 1 + (this.page.pageNo - 1) * this.page.pageSize,
          ...params[i],
        })
      }
      return data
    },

    getData() {
      if (this.dataList) {
        this.data = this.dataFormat
          ? this.dataFormat(this.dataList)
          : this.dataList
        $emit(this, 'dataLoaded', this.data)
        $emit(this, 'update:reload', false)
        $emit(this, 'update:flush', false)
        return
      }
      if (!this.url) {
        return
      }
      const config = {}
      config.url = this.url
      config.method = this.method ? this.method : 'GET'
      const payload = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.params,
      }
      if (config.method === 'GET') {
        config.params = payload
      } else {
        config.data = payload
      }
      this.isLoad = true
      if (import.meta.env.VITE_ENV !== 'prod') {
      }
      request(config)
        .then(async (data) => {
          if (import.meta.env.VITE_ENV !== 'prod') {
          }
          const temp = data.data
          this.respData = data
          if (temp.data && temp.data.length > 0) {
            this.data = this.dataFormat
              ? this.dataFormat(temp.data)
              : this.AddKey(temp.data)
          } else if (temp.list && temp.list.length > 0) {
            this.data = this.dataFormat
              ? this.dataFormat(temp.list)
              : this.AddKey(temp.list)
          } else if (temp.items && temp.items.length > 0) {
            this.data = this.dataFormat
              ? this.dataFormat(temp.items)
              : this.AddKey(temp.items)
          } else {
            this.data = []
          }
          if (temp.summary && !this.disableSummary) {
            this.count = this.countFormat
              ? this.countFormat(temp.summary)
              : temp.summary
          }
          if (this.summaryPromise && !this.disableSummary) {
            const data = await this.summaryPromise()
            this.count = this.countFormat
              ? this.countFormat(data.data)
              : data.data
          }
          this.showPage = temp.total && temp.total > 0
          this.page.total = data.data.total
          $emit(this, 'dataLoaded', this.data)
        })
        .finally(() => {
          this.isLoad = false
          $emit(this, 'update:reload', false)
          $emit(this, 'update:flush', false)
          this.$nextTick(() => {
            this.$refs['el-table'].doLayout()
          })
        })
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(pageNo) {
      this.page.pageNo = pageNo
      this.getData()
    },
    getValue(value, row) {
      return value instanceof Function ? value(row) : value
    },
  },
  emits: ['update:flushData', 'update:reload', 'update:flush', 'dataLoaded'],
}
</script>

<style lang="scss" scoped>
#easy-table {
  width: 100%;
  overflow: auto;
  display: block;
  position: relative;
}

.el-pagination {
  text-align: center !important;
}
</style>
