import { ElMessage  } from 'element-plus'


// 生成UUID
function uuid() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    // eslint-disable-next-line no-constant-condition
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  // eslint-disable-next-line no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  // eslint-disable-next-line no-bitwise
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}


class PrototypeConfig {
  static createPrototype(vue) {
    Number.getFloatStr = function (num) {
      num += ''
      num = num.replace(/[^0-9|\.]/g, '') // 清除字符串中的非数字非.字符
      // eslint-disable-next-line no-constant-condition
      if (/^0+/) {
        // 清除字符串开头的0
        // eslint-disable-next-line brace-style
        num = num.replace(/^0+/, '')
      }
      if (!/\./.test(num)) {
        // 为整数字符串在末尾添加.00
        // eslint-disable-next-line brace-style
        num += '.00'
      }
      if (/^\./.test(num)) {
        // 字符以.开头时,在开头添加0
        // eslint-disable-next-line brace-style
        num = '0' + num
      }
      num += '00' // 在字符串末尾补零
      num = num.match(/\d+\.\d{2}/)[0]
      return num
    }
    Date.prototype.format = function (format) {
      var o = {
        'M+': this.getMonth() + 1, // month
        'd+': this.getDate(), // day
        'h+': this.getHours(), // hour
        'm+': this.getMinutes(), // minute
        's+': this.getSeconds(), // second
        'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
        S: this.getMilliseconds(), // millisecond
      }
      if (/(y+)/.test(format)) {
        format = format.replace(
          RegExp.$1,
          (this.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return format
    }

    // Array.prototype.hasValue = function (value) {
    //   return this.indexOf(value) > -1
    // }
    Object.defineProperty(Array.prototype, 'hasValue', {
      enumerable: false,
      value: function (value) {
        return this.indexOf(value) > -1
      }
    })

    // 使用 defineProperty 重写 uuid 和 copy 方法
    // const uuidKey = Symbol('uuid');
    Object.defineProperty(Object.prototype, 'createUUID', {
      enumerable: false,
      value: function () {
        if (!this._uuid) {
          this._uuid = uuid();
        }
        return this._uuid;
      }
    });

    Object.defineProperty(Object.prototype, 'copy', {
      enumerable: false,
      value: function () {
        return JSON.parse(JSON.stringify(this))
      }
    });

    console.log(vue,window.$vueApp)
    // 统一弹框
    vue.config.globalProperties.Message = function (value, type) {
      ElMessage({
        message: value || '操作成功',
        type: type || 'success',
      })
    }
    vue.config.globalProperties.accurateDecimal = function (number, format, zeroFill) {
      // 判断非空
      if (!isEmpty(number)) {
        // 正则匹配:正整数，负整数，正浮点数，负浮点数
        if (!/^\d+(\.\d+)?$|^-\d+(\.\d+)?$/.test(number)) {
          return number
        }
        var n = 1
        for (var i = 0; i < format; i++) {
          n = n * 10
        }
        // 四舍五入
        number = Math.round(number * n) / n
        var str = number.toString()
        // 是否补零
        if (zeroFill) {
          var index
          if (str.indexOf('.') == -1) {
            index = format
            str += '.'
          } else {
            index = format - (str.length - 1 - str.indexOf('.'))
          }

          for (var i = 0; i < index; i++) {
            str += '0'
          }
        }
        return str
      }
      return number
    }
    vue.config.globalProperties.isEmpty = function (ObjVal) {
      if (
        ObjVal == null ||
        typeof ObjVal === 'undefined' ||
        (typeof ObjVal === 'string' && ObjVal == '' && ObjVal != 'undefined')
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
export default PrototypeConfig
