import Layout from '@/layout'

const authorityRouter = {
  path: '/authority',
  component: Layout,
  name: 'backend_permission',
  meta: { title: '权限管理', icon: 'Menu' },
  children: [
    {
      path: '/authority/power-manage',
      component: ( () => import( '@/views/authority/power/manage' ) ),
      name: 'PermissionManage',
      meta: { title: '权限管理', keepAlive: false, icon: 'Menu' },
    },
    {
      path: '/power-save/:id',
      component: ( () => import( '@/views/authority/power/save' ) ),
      name: 'PermissionEdit',
      props: true,
      meta: { title: '编辑权限', keepAlive: false },
      hidden: true,
    },
    {
      path: '/authority/menu-manage',
      component: ( () => import( '@/views/authority/menu/manage' ) ),
      name: 'MenuManage',
      meta: { title: '菜单管理', keepAlive: false, icon: 'Memo' },
      // hidden: true
    },
    {
      path: '/menu-save',
      component: ( () => import( '@/views/authority/menu/save' ) ),
      name: 'MenuEdit',
      props: true,
      meta: { title: '编辑菜单', keepAlive: false },
      hidden: true,
    },
    {
      path: '/authority/role-manage',
      component: ( () => import( '@/views/authority/role/manage' ) ),
      name: 'RoleManage',
      meta: { title: '角色管理', keepAlive: false, icon: 'UserFilled' },
      // hidden: true
    },
    {
      path: '/role-save',
      component: ( () => import( '@/views/authority/role/save' )

      ),
      name: 'RoleEdit',
      props: true,
      meta: { title: '编辑角色', keepAlive: true },
      hidden: true,
    },
    {
      path: '/edit-power/:id/:name',
      component: ( () => import( '@/views/authority/role/edit-power' ) ),
      name: 'RolePermission',
      props: true,
      meta: { title: '编辑权限', keepAlive: false },
      hidden: true,
    },
    {
      path: '/new-edit-power/:id/:name',
      component: ( () => import( '@/views/authority/role/new-edit-power' ) ),
      name: 'RolePermission',
      props: true,
      meta: { title: '编辑权限', keepAlive: false },
      hidden: true,
    },
    {
      path: '/edit-menu/:id/:name',
      component: ( () => import( '@/views/authority/role/edit-menu' ) ),
      name: 'RoleMenu',
      props: true,
      meta: { title: '编辑菜单', keepAlive: false },
      hidden: true,
    },
    {
      path: '/new-edit-menu/:id/:name',
      component: ( () => import( '@/views/authority/role/new-edit-menu' ) ),
      name: 'RoleMenu',
      props: true,
      meta: { title: '编辑菜单', keepAlive: false },
      hidden: true,
    },
    {
      path: '/authority/sys-user-manage',
      component: ( () => import( '@/views/authority/user/manage' ) ),
      name: 'PermissionUserManage',
      meta: { title: '后台用户管理', icon: 'User' },
    },
    {
      path: "/sys-user-save",
      component: () => import( "@/views/authority/user/save" ),
      name: "UserEdit",
      meta: { title: "新增/编辑用户", keepAlive: false, noCache: true },
      hidden: true
    },
  ],
}

export default authorityRouter
