/**
 *
 * 注册所有组件
 */
// 表格
import EasyTable from '@/components/Table/easy-table'
// 表格
import vElTable from '@/components/Table/v-el-table'
// 组织筛选
import NewOrgSelect from '@/components/Select/new-org-select'
// 分公司选择 封装
import BrabchSelect from '@/components/Select/brabchSelect/brabch-select'
// 配送中心封装
import Distribution from '@/components/Select/distribution/distribution'
// 选择框
import OrgSelect from '@/components/Select/org-select'
// 选择框
import easySelect from '@/components/Select/easy-select'
// 选择框
import easyDataSelect from '@/components/Select/easy-data-select'
// 时间选择器
import easyDatetimePicker from '@/components/dateTimePicker/easy-datetime-picker'
// 时间选择器
import easyDatePicker from '@/components/dateTimePicker/easy-date-picker'
import easyDateTimePicker from "@/components/Select/easy-date-time-picker"
// 大文件上传七牛
import QiniuUpload from "@/components/Upload/qiniu-upload.vue"
// 上传
import uploadExcel from '@/components/Upload/upload-excel'
import NewEasyImageSelect from '@/components/Upload/new-easy-image-select'
import EasyExcelExport from '@/components/Upload/easy-excel-export'
import EasyExcelImportPro from '@/components/Upload/easy-excel-import-pro'
import EasyExcelImport from '@/components/Upload/easy-excel-import'
import EasyFileSelect from '@/components/Upload/easy-file-select'
import EasyImageSelectPro from '@/components/Upload/easy-image-select-pro'
import EasyImageSelect from '@/components/Upload/easy-image-select'
import SvgIcon from '@/components/SvgIcon/index.vue' // 引入组件
import DiTable from '@/components/mg-component/ditable'
import MgTable from '@/components/mg-component/mg-table'
import MgLabel from '@/components/mg-component/mg-label'

class ComponentConfig {
  static createComponent(vue) {
    // 表格
    vue.component('easy-table', EasyTable)
    vue.component('v-el-table', vElTable)
    vue.component('svg-icon',SvgIcon)
    // 分公司 配送中心
    vue.component('brabch-select', BrabchSelect)
    vue.component('distribution-select', Distribution)
    vue.component('org-select', OrgSelect)
    vue.component('easy-select', easySelect)
    vue.component('new-org-select', NewOrgSelect)
    vue.component('easy-data-select', easyDataSelect)
    // 时间选择器
    vue.component('easy-datetime-picker', easyDatetimePicker)
    vue.component('easy-date-picker', easyDatePicker)
    vue.component('easy-date-time-picker', easyDateTimePicker)
    
    // 上传 导出 导入
    vue.component('upload-excel', uploadExcel)
    vue.component('new-easy-image-select', NewEasyImageSelect)
    vue.component('easy-excel-export', EasyExcelExport)
    vue.component('easy-excel-import-pro', EasyExcelImportPro)
    vue.component('easy-excel-import', EasyExcelImport)
    vue.component('easy-file-select', EasyFileSelect)
    vue.component('easy-image-select-pro', EasyImageSelectPro)
    vue.component('easy-image-select', EasyImageSelect)
    vue.component('qiniu-upload',QiniuUpload)

    vue.component('di-table', DiTable)
    vue.component('mg-table', MgTable)
    vue.component('mg-label', MgLabel)
  }
}
export default ComponentConfig
