import * as Vue from "vue";
import Layout from "@/layout";

const kafkaRouter = {
  path: "/kafka",
  component: Layout,
  name: "kafkaFile",
  meta: { title: "Kafka", icon: "Avatar" },
  children: [
    {
      path: "/kafka/delivery-center",
      component: () => import("@/views/kafka/delivery-center/list"),
      name: "KafkaDeliveryCenter",
      meta: { title: "配送中心列表", icon: "EditPen" },
    },
    {
      path: "/kafka/error-log",
      component: () => import("@/views/kafka/log/error_log.vue"),
      name: "KafkaErrorLog",
      meta: { title: "Kafka异常日志", icon: "EditPen" },
    },
    {
      path: "/kafka/order-message-list",
      component: () => import("@/views/kafka/message/list.vue"),
      name: "KafkOrderMessageList",
      meta: { title: "订单消息列表", icon: "EditPen" },
    },
    {
      path: "/kafka/edit-stock",
      component: () => import("@/views/kafka/stock/edit-stock.vue"),
      name: "KafkManuallyVerifiesInventory",
      meta: { title: "手动校验库存", icon: "EditPen" },
    },
    {
      path: "/kafka/send-order",
      component: () => import("@/views/kafka/order/send-order.vue"),
      name: "KafkBulkResendOrders",
      meta: { title: "批量重发订单", icon: "EditPen" },
    },
  ],
};
export default kafkaRouter;
