<template>
  <el-button
    :type="type ? type : 'primary'"
    :plain="plain == null || plain == undefined ? true : plain"
    :icon="hiddenBtnIcon ? '' : 'Download'"
    :disabled="disabled"
    :loading="exportLoading"
    :size="size ? size : 'medium'"
    @click="exportExcel"
  >
    <slot>导出</slot>
  </el-button>
</template>

<script>
import request from '@/api/request'

export default {
  name: 'EasyExcelExport',
  props: [
    'url',
    'method',
    'params',
    'async',
    'type',
    'plain',
    'size',
    'hiddenBtnIcon',
    'disabled'
  ],
  data() {
    return {
      exportLoading: false,
    }
  },
  created() { },
  methods: {
    exportExcel() {
      if (!this.url) {
        return
      }
      const config = {
        url: this.url,
        method: this.method ? this.method.toUpperCase() : 'GET',
      }
      config[config.method === 'GET' ? 'params' : 'data'] =
        this.params instanceof Function ? this.params() : this.params
      this.exportLoading = true
      request(config)
        .then((data) => {
          if (this.async) {
               this.$message({
                type: 'success',
                dangerouslyUseHTMLString: true,
                message: `导出成功, <a href="#/statistics/import_export">点击前往<span style="color: #409eff;">导入导出列表</span>查看导入结果</a>`,
              })
          } else {
            this.$message.success('导出成功!')
            window.location.href = data.message
          }
        })
        .finally(() => {
          this.exportLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#easy-excel-export {}
</style>
