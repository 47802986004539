
class EnumData{
  _label = '';
  _value = null;
  constructor(str){
    let strs = str.split("-");
    this._value = strs[0];
    this._label = strs[1];
  }
  get label(){
    return this._label;
  }
  get value(){
    return this._value;
  }
}

export class Enum{
  _enums = [];

  constructor(...args){
    args.forEach(str => {
      this._enums.push(new EnumData(str));
    });
  }

  getEnumDataByValue(value){
    return this._enums.find(item => item.value == value) || new EnumData("-")
  }
  getEnumDataByLabel(label){
    return this._enums.find(item => item.label == label) || new EnumData("-")
  }

}

