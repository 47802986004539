import * as Vue from 'vue'
import Layout from '@/layout'

const checkSaleRouter = {
  path: '/check-sale',
  component: Layout,
  redirect: 'checkSaleVueList',
  name: 'check-sale',
  meta: {
    title: '核销管理',
    icon: 'Medal',
  },
  // children: [
  //   {
  //     path: 'checkSaleVue',
  //     component: () => import('@/views/check-sale/checkSaleVue/index'), // 父级 router-view
  //     name: 'check-sale-sub',
  //     meta: { title: '核销任务', icon: 'drag' },
  //     redirect: '/check-sale/checkSaleVue/checkSaleVueList',
  children: [
    {
      path: '/check-sale/checkSaleVueList',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkSaleVueList/list')),
      name: 'check-sale-sub',
      meta: { title: '核销列表', icon: 'Unlock' },
    },
    {
      path: '/check-sale/checkBillList',
      component:(() => import('@/views/check-sale/checkSaleVue/checkBillList/list')),
      name: 'check_bill_list',
      meta: { title: '对账明细', icon: 'SetUp' },
    },
    {
      path: '/check-sale/checkProof',
      component: (() =>import('@/views/check-sale/checkSaleVue/checkProofVueList/list')
      ),
      name: 'CheckProof',
      meta: { title: '举证与申请审核', icon: 'DocumentRemove' },
    },
    {
      path: '/proofDetail',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkProofVueList/detail')),
      name: 'proofDetail',
      meta: { title: '审核明细', keepAlive: false },
      hidden: true,
    },
    {
      path: '/check-sale/myProof',
      component:(() => import('@/views/check-sale/checkSaleVue/myProof/list')),
      name: 'myProof',
      meta: { title: '我的举证与申请', icon: 'user' },
    },
    {
      path: '/myProofDetail',
      component:(() => import('@/views/check-sale/checkSaleVue/myProof/detail')),
      name: 'myProofDetail',
      meta: { title: '举证单据明细', keepAlive: false },
      hidden: true,
    },
    {
      path: '/orderVueDetail',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkBillList/orderVue/list')),
      name: 'order_vue_detail',
      meta: { title: '联盟订单列表', keepAlive: true },
      hidden: true,
    },
    {
      path: '/orderVueSkuDetail',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkBillList/orderVue/components/orderDetail')),
      name: 'order_vue_sku_detail',
      meta: { title: '联盟订单明细', keepAlive: true },
      hidden: true,
    },
    {
      path: '/createCheckSale',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkSaleVueList/create')),
      name: 'create_checkSale',
      meta: { title: '新增核销', keepAlive: true },
      hidden: true,
    },
    {
      path: '/updateCheckSale',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkSaleVueList/create')),
      name: 'update_checkSale',
      meta: { title: '编辑核销', keepAlive: true },
      hidden: true,
    },
    {
      path: '/checkSale/goodsPool',
      component:(() =>import('@/views/check-sale/checkSaleVue/checkGoodsPool/goodsPool')),
      name: 'check-sale_vue',
      meta: { title: '核销产品池' },
      hidden: true,
    },
  ],
  //   }
  // ]
}

export default checkSaleRouter
