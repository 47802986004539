import Layout from '@/layout'

const version = {
  path: '/version-management',
  component: Layout,
//   redirect: '/test/index',
  name: 'VersionManagement',
  meta: {
    title: '版本管理',
    icon: 'TrendCharts'
  },
//   hidden: true,
  children: [
    {
      path: '/version-management/version-upgrade',
      component: () => import('@/views/version-management/version-upgrade'),
      name: 'VersionUpgrade',
      meta: { title: '版本升级', icon: 'DataLine' },
    }
  ]
}

export default version