import * as Vue from 'vue'
import Layout from '@/layout'

const userRouter = {
  path: '/user',
  component: Layout,
  // redirect: '/user/userVueList',
  name: 'user',
  meta: {
    title: '用户管理',
    icon: 'UserFilled',
  },
  children: [

    {
      path: '/user/create',
      component:(() => import('@/views/user/userVue/userVueList/create')),
      name: 'CreateUser',
      meta: { title: '新增用户' },
      hidden: true,
    },
    {
      path: '/user/userVueList',
      component: (() => import('@/views/user/userVue/userVueList/list')),
      name: 'UserVueList',
      meta: { title: '用户列表' , icon:'Avatar'},
    },
    {
      path: '/user/userRole',
      component: (() => import('@/views/user/userVue/user-role/index')),
      name: 'UserRuleManage',
      meta: { title: '角色列表' , icon:'Avatar'},
    },
    {
      path: '/user/newEditUser',
      component: (() => import('@/views/user/userVue/userVueList/new-edit-user')),
      name: 'NewEditUser',
      meta: { title: '新建/编辑用户', keepAlive: false },
      hidden: true,
    },
    {
      path: '/user/edit',
      component:(() => import('@/views/user/userVue/userVueList/edit')),
      name: 'EditUser',
      meta: { title: '编辑用户' },
      hidden: true,
    },
    {
      path: '/user/batchAdd',
      component:(() => import('@/views/user/userVue/userVueList/batchAdd')),
      name: 'BatchAddUser',
      meta: { title: '批量新增用户' },
      hidden: true,
    },
    {
      path: '/user/store',
      component:(() => import('@/views/user/userVue/userVueList/store')),
      name: 'Store',
      meta: { title: '维护终端', noCache: true },
      hidden: true,
    },
    {
      path: '/user/companyVueList',
      component:(() => import('@/views/user/userVue/companyVue/list')),
      name: 'CompanyVueList',
      meta: { title: '分公司列表', icon: 'OfficeBuilding' },
    },
    {
      path: '/user/deliver_list',
      component:(() => import('@/views/user/userVue/companyVue/deliverList')),
      name: 'DeliverList',
      meta: { title: '分公司下配送中心列表', noCache: true },
      hidden: true,
    },
    {
      path: '/user/deliverLists',
      component:(() => import('@/views/user/userVue/deliverVue/list')),
      name: 'AllDeliverVueList',
      meta: {
        title: '配送中心列表',
        icon: 'School',
        keepAlive: false,
      },
    },
    {
      path: '/optionManage',
      component:(() => import('@/views/user/userVue/deliverVue/optionManage')),
      name: 'OptionManage',
      hidden: true,
      meta: {
        title: '配送中心操作配置',
        icon: 'el-icon-user-solid',
        keepAlive: false,
      },
    },
    {
      path: '/user/storeList',
      component:(() => import('@/views/user/userVue/deliverVue/storeList')),
      name: 'DeliverStoreList',
      meta: { title: '配送中心下的终端列表', noCache: true },
      hidden: true,
    },
    {
      path: '/user/storeVueList',
      component:(() => import('@/views/user/userVue/storeVue/list')),
      name: 'StoreVueList',
      meta: { title: '终端列表', icon: 'Service' },
    },
    {
      path: '/user/storeDetail',
      component:(() => import('@/views/user/userVue/storeVue/seeDetail')),
      name: 'StoreDetail',
      meta: { title: '终端详情列表' },
      hidden: true,
    },
    {
      path: '/user/editStoreDetail',
      component:(() => import('@/views/user/userVue/storeVue/edit')),
      name: 'StoreUser',
      meta: { title: '关联账号', noCache: true },
      hidden: true,
    },
    {
      path: '/user/AutoGroup',
      component:(() => import('@/views/home-page/storeGroup-vue/list')),
      name: 'AutoGroup',
      meta: { title: '自增终端组', icon: 'CirclePlus' },
    },
    {
      path: '/user/AutoGroupContent',
      component:(() => import('@/views/home-page/storeGroup-vue/edit')),
      name: 'AutoGroupContent',
      meta: { title: '自增终端组内容页', noCache: true },
      hidden: true,
    },
    {
      path: '/user/StoreGroupList',
      component:(() => import('@/views/home-page/store-vue/list')),
      name: 'StoreGroupList',
      meta: { title: '终端组列表', icon: 'Odometer' },
    },
    {
      path: '/user/CreateStoreGroup',
      component:(() => import('@/views/home-page/store-vue/create')),
      name: 'CreateStoreGroup',
      meta: { title: '新增终端组' },
      hidden: true,
    },
    {
      path: '/user/EditStoreListActivity',
      component:(() => import('@/views/home-page/store-vue/editList')),
      name: 'StoreGroupContent',
      meta: { title: '终端组内容页', noCache: true },
      hidden: true,
    },
  ],
}

export default userRouter
