import * as Vue from 'vue'
import Layout from '@/layout'

const finance = {
  path: '/finance',
  component: Layout,
  name: 'Finance',
  redirect: '/finance/terminalReconciliation',
  meta: { title: '财务管理', icon: 'UserFilled' },
  children: [
    {
      path: '/finance/payeeSetting',
      component:(() => import('@/views/finance-manage/payee-setting')),
      name: 'PayeeSetting',
      meta: { title: '配送中心收款设置', icon: 'Setting' },
    },
    {
      path: '/finance/terminalReconciliation',
      component:(() => import('@/views/finance-manage/terminal-reconciliation')),
      name: 'TerminalReconciliation',
      meta: { title: '终端对账单', icon: 'Edit' },
    },
  ],
}

export default finance
