var MgPlugin = {
  install(Vue) {
    Vue.config.globalProperties.$toBase64 = function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let res = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          res = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(res)
        }
      })
    }

    function isJson(obj) {
      var isjson =
        typeof obj === 'object' &&
        Object.prototype.toString.call(obj).toLowerCase() ==
          '[object object]' &&
        !obj.length
      return isjson
    }
    function isArray(obj) {
      var isjson =
        typeof obj === 'object' &&
        Object.prototype.toString.call(obj).toLowerCase() == '[object array]' &&
        obj.length
      return isjson
    }

    function call(data, formData, key, k) {
      if (isJson(data)) {
        Object.keys(data).forEach((k) => {
          call(data[k], formData, key ? key + '.' + k : k, k)
        })
      } else if (isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          call(data[i], formData, key + '[' + i + ']', key)
        }
      } else {
        formData.append(key, data)
      }
    }
    window.vueInstance = Vue
    Vue.config.globalProperties.$toFormData = window.$toFormData = function (data) {
      var formData = new FormData()
      call(data, formData)
      return formData
    }
  },
}

export default MgPlugin
