import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'


NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  console.log(to,"-------------to")
  // start progress bar
  NProgress.start()
  if (to.path != '/login') {
    window.routerToPath = to.path
  }
  // set page title
  document.title = getPageTitle(to.meta.title)
  //  因为要兼容旧后台，所以Token不做唯一登录判定，用户可能使用cookie，等到老后台废弃，再启用hasToken判断
  // const hasToken = getToken()
  // if (hasToken) {
  if (whiteList.indexOf(to.path) !== -1) {
    const hasToken = getToken()
    if (hasToken) {
      next(`/`)
    } else {
      next()
    }
  } else {
    // 如果有roles认为已经登录
    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    if (hasRoles) {
      const isRequireSetPwd = store.getters.isRequireSetPwd
      const routeNames = store.getters.routeNames
      if (routeNames && routeNames.find((v) => v === to.name)) {
        if (isRequireSetPwd) {
          if (to.path === '/person/resetPassword') {
            next()
          } else {
            next('/person/resetPassword')
          }
        } else {
          next()
        }
      } else {
        next()
      }
      NProgress.done()
    } else {
      try {
        // get user info
        // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
        const { routes, isRequireSetPwd } = await store.dispatch('user/getInfo')
        // generate accessible routes map based on roles
        // 因为重置密码特殊的原因 所以在这里固定有访问权限
        routes.push('PersonResetPassword')
        const accessRoutes = await store.dispatch(
          'permission/generateAsyncRoutes',
          routes
        )
        const accessRoutesName = await store.dispatch(
          'permission/generateAsyncRouteName',
          routes
        )
        // dynamically add accessible routes
        accessRoutes.forEach(route => router.addRoute(route))
        if (isRequireSetPwd) {
          next('/person/resetPassword')
        } else {
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        }
        NProgress.done()
      } catch (error) {
        console.log('====================================路由报错')
        console.log(error)
        console.log('====================================路由报错')
        await store.dispatch('user/resetToken')
        next(`/login?redirect=${to.fullPath}`)
        NProgress.done()
      }
    }
  }
  // } else {
  //   /* has no token*/
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // in the free login whitelist, go directly
  //     next()
  //   } else {
  //     // other pages that do not have permission to access are redirected to the login page.
  //     next(`/login?redirect=${to.path}`)
  //     NProgress.done()
  //   }
  // }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
