import { login, logout, getInfo } from '@/network/NetworkUser'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  companyCode: '',
  deliverCode: '',
  isRequireSetPwd: false,
  routes: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_COMPANYCOD: (state, companyCode) => {
    state.companyCode = companyCode
  },
  SET_DELIVERCODE: (state, deliverCode) => {
    state.deliverCode = deliverCode
  },
  SET_ISREQUIRESETPWD: (state, isRequireSetPwd) => {
    state.isRequireSetPwd = isRequireSetPwd
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { data } = response

          if (!data) {
            reject('鉴权失败，请重新登录！')
          }

          const {
            roles,
            routes,
            name,
            avatar,
            introduction,
            token,
            companyCode,
            deliverCode,
            isRequireSetPwd,
          } = data
          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('用户权限为空！')
          }
          if (!routes || routes.length <= 0) {
            reject('用户的权限为空！')
          }
          if (token) {
            commit('SET_TOKEN', token)
            setToken(token)
          }
          commit('SET_ISREQUIRESETPWD', isRequireSetPwd)
          commit('SET_ROLES', roles)
          commit('SET_NAME', name)
          commit(
            'SET_AVATAR',
            avatar ||
              'https://lifemg.cn/static/admin/images/mg-icon_2235b4d.png'
          )
          commit('SET_INTRODUCTION', introduction)
          commit('SET_COMPANYCOD', companyCode)
          commit('SET_DELIVERCODE', deliverCode)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_DELIVERCODE', '')
          removeToken()
          resetRouter()
          dispatch('permission/removeRouteName', null, { root: true })
          dispatch('tagsView/delAllViews', null, { root: true })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true,
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
