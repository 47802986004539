<template>
  <div>
    <div
      id="easy-image-select"
      :style="{ width: imgWidth, minHeight: imgHeight, lineHeight: imgHeight }"
      @click="selectFile()"
    >
      <img
        ref="img"
        :src="imgUrl ? imgUrl : ''"
        :style="{ 'width':(imgUrl||file)?'100%':'0','height': (setImgHeight&&(imgUrl||file))?imgHeight:'' }"
        style="display: block;"
      >
      <input
        ref="file"
        type="file"
        accept="image/*"
        style="display: none"
        @change="fileChange()"
      >
      <!-- <div v-if="showPlus">+</div> -->
      <el-icon class="avatar-uploader-icon" v-if="showPlus"><Plus /></el-icon>
    </div>
    <span style="color: #95a5a6; font-size: 14px;" :class="{'tipCenter':tipCenter}">
      {{ this.tip }}
    </span>
  </div>
</template>

<script>
export default {
  name: "EasyImageSelect",
  props: ['file', 'imgUrl', 'tip', 'imgWidth', 'imgHeight', 'setImgHeight', 'tipCenter'],
  data() {
    return {
      showPlus: true
    }
  },
  watch: {
    imgUrl() {
      if (this.imgUrl) {
        this.showPlus = false;
      }
    }
  },
  created() {
    if (this.imgUrl) {
      this.showPlus = false;
    }
  },
  methods: {
    selectFile() {
      this.$refs["file"].click()
    },
    fileChange() {
      const file = this.$refs["file"].files[0]
      if (!file) {
        return
      }
      this.$refs["img"].src = window.URL.createObjectURL(file)
      this.$emit("update:file", file)
      setTimeout(() => {
        this.showPlus = false
      }, 5)
    }
  },
}
</script>

<style scoped lang="scss">
#easy-image-select {
  border: 1px solid #dcdfe6;
  width: 200px;
  min-height: 100px;
  overflow: auto;
  cursor: pointer;
  color: #dcdfe6;
  border-radius: 5px;
  font-size: 30px;
  text-align: center;
  line-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    border-color: #409eff;
    color: #409eff;
  }
}

.tipCenter {
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>
