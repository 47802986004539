<template>
  <el-dialog
    :title="title ? title : '文件导入'"
    v-model="dialogVisible"
    width="600px"
    :modal="modal"
  >
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item v-if="dialogVisible" label="文件：">
        <easy-file-select
          v-model:file="form.file"
          accept=".xls,.xlsx"
          :template-url="templateUrl"
          :template-text="templateText"
        />
      </el-form-item>
      <el-form-item v-if="showImportType" label="导入方式：">
        <el-radio-group v-model="form.importType">
          <el-radio :disabled="disabledIncrement" :label="1">增量</el-radio>
          <el-radio :disabled="disabledOverride" :label="0">覆盖</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="success" :loading="submitLoading" @click="submit()"
          >导 入</el-button
        >
        <el-button @click="dialogVisible = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import request from '@/api/request'
import EasyFileSelect from "@/components/Upload/easy-file-select.vue";
export default {
  name: 'EasyExcelImport',
  components: {EasyFileSelect},
  props: [
    'url',
    'visible',
    'title',
    'method',
    'params',
    'templateUrl',
    'templateText',
    'message',
    'modal',
    'disabledIncrement',
    'disabledOverride',
  ],
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      showImportType: true,
      form: {
        file: null,
        importType: 1,
      },
    }
  },
  watch: {
    dialogVisible() {
      $emit(this, 'update:visible', this.dialogVisible)
    },
    visible() {
      this.dialogVisible = this.visible
    },
  },
  created() {
    if (this.disabledOverride && this.disabledIncrement) {
      this.showImportType = false
    } else {
      if (this.disabledIncrement) {
        this.form.importType = 0
      }
      if (this.disabledOverride) {
        this.form.importType = 1
      }
    }
  },
  methods: {
    getValue(value) {
      return value instanceof Function ? value() : value
    },
    submit() {
      if (!this.form.file) {
        this.$message.error('文件不能为空！')
        return
      }
      if (!this.url) {
        return
      }
      this.submitLoading = true
      const data = {
        file: this.form.file,
        ...this.getValue(this.params),
      }
      if (this.showImportType) {
        data.importType = this.form.importType
      }
      request({
        url: this.url,
        method: this.method ? this.method : 'POST',
        data: this.$toFormData(data),
      })
        .then((data) => {
          if (data.code == 30004) {
            this.$message.error(data.data ? data.message : '导入失败')
            window.open(data.data ? data.data.url : data.message)
            // window.location.href = data.data ? data.data.url : data.message;
          } else {
            this.$message.success(this.message ? this.message : '导入成功!')
            $emit(this, 'success', data)
          }
        })
        .finally(() => {
          this.form.file = null
          this.submitLoading = false
          this.dialogVisible = false
        })
    },
  },
  emits: ['update:visible', 'success'],
}
</script>

<style lang="scss" scoped>
#easy-excel-import {
}
</style>
