import * as Vue from 'vue'
import Layout from '@/layout'

const ordeMeeting = {
  path: '/ordeMeeting',
  component: Layout,
  redirect: '/ordeMeeting/participants',
  name: 'OrdeMeeting',
  meta: {
    title: '订货会',
    icon: 'ShoppingCartFull',
  },
  children: [

    {
      path: '/ordeMeeting/participants',
      component: (() => import('@/views/ordeMeeting/participants/participants')),
      name: 'ParticipantsManagement',
      meta: { title: '参会方管理', icon: 'el-icon-s-data' },
      hidden: true,
    },
    {
      path: '/ordeMeeting/order-management',
      component:(() => import('@/views/ordeMeeting/OrderManagement/order-management.vue')),
      name: 'OrderManagement',
      meta: { title: '订单管理',  },
      hidden: true,
    },
    {
      path: '/ordeMeeting/order-details',
      component:(() => import('@/views/ordeMeeting/OrderManagement/orderDetails/order-details')
      ),
      meta: { title: '订单详情', icon: 'el-icon-s-data' },
      hidden: true,
    },
    {
      path: '/ordeMeeting/sign-in-management',
      component: (() =>import('@/views/ordeMeeting/SignInManagement/sign-in-management')),
      name:'SignInManagement',
      meta: { title: '签到管理', icon: 'el-icon-s-data' },
      hidden: true,
    },
    {
      path: '/ordeMeeting/purchasing-meeting',
      component: (() => import('@/views/ordeMeeting/purchasing-meeting/index')),
      name: 'PurchasingMeeting',
      meta: { title: '订货会管理', icon: 'ShoppingCartFull' },
    },
    {
      path: '/ordeMeeting/order-meeting-summary-report',
      component: (() => import('@/views/ordeMeeting/orderMeetingSummaryReport/index')),
      name: 'OrderMeetingSummaryReport',
      meta: { title: '订货会汇总报表', icon: 'Tickets' },
    },
    // {
    //   path: '/ordeMeeting/purchasing-meeting-edit',
    //   component: () => import('@/views/ordeMeeting/purchasing-meeting/edit'),
    //   name: 'PurchasingMeetingEdit',
    //   meta: { title: '新建/编辑订货会', icon: 'el-icon-s-data' }
    // },
    {
      path: '/ordeMeeting/pm-item',
      component:(() => import('@/views/ordeMeeting/pm-item/index')),
      name: 'PmItem',
      meta: { title: '商品管理', icon: 'el-icon-s-data' },
      hidden: true,
    },
    {
      path: '/ordeMeeting/product-order-report',
      component: (() => import('@/views/ordeMeeting/productOrderReport/index')),
      name: 'ProductOrderReport',
      meta: { title: '商品订货报表', icon: 'Tickets' },
    },
    {
      path: '/ordeMeeting/split-item-package-report',
      component: (() => import('@/views/ordeMeeting/splitItemPackageReport/index')),
      name: 'SplitItemPackageReport',
      meta: { title: '汇总报表_商品包拆包', icon: 'Tickets' },
    },
  ],
}

export default ordeMeeting
