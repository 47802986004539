import Layout from '@/layout'

const  contentManagementRouter = {
  path: '/contentManagement',
  component: Layout,
  name: 'contentManagement',
  redirect: '/AudioVisualPage',
  meta: { title: '内容管理', icon: 'Document' },
  children: [
    {
      path: '/contentManagement/AudioVisualPage',
      component: (() => import('@/views/home-page/audioVisual-vue/list')
      ),
      name: 'vue_product_video',
      meta: { title: '视频列表', icon: 'VideoCamera' },
    },
    {
      path: '/contentManagement/audio-program-manage/list',
      component: (() => import('@/views/home-page/audio-program-manage/list')
      ),
      name: 'AudioProgramManageList',
      meta: { title: '视频栏目管理', icon: 'VideoCamera' },
    },
    {
      path: '/contentManagement/audio-program-manage/edit',
      component: (() => import('@/views/home-page/audio-program-manage/edit')
      ),
      name: 'AudioProgramManageEdit',
      hidden: true,
      meta: { title: '视频栏目管理编辑', icon: 'VideoCamera' },
    },
    {
      path: '/contentManagement/audio-program-manage',
      component: (() => import('@/views/home-page/audio-program-manage/audio-manage')
      ),
      name: 'AudioProgramManage',
      hidden: true,
      meta: { title: '视频栏目管理', icon: 'VideoCamera' },
    },
    {
      path: '/contentManagement/video-tag',
      component: (() => import('@/views/home-page/video-tag/index.vue')),
      name: 'VideoTag',
      meta: { title: '视频标签管理', icon: 'VideoCamera' },
    },
    {
      path: "/video-comment",
      component: () => import("@/views/home-page/audioVisual-vue/video-comment"),
      name: "VideoComment",
      hidden: true,
      meta: { title: '评论列表', icon: 'ChatDotSquare' }
    },
    {
      path: '/EditAudioVisualPage',
      component: (() => import('@/views/home-page/audioVisual-vue/edit')
      ),
      name: 'ChangeProductVideo',
      meta: {
        title: '编辑商品视频信息',
        noCache: true,
        icon: 'el-icon-video-camera',
      },
      hidden: true,
    },
    {
      path: '/contentManagement/ArticleList',
      component:(() => import('@/views/home-page/article')

      ),
      name: 'ArticleList',
      meta: { title: '文章列表', icon: 'Reading' },
    },
    {
      path: '/ArticleEdit',
      component:(() => import('@/views/home-page/article/edit')),
      name: 'ArticleEdit',
      meta: { title: '文章编辑', icon: 'el-icon-video-camera' },
      hidden: true,
    },
    {
      path: '/contentManagement/listOfTectonicPlates',
      component:(() => import('@/views/home-page/list-of-tectonic-plates/list.vue')),
      name: 'ListOfTectonicPlates',
      meta: { title: '板块列表', icon: 'Reading'},
    },
    {
      path: '/contentManagement/TopicList',
      component:(() => import('@/views/home-page/topic')),
      name: 'TopicList',
      meta: { title: '话题列表', icon: 'Reading' },
    },
    {
      path: '/contentManagement/operations-management',
      component:(() => import('@/views/home-page/operations-management')),
      name: 'OperationalPositionManagementList',
      hidden: true,
      meta: { title: '运营位管理列表', icon: 'Reading' },
    },
    {
      path: '/contentManagement/invitation',
      component:(() => import('@/views/home-page/invitation/index.vue')),
      name: 'InvitationManage',
      meta: { title: '帖子列表', icon: 'Tickets' },
    },
    {
      path: '/contentManagement/questionnaire-manage',
      component:(() => import('@/views/home-page/questionnaire/index.vue')),
      name: 'QuestionnaireManage',
      meta: { title: '问卷管理', icon: 'Tickets' },
    },
    {
      path: '/contentManagement/topic/topicSetting',
      component:(() => import('@/views/home-page/topic/topic-setting')),
      name: 'TopicSetting',
      meta: { title: '话题设置', icon: 'Reading' },
      hidden: true
    },
    {
      path: '/contentManagement/topic/rewardSetting',
      component:(() => import('@/views/home-page/topic/reward-setting')),
      name: 'TopicRewardSetting',
      meta: { title: '奖励设置', icon: 'Reading' },
      hidden: true
    },
    {
      path: '/contentManagement/ListActivity',
      component:(() => import('@/views/home-page/list-vue/list')),
      name: 'product_list',
      meta: { title: '产品清单列表', icon: 'Files' },
    },
    {
      path: '/EditListActivity',
      component:(() => import('@/views/home-page/list-vue/edit')),
      name: 'ChangeProduct',
      meta: {
        title: '编辑产品清单',
        noCache: true,
        icon: 'Files',
      },
      hidden: true,
    },
    {
      path: '/EditListVueActivity',
      component:(() => import('@/views/home-page/list-vue/editList')),
      name: 'ProductManage',
      meta: { title: '清单商品管理', noCache: true, icon: 'el-icon-milk-tea' },
      hidden: true,
    },
    {
      path: "/contentManagement/head-img-border",
      component: ()=> import("@/views/home-page/head-img-border/index"),
      name: "HeadImgBorder",
      meta: { title: '头图框管理', icon: 'Picture' },
    }
  ],
}

export default contentManagementRouter
