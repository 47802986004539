import * as VueRouter from 'vue-router'

/* Layout */
import Layout from '@/layout'

/* Router Modules */
import versionManagement from "@/router/modules/version-management"
import activityRouter from './modules/activity'
import airdropRouter from './modules/airdrop'
import authorityRouter from './modules/authority'
import channelVerificationRouter from './modules/channelVerification'
import checkSaleRouter from './modules/checkSaleAdmin'
import conferenceMarketingRouter from './modules/conferenceMarketing'
import contentManagementRouter from './modules/contentManagement'
import finance from './modules/finance'
import fulfillmentRate from './modules/fulfillment-rate'; // 组件测试
import goodsRouter from './modules/goods'
import homePage from './modules/home-page'
import ordeMeeting from './modules/ordeMeeting'
import orderRouter from './modules/order'
import personOpRouter from './modules/personOp'
import redPacketRouter from './modules/redPacket'
import score from './modules/score'
import statistics from './modules/statistics'
import settingRouter from './modules/sysSetting'
import templateRouter from './modules/template'
import testRouter from './modules/test'
import userRouter from './modules/userAdmin'
import commentFeedback from './modules/comment-feedback'
import store from "@/router/modules/store";
import task from "@/router/modules/task";
import luckDraw from "@/router/modules/luck-draw";
import tackoutPlatform from "@/router/modules/takeout-platform"; // 评论反馈
import kafkaRouter from "@/router/modules/kafka";




export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    meta: {
      title: '默认展示',
      icon: 'Monitor',
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'redirect-*',
        component: (() => import('@/views/redirect/index')),
        hidden: true
      },
    ],
  },

  {
    path: '/login',
    name: 'login',
    component: (() => import('@/views/login/index')),
    hidden: true,
  },
  {
    path:"/projection-screen",
    name: "projectionScreen",
    component: (() => import('@/views/projectionScreen/index')),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    name: 'authRedirect',
    component: (() => import('@/views/login/auth-redirect')

    ),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: (() => import('@/views/error-page/404')

    ),
    hidden: true,
  },
  {
    path: '/401',
    name: '401',
    component: (() => import('@/views/error-page/401')

    ),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      title: '默认展示',
      icon: 'Monitor',
    },

    hidden: true,
    children: [
      {
        path: 'dashboard',
        component: (() => import('@/views/dashboard/index')),
        name: 'Dashboard',
        hidden: true,
        meta: { title: '首页', icon: 'HomeFilled', affix: true },
      },
    ],
  },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export const asyncRoutes = [
  {
    path: '/permission',
    component: Layout,
    redirect: '/permission/page',
    hidden: true,
    alwaysShow: true, // will always show the root menu
    name: 'Permission',
    meta: {
      title: 'Permission',
      icon: 'lock',
    },
    children: [
      {
        path: 'page',
        component: (() => import('@/views/permission/page')

        ),
        name: 'PagePermission',
        meta: {
          title: 'Page Permission',
        },
      },
      {
        path: 'directive',
        component: (
          () => import('@/views/permission/directive')
        ),
        name: 'DirectivePermission',
        meta: {
          title: 'Directive Permission',
          // if do not set roles, means: this page does not require permission
        },
      },
      {
        path: 'role',
        component: (() => import('@/views/permission/role')),


        name: 'RolePermission',
        meta: {
          title: 'Role Permission',
        },
      },
    ],
  },

  goodsRouter,
  userRouter,
  store,
  fulfillmentRate,
  airdropRouter,
  orderRouter,
  contentManagementRouter,
  homePage,
  activityRouter,
  score,
  luckDraw,
  task,
  checkSaleRouter,
  conferenceMarketingRouter,
  statistics,
  ordeMeeting,
  commentFeedback,
  versionManagement,
  kafkaRouter,
  redPacketRouter,
  templateRouter,
  finance,
  channelVerificationRouter,
  tackoutPlatform,
  settingRouter,
  personOpRouter,
  authorityRouter,
  testRouter,

  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)', redirect: '/404', hidden: true },
]

const createRouter = () =>
  VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: constantRoutes,
    scrollBehavior: () => ({
      top: 0,
    }),
  })

const router = createRouter()

router.closeRouteView = function (target, closeRoute) {
  if (!target) {
    alert('请传必填参数！')
  }
  if (!closeRoute) {
    closeRoute = target.$route
  }
  target.$store
    .dispatch('tagsView/delView', closeRoute)
    .then(({ visitedViews }) => {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        target.$router.push(latestView.fullPath)
      } else {
        if (closeRoute.name === 'Dashboard') {
          target.$router.replace({ path: '/redirect' + closeRoute.fullPath })
        } else {
          target.$router.push('/')
        }
      }
    })
}

router.closeView = function (target) {
  if (!target) {
    alert('请传必填参数！')
    return
  }
  const closeRoute = target.$route
  target.$store.dispatch('tagsView/delView', closeRoute)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
