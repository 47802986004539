<template>
  <div class="table_page">
    <el-table ref="elTable" :row-key="getRowKey" v-loading="optionTemp.loading" :data="data" :size="size"
      :max-height="optionTemp.maxHeight" :height="optionTemp.height" :stripe="optionTemp.stripe" :scrollbar-always-on="true"
      :border="optionTemp.border" @row-click="handleRowClick" @selection-change="handleSelectionChange"
      :cell-style="cellStyle" :show-summary="optionTemp.showSummary" :summary-method="getSummaries"
      :default-sort="optionTemp.defaultSort" @sort-change="val => optionTemp.sortChange(val)"
      @sort-method="val => optionTemp.sortMethod(val)" @sort-by="val => optionTemp.sortBy(val)">
      <!--selection选择框-->
      <el-table-column v-if="optionTemp.mutiSelect" type="selection" fixed="left"  :reserve-selection="true" width="50" align="center">
      </el-table-column>
      <!--序号-->
      <el-table-column v-if="optionTemp.index" label="序号" type="index" width="50" align="center">
      </el-table-column>
      <!--数据列-->
      <template v-for="(column, index) in columns" :key="index">
        <el-table-column :prop="column.prop" :label="column.label" :align="column.align || 'center'" :width="column.width"
          :fixed="column.fixed" :show-overflow-tooltip="column.hasOwnProperty('tooltip') ? column.tooltip : true"
          :sortable="column.sortable">
          <template v-slot="scope">
            <!--  含有click函数 -->
            <template v-if="column.onClick">
              <span @click.stop="column.onClick(scope.row, scope.$index, scope)">{{ scope.row[column.prop] }}</span>
            </template>
            <!-- 含有render函数 -->
            <template v-else-if="column.render">
              <RenderDom :row="scope.row" :index="index" :render="column.render" />
            </template>
            <!-- 有img图片显示 -->
            <template v-else-if="column.img">
              <div class="img-wrap" :style="{
                maxWidth: column.imgWidth ? column.imgWidth + 'px' : '100px',
                maxHeight: column.imgHeight
                  ? column.imgHeight + 'px'
                  : '100px',
              }">
                <el-image preview-teleported style="width: 100px; height: 100px" :src="scope.row[column.prop]" fit="cover"
                  :preview-src-list="[scope.row[column.prop]]">
                    <template #error>
                    <div class="image-slot">
                      <el-icon><icon-picture /></el-icon>
                    </div>
                  </template>
                </el-image>
                <!-- <img :src="scope.row[column.prop]" :alt="column.alt ? column.alt : '加载失败'"/> -->
              </div>
            </template>
            <!-- 插槽 -->
            <template v-else-if="column.slot">
              <slot :name="column.prop" :row="scope.row" :index="scope.$index"></slot>
            </template>
            <!-- 没有render函数且没有图片要显示 -->
            <template v-else>
              <span>{{ scope.row[column.prop] }}</span>
            </template>
            <!-- button 操作按钮  caseStatus-->
            <template v-if="column.button">
              <template v-for="(btn, i) in column.group">
                <!-- 当有显示控制的时候 -->
                <span v-if="btn.btnShow">
                  <span v-if="btn.showName">
                    <el-button :type="btn.type ? btn.type : 'primary'" link size="small"
                      @click.stop="btn.onClick(scope.row, scope.$index, scope)">{{ [btn.btnShow.value] == scope.row[btn.btnShow.key] ?
                        btn.name : btn.showName }}</el-button>
                  </span>
                  <span v-else>
                    <el-button :type="btn.type ? btn.type : 'primary'" link size="small"
                      @click.stop="btn.onClick(scope.row, scope.$index, scope)"
                      v-if="btn.btnShow.value.indexOf(scope.row[btn.btnShow.key]) != -1">{{ btn.name }}</el-button>
                  </span>
                </span>
                <span v-else>
                  <el-button :type="btn.type ? btn.type : 'primary'" link size="small"
                    @click.stop="btn.onClick(scope.row, scope.$index, scope)">{{ btn.name }}</el-button>
                </span>
              </template>
            </template>
            <!-- slot 你可以其他常用项 -->
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!-- 分页 -->
    <el-pagination v-if="pagination" background :total="pagination.total" :current-page="pagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 200]" :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleIndexChange"
      style="padding: 20px; text-align: right">
    </el-pagination>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
import * as Vue from 'vue'
import { keepDecimal, totalKeepDecimal } from '@/utils'

export default {
  components: {
    RenderDom: function render(_props, _context) {
      const data = {
        ..._context,
        props: _props,
        data: _context.attr,
        children: _context.slots,
      }
      const params = {
        row: data.props.row,
        index: data.props.index,
      }
      if (data.props.column) params.column = data.props.column
      return data.props.render(Vue.h, params)
    },

  },
  props: {
    size: {
      type: String,
      default: 'small',
    },
    dataSource: Array, // table内容数据
    options: Object, // 表格参数控制 maxHeight、stripe 等等...
    columns: Array, // 表头
    updateCheckbox: Boolean,
    fetch: {
      type: Function,
      default: function () { },
    }, // 获取数据的函数
    pagination: Object, // 分页，不传则不显示
    typeTable: String,
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        this.optionTemp.loading = newVal.loading
        this.optionTemp.customSums = newVal.customSums
        this.optionTemp.summary = newVal.summary
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true,
      deep: true,
    },
    dataSource: {
      handler(newVal, oldVal) {
        if (this.pagination && newVal) {
          this.data = this.AddKey(newVal)
        } else {
          this.data = newVal
        }
      },
      immediate: true,
      deep: true,
    },
    updateCheckbox: {
      handler(newVal, oldVal) {
        if (newVal) {
          $emit(this, 'selection-change', '')
          this.$refs.elTable.clearSelection()
          $emit(this, 'update:updateCheckbox', false)
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true,
      deep: true,
    }
  },
  data() {
    return {
      optionTemp: {
        loading: false,
        stripe: false, // 是否为斑马纹
        border: true,
        summaryType: 'custom', // 合计方式
        sumKeepDecimal: [], // 合计控制小数保留
        customSums: [], // 自定义合计列
      },
      data: []
    }
  },
  created() {
    // 传入的options覆盖默认设置
    this.optionTemp = Object.assign(this.optionTemp, this.options)

    this.optionTemp.initTable && this.fetch(this.pagination)
  },
  methods: {
    AddKey(params) {
      const data = [];
      for (let i = 0; i < params.length; i++) {
        data.push({
          tableKey: i + 1 + (this.pagination.pageIndex - 1) * this.pagination.pageSize,
          ...params[i]
        });
      }
      return data;
    },
    // table 文本颜色
    cellStyle(row, column, rowIndex, columnIndex) { },
    // pageSize 改变时触发事件
    handleSizeChange(size) {
      this.pagination.pageSize = size
      this.fetch(this.pagination)
    },
    // currentPage 改变时触发事件
    handleIndexChange(current) {
      this.pagination.pageIndex = current
      this.fetch(this.pagination)
    },


    // 多选框选择变化触发事件
    handleSelectionChange(selection) {
      console.log(selection, 'selection')
      $emit(this, 'selection-change', selection)
    },
    // 多选框选择时取消选择
    toggleRowSelection(rows) {
      if (rows) {
        // 切换选择
        rows.forEach((row) => {
          console.log(row, 'row')
          this.$refs.elTable.toggleRowSelection(row)
        })
      } else {
        $emit(this, 'selection-change', '')
        this.$refs.elTable.clearSelection()
      }
    },
    // 点击table某一行时触发事件
    handleRowClick(row, event, column) {
      // console.log(row, '父组件')
      $emit(this, 'handleRowClick', row, event, column)
    },
    // 翻页时，记住上一页的勾选标识
    getRowKey(row) {
      return row.tableKey
    },


    // 表格最下面合计行数据合计
    getSummaries(param) {
      // [  sumKeepDecimal数据格式
      //   {
      //       items: [],
      //       totalAmount: '',
      //       itemAmount: ''
      //   }
      // ]
      const { columns, data } = param

      let summaryType = this.optionTemp.summaryType
      let sumKeepDecimal = this.optionTemp.sumKeepDecimal
      if (summaryType == 'custom') {
        // 自定义合计
        // this.$emit("handleSummaries", columns, data);
        return this.optionTemp.customSums
      } else if (summaryType == 'endData') {
        // 后端合计
        return this.handleEndSum(columns, data, sumKeepDecimal)
      } else if (summaryType == 'frontData') {
        // 前端合计
        return this.handleFrontSum(columns, data, sumKeepDecimal)
      }
    },
    // 后端返回合计值
    handleEndSum(columns, data, sumKeepDecimal) {
      const sums = []
      const summary = this.optionTemp.summary

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 匹配后台返回的字段
        for (const key in summary) {
          if (column.property === key) {
            for (let i = 0; i < sumKeepDecimal.length; i++) {
              if (sumKeepDecimal[i].items.indexOf(column.property) != -1) {
                sums[index] = keepDecimal(
                  summary[key],
                  sumKeepDecimal[i].totalAmount
                )
              } else {
                sums[index] = summary[key]
              }
            }
          }
        }
      })

      return sums
    },
    // 前端计算合计值
    handleFrontSum(columns, data, sumKeepDecimal) {
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          for (let i = 0; i < sumKeepDecimal.length; i++) {
            if (sumKeepDecimal[i].items.indexOf(column.property) != -1) {
              sums[index] = totalKeepDecimal(
                values,
                sumKeepDecimal[i].totalAmount,
                '',
                sumKeepDecimal[i].itemAmount
              )
            } else {
              sums[index] = ''
            }
          }
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
  },
  emits: ['selection-change', 'handleRowClick'],
}
</script>

<style lang="scss" scoped>
.img-wrap {
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}</style>
