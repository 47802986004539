import * as Vue from 'vue'
import Layout from '@/layout'

const fulfillmentRate = {
  path: '/fulfillmentRate',
  component: Layout,
  name: 'FulfillmentRate',
  meta: {
    title: '订单满足率',
    icon: 'HelpFilled',
  },
  children: [
    {
      path: '/fulfillmentRate/dashboard',
      component:(() => import('@/views/fulfillment-rate/dashboard')),
      name: 'FulfillmentRateDashboard',
      meta: { title: '看板', icon: 'Monitor' },
    },
    {
      path: '/fulfillmentRate/order-exec-detail',
      component:(() => import('@/views/fulfillment-rate/order-exec-detail')),
      name: 'FulfillmentRateOrderExecDetail',
      meta: { title: '执行明细', icon: 'Tickets' },
    },
    {
      path: '/fulfillmentRate/unsent-order',
      component:(() => import('@/views/fulfillment-rate/unsent-order')),
      name: 'FulfillmentRateUnsentOrder',
      meta: { title: '终端未发汇总', icon: 'Notebook' },
    },
    {
      path: '/fulfillmentRate/distribution-stock-out',
      component: () => import('@/views/fulfillment-rate/distribution-stock-out'),
      name: 'DistributionStockOut',
      meta: { title: '配送中心缺货', icon: 'Notebook' }
    },
    {
      path: '/fulfillmentRate/company-stock-out',
      component: () => import('@/views/fulfillment-rate/company-stock-out'),
      name: 'CompanyStockOut',
      meta: { title: '分公司缺货', icon: 'Notebook' }
    },
  ],
}

export default fulfillmentRate
