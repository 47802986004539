import request from '@/api/request'

// 专供-缺货报表

// 获取缺货报表列表
export function getStockOutList(query) {
  return request({
    url: '/v4/admin/zg/stockOut',
    method: 'get',
    params: query,
  })
}

// 分公司接口

export function branchOffice() {
  return request({
    url: '/v4/admin/org/unLimit',
    method: 'get',
  })
}
// 批量开始欠量
export function batchStockOut(query) {
  return request({
    url: '/v4/admin/zg/stockOut/replenish/batch',
    method: 'post',
    data: query,
  })
}

// 部分开始欠量
export function batchPartStockOut(query) {
  return request({
    url: '/v4/admin/zg/stockOut/replenish/part',
    method: 'post',
    data: query,
  })
}

// 获取联盟专供缺货欠量进度
export function getStockOutProcess() {
  return request({
    url: '/v4/admin/zg/stockOut/process',
    method: 'get',
  })
}

// 获取缺货报表明细
export function getStockOutDetail(query) {
  return request({
    url: '/v4/admin/zg/stockOut/detail',
    method: 'get',
    params: query,
  })
}

// 获取缺货报表商品列表
export function getGoodsList(query) {
  return request({
    url: '/v4/admin/zg/stockOut/sku/list',
    method: 'get',
    params: query,
  })
}

// 导出缺货报表商品列表
export function exportGoodsList(query) {
  return request({
    url: '/v4/admin/zg/stockOut/sku/list/export',
    method: 'get',
    params: query,
  })
}

// 专供-补货单

// 联想获取专供商品清单
export function getSkusList(query) {
  return request({
    url: '/v4/admin/zg/sku',
    method: 'get',
    params: query,
  })
}

// 手工创建补货单
export function createReplenish(query) {
  return request({
    url: '/v4/admin/zg/replenish/create',
    method: 'post',
    data: query,
  })
}

// 获取分公司树形结构
export function getCompanyList() {
  return request({
    url: '/v4/admin/org/active',
    method: 'get',
  })
}

// 获取补货单列表
export function getReplenishList(query) {
  return request({
    url: '/v4/admin/zg/replenish/form',
    method: 'get',
    params: query,
  })
}

// 导出补货单列表
export function exportReplenishList(query) {
  return request({
    url: '/v4/admin/zg/replenish/form/export',
    method: 'get',
    params: query,
  })
}

// 导出专供清单/过滤订单号清单
export function exportOtherList(url) {
  return request({
    url: url,
    method: 'get',
  })
}

// 获取补货单明细
export function getReplenishDetail(id) {
  return request({
    url: '/v4/admin/zg/replenish/form/' + id,
    method: 'get',
  })
}

// 获取补货单明细商品列表
export function getRepGoodsList(query) {
  return request({
    url: '/v4/admin/zg/replenish/sku/list',
    method: 'get',
    params: query,
  })
}

export function orderValidate(params) {
  return request({
    url: '/v4/admin/order/web/validate',
    params: params,
  })
}

export function submit(data) {
  return request({
    url: '/v4/admin/order/web/submit',
    method: 'post',
    data: data,
  })
}

export function getList(query) {
  return request({
    url: '/v4/admin/order/withdrawOrderList',
    method: 'get',
    params: query,
  })
}
export function getCancelReason(query) {
  return request({
    url: '/v4/admin/orderCancel/cancelReason/' + query.orderNo,
    method: 'get',
  })
}
export function detail(id, query) {
  return request({
    url: `/v4/admin/order/${id}/newDetail`,
    method: 'get',
    params: query,
  })
}
export function audit(params) {
  return request({
    url: `/v4/admin/orderCancel/audit`,
    method: 'post',
    data: params,
  })
}

export function auditInfo(orderNo) {
  return request({
    url: `/v4/admin/orderCancel/auditHistory/${orderNo}`,
  })
}

// 直投组织修改
export function editDistributePartner(params) {
  return request({
    url: `/v4/admin/ord/distribute/partner`,
    method: 'PATCH',
    data: params,
  })
}

// 直投组织同步日志查询
export function syncLog(params) {
  return request({
    url: `/v4/admin/ord/distribute/partnerStore/syncLog/page`,
    method: 'GET',
    params: params,
  })
}

// 直投组织终端修改
export function editTerminalOrder(params) {
  return request({
    url: `/v4/admin/ord/distribute/partnerStore`,
    method: 'PATCH',
    data: params,
  })
}

// 修改直投组织终端组设置
export function editTerminalOrderSetting(params) {
  return request({
    url: `/v4/admin/ord/distribute/partner/partnerStoreTag/edit`,
    method: 'Post',
    data: params,
  })
}

// 查询直投组织关联终端组信息
export function getPartnerStoreTag(params) {
  return request({
    url: `/v4/admin/ord/distribute/partner/partnerStoreTag/list`,
    method: 'GET',
    params: params,
  })
}

// 获取支付码
export function  getQrcode(params){
  return request({
    url: `/v4/admin/pay/qrcode/create`,
    method: 'post',
    data:params
  })
}
// 售后单列表
export function  listOfAfterSalesOrders(params){
  return request({
    url: `/v4/admin/ord/distribute/returnForm/list`,
    method: 'get',
    params:params
  })
}
//售后单明细 
export function  afterSalesOrderDetails(params){
  return request({
    url: `/v4/admin/ord/distribute/returnForm/detail`,
    method: 'get',
    params:params
  })
}

// 售后单审核
export function  afterSalesOrderReview(params){
  return request({
    url: `/v4/admin/ord/distribute/returnForm/approve`,
    method: 'post',
    data:params
  })
}

