<template>
  <el-select
    v-model="selectValue"
    placeholder="请选择"
    :clearable="clearable"
    :disabled="disabled"
    :size="size ? size : 'default'"
    :multiple="!!multiple"
    collapse-tags
  >
    <el-option
      v-for="(item, i) in options ? options : []"
      v-show=" item.show ? item.show() : true "
      :key="i"
      :label="label ? item[label] : item.name"
      :value="value ? item[value] : item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { $on, $off, $once, $emit } from '../../utils/gogocodeTransfer'
export default {
  name: 'EasySelect',
  // model: {
  //   prop: 'modelValue',
  //   event: 'modelValueChange',
  // },
  props: ['options','modelValue','label','value','clearable','disabled','size', 'multiple'],
  data() {
    return {
      selectValue: '',
      // modelSwitch: true,
    }
  },
  watch: {
    modelValue() {
      // console.log(this.modelSwitch)
      // if (this.cutModelSwitch()) {
      //   return
      // }
      this.selectValue = this.modelValue
      // this.$emit("change")
    },
    value(){
      console.log(this.value,'value')
    },
    selectValue() {
      // console.log(this.selectValue)
      // console.log(this.modelSwitch)
      // if (this.cutModelSwitch()) {
      //   return
      // }
      // console.log(this.selectValue)
       $emit(this, 'update:modelValue', this.selectValue)
      // this.$emit("update:modelValue", this.selectValue)
      for (const option of this.options) {
        const value = this.value ? option[this.value] : option.id
        if (value === this.selectValue) {
          $emit(this, "change", option)
          return
        }
      }
      $emit(this,"change", null)
    },
  },
  created() {
    this.selectValue = this.modelValue
  },
  methods: {
    cutModelSwitch() {
      this.modelSwitch = !this.modelSwitch
      return this.modelSwitch
    },
  },
  emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
#easy-select {}
</style>
